import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  ListDescription,
  ListHeader,
  ListItem,
  Loader,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as magasinsService from "../../../services/gestion_attestations/company/magasinsService";
import * as magasinsStocksService from "../../../services/gestion_attestations/company/magasinsStockService";

const validationSchema = yup.object().shape({
  start_serial: yup
    .number("Veuillez renseigner un numéro de série de début valide")
    .required("Veuillez renseigner le numéro de série de début"),
  end_serial: yup
    .number("Veuillez renseigner un numéro de série de fin valide")
    .min(
      yup.ref("start_serial"),
      "Le numéro de série de fin doit être supérieur ou égal au numéro de série de début"
    )
    .required("Veuillez renseigner le numéro de série de fin"),
  magasin_destination_uuid: yup
    .string()
    .uuid("Veuillez sélectionner un magasin")
    .required("Veuillez sélectionner un magasin"),
  magasin_stock_uuid: yup
    .string()
    .uuid("Veuillez sélectionner un stock")
    .required("Veuillez sélectionner un stock"),
});
function MagasinStockTransfertFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  magasinStockUuid = null,
  handleCloseModal = () => {},
  attestationMagasinUuid = null,
}) {
  const [selectedAttestationMagasinStock, setSelectedAttestationMagasinStock] =
    React.useState(null);
  const [attestationMagasinStock, setAttestationMagasinStock] =
    React.useState(null);
  const [magasins, setMagasins] = React.useState([]);
  const inputQtyRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      start_serial: "",
      end_serial: "",
      magasin_destination_uuid: "",
      magasin_stock_uuid: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        magasin_stock_uuid: magasinStockUuid,
      };

      if (selectedItemId) {
        delete data.magasin_stock_uuid;
      }

      const request = selectedItemId
        ? magasinsStocksService.transferUpdate(selectedItemId, data)
        : magasinsStocksService.transferCreate(data);

      request
        .then(() => {
          toast.success(
            `Attribution d'attestation ${
              selectedItemId ? "mise à jour" : "ajoutée"
            }`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de l'attribution d'attestation`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { values, errors, handleSubmit, isSubmitting } = formik;

  // Fetch attestation magasin stock : stock trnsféré
  const fetchAttestationMagasinStock = (selectedItemId) => {
    setLoading(true);
    magasinsStocksService
      .fetchOne(selectedItemId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          setSelectedAttestationMagasinStock(data);
          formik.setFieldValue(
            "start_serial",
            data?.usage?.available_start_serial || ""
          );
          formik.setFieldValue(
            "end_serial",
            data?.usage?.available_end_serial || ""
          );
          formik.setFieldValue(
            "magasin_destination_uuid",
            data?.attestations_magasins?.uuid || ""
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMagasinStock = (magasinStockUuid) => {
    magasinsStocksService
      .fetchOne(magasinStockUuid)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          setAttestationMagasinStock(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const fetchMagasins = () => {
    magasinsService
      .fetchAll()
      .then((response) => {
        if (Array.isArray(response.data)) {
          const data = response.data;
          setMagasins(
            data?.filter((magasin) => magasin.uuid !== attestationMagasinUuid)
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (open) {
      formik.resetForm();
      formik.setFieldValue("magasin_stock_uuid", magasinStockUuid);
      if (magasinStockUuid) {
        fetchAttestationMagasinStock(selectedItemId);
        fetchMagasinStock(magasinStockUuid);
      }
      fetchMagasins();
    }
  }, [open, magasinStockUuid, selectedItemId]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} un transfert de stock
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <List horizontal className="w-full">
            <ListItem className="w-[45%] text-left">
              <ListHeader as="a">Quantité disponible</ListHeader>
              <ListDescription className="mt-2">
                {new Intl.NumberFormat().format(
                  attestationMagasinStock?.usage?.remainingQty || 0
                )}
              </ListDescription>
            </ListItem>
            <ListItem className="w-[45%] text-right">
              <ListHeader as="a">Plage disponible</ListHeader>
              <ListDescription className="mt-2">
                {attestationMagasinStock?.usage?.remainingQty > 0 ? (
                  <>
                    {`${attestationMagasinStock?.usage?.available_start_serial} - ${attestationMagasinStock?.usage?.available_end_serial}`}
                  </>
                ) : (
                  "Toutes les attestations sont déjà attribuées"
                )}
              </ListDescription>
            </ListItem>
          </List>
          <Divider />
          {loading ? (
            <div className="text-center">
              <Loader active inline="centered" />
            </div>
          ) : (
            <div className="mt-4">
              <div>
                <Form.Dropdown
                  fluid
                  selection
                  required={true}
                  label="Magasin"
                  placeholder="Selectionner un magasin"
                  name="magasin_destination_uuid"
                  value={values.magasin_destination_uuid}
                  onChange={(e, { value }) => {
                    formik.setFieldValue("magasin_destination_uuid", value);
                  }}
                  onBlur={(e, { value }) => {
                    formik.setFieldValue("magasin_destination_uuid", value);
                  }}
                  options={[
                    {
                      key: "",
                      text: "Choisir un magasin",
                      value: "",
                    },
                    ...magasins.map((magasin) => ({
                      key: magasin.uuid,
                      text: `${
                        magasin.name
                      } (${magasin.type_magasin?.toLowerCase()})`,
                      value: magasin.uuid,
                    })),
                  ]}
                />
                <small className="field-error">{errors.magasin_uuid}</small>
              </div>

              <div className="mt-4 mb-4">
                <Form.Field width={8}>
                  <label>Quantité</label>
                  <input
                    ref={inputQtyRef}
                    type="number"
                    onChange={(e) => {
                      const quantity = parseInt(e.target.value);
                      if (!isNaN(quantity) && quantity > 0) {
                        const startSerial =
                          attestationMagasinStock?.usage
                            ?.available_start_serial || 0;
                        formik.setFieldValue("start_serial", startSerial);
                        formik.setFieldValue(
                          "end_serial",
                          (Number(startSerial) + Number(quantity) - 1)
                            .toString()
                            .padStart(formik.values.start_serial.length, "0")
                        );
                      }
                    }}
                  />
                </Form.Field>
              </div>

              <Form.Group widths="equal">
                <Form.Field required error={"start_serial" in errors}>
                  <label>Série de début</label>
                  <Input
                    type="text"
                    name="start_serial"
                    value={values.start_serial}
                    onChange={(e) => {
                      const startSerial = e.target.value;
                      formik.setFieldValue("start_serial", startSerial);
                      formik.setFieldValue(
                        "end_serial",
                        (
                          Number(startSerial) +
                          Number(inputQtyRef.current.value) -
                          1
                        )
                          .toString()
                          .padStart(formik.values.start_serial.length, "0")
                      );

                      inputQtyRef.current.value = "";
                    }}
                    min={
                      selectedAttestationMagasinStock?.usage
                        ?.available_start_serial
                    }
                    max={
                      selectedAttestationMagasinStock?.usage
                        ?.available_end_serial
                    }
                    error={errors.start_serial}
                  />
                  <small className="field-error">{errors.start_serial}</small>
                </Form.Field>
                <Form.Field required error={"end_serial" in errors}>
                  <label>Série de fin</label>
                  <Input
                    type="text"
                    name="end_serial"
                    value={values.end_serial}
                    onChange={(e) => {
                      const endSerial = e.target.value;
                      formik.setFieldValue("end_serial", endSerial);

                      inputQtyRef.current.value = "";
                    }}
                    min={values.start_serial}
                    max={
                      selectedAttestationMagasinStock?.usage
                        ?.available_end_serial
                    }
                    error={errors.end_serial}
                  />
                </Form.Field>
              </Form.Group>
              {!isNaN(Number(values.start_serial)) &&
                !isNaN(Number(values.end_serial)) &&
                Number(values.start_serial) < Number(values.end_serial) && (
                  <div className="text-right font-bold text-gray-500">
                    Quantité d'attestations:{" "}
                    {new Intl.NumberFormat().format(
                      Number(values.end_serial) -
                        Number(values.start_serial) +
                        1
                    )}
                  </div>
                )}
            </div>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default MagasinStockTransfertFormModal;
