import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "semantic-ui-react";
import * as compagniesService from "../../../../services/comagniesService";

function AgenceSelection({ formik }) {
  const [compagnies, setCompagnies] = useState([]);
  const { values, errors, handleBlur, handleChange } = formik;

  const { assure } = values;

  const fetchJobs = async () => {
    try {
      const response = await compagniesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data?.compagnies)) {
        setCompagnies(response.data.compagnies);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchJobs();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Form.Group widths={"equal"}>
        <Form.Field error={"assure.idcompagnies" in errors} width={6}>
          <label>Agences</label>
          <Select
            search
            options={compagnies?.map((compagnie) => ({
              key: compagnie.idcompagnies,
              text: compagnie.denomination,
              value: compagnie.idcompagnies,
            }))}
            value={assure.idcompagnies}
            onChange={handleChange("assure.idcompagnies")}
            onBlur={handleBlur("assure.idcompagnies")}
          />
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default AgenceSelection;
