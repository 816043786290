import React from "react";
import { Accordion, Checkbox, Icon } from "semantic-ui-react";

function PermissionSubmoduleContent({
  moduleKey,
  submodule,
  formik,
  submoduleAccordions,
  setSubmoduleAccordions,
}) {
  return (
    <Accordion fluid styled className="!ml-4 !mb-2">
      <Accordion.Title
        active={submoduleAccordions[`${moduleKey}-${submodule.key}`]}
        onClick={() => {
          setSubmoduleAccordions((prev) => ({
            ...prev,
            [`${moduleKey}-${submodule.key}`]:
              !prev[`${moduleKey}-${submodule.key}`],
          }));
        }}
      >
        <Icon name="dropdown" />
        {submodule.label}
      </Accordion.Title>
      <Accordion.Content
        active={submoduleAccordions[`${moduleKey}-${submodule.key}`]}
      >
        {/* Permissions du sous-module */}
        {submodule.permissions && submodule.permissions.length > 0 && (
          <div className="p-2 flex flex-row flex-wrap gap-4">
            {submodule.permissions.map((permission) => (
              <div
                key={`${moduleKey}-${submodule.key}-${permission.key}`}
                className="mb-2"
              >
                <Checkbox
                  toggle
                  label={`${permission.description}`}
                  checked={
                    formik.values.selectedPermissions?.[moduleKey]?.[
                      submodule.key
                    ]?.[permission.key] || false
                  }
                  onChange={(e, { checked }) => {
                    // Créer la structure complète si elle n'existe pas
                    const currentPermissions = {
                      ...formik.values.selectedPermissions,
                    };
                    if (!currentPermissions[moduleKey]) {
                      currentPermissions[moduleKey] = {};
                    }
                    if (!currentPermissions[moduleKey][submodule.key]) {
                      currentPermissions[moduleKey][submodule.key] = {};
                    }
                    currentPermissions[moduleKey][submodule.key][
                      permission.key
                    ] = checked;

                    formik.setFieldValue(
                      "selectedPermissions",
                      currentPermissions
                    );
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {/* Sous-modules récursifs */}
        {submodule.submodules &&
          submodule.submodules.map((childSubmodule) => (
            <PermissionSubmoduleContent
              key={`${moduleKey}-${submodule.key}-${childSubmodule.key}`}
              moduleKey={`${moduleKey}.${submodule.key}`}
              submodule={childSubmodule}
              formik={formik}
              submoduleAccordions={submoduleAccordions}
              setSubmoduleAccordions={setSubmoduleAccordions}
            />
          ))}
      </Accordion.Content>
    </Accordion>
  );
}

export default PermissionSubmoduleContent;
