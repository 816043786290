export const USER_STORAGE = "jpBHjJNW1ONux3sTZeOdEkySJWUPOYfU";

export const POURCENTAGE_ASSURANCE_DUREE = {
  3: 26,
  6: 51,
};

export const TYPES_INTERMEDIAIRE = {
  AGENT_GENERAL: "AGENT_GENERAL",
  COURTIER: "COURTIER",
};

export const USERS_ROLE_ENUMS = {
  ROOT_ADMIN: "ROOT_ADMIN",
  POOL_USER: "POOL_USER",
  COMPANY_USER: "COMPANY_USER",
  COURTIER_USER: "COURTIER_USER",
  AGENT_GENERAL_USER: "AGENT_GENERAL_USER",
  COMPANY_AGENCY_USER: "COMPANY_AGENCY_USER",
  INTERMEDIAIRE_AGENCY_USER: "INTERMEDIAIRE_AGENCY_USER",
};

export const USERS_BASE_ROUTES = {
  ROOT_ADMIN: "pool",
  POOL_USER: "pool",
  COMPANY_USER: "company",
  COURTIER_USER: "intermediaire",
  AGENT_GENERAL_USER: "intermediaire",
  COMPANY_AGENCY_USER: "agency",
  INTERMEDIAIRE_AGENCY_USER: "agency",
};

export const USERS_ROLE_ENUMS_OPTIONS = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: USERS_ROLE_ENUMS.ROOT_ADMIN,
    text: "Super Admnistrateur",
    value: USERS_ROLE_ENUMS.ROOT_ADMIN,
  },
  {
    key: USERS_ROLE_ENUMS.POOL_USER,
    text: "Utilisateur du pool",
    value: USERS_ROLE_ENUMS.POOL_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COMPANY_USER,
    text: "Utilisateur de compagnie",
    value: USERS_ROLE_ENUMS.COMPANY_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COURTIER_USER,
    text: "Utilisateur Courtier",
    value: USERS_ROLE_ENUMS.COURTIER_USER,
  },
  {
    key: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
    text: "Utilisateur Agent Général",
    value: USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
  },
  {
    key: USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
    text: "Utilisateur Courtier/Agent Général d'Agence",
    value: USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
  },
  {
    key: USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
    text: "Utilisateur Bureau Direct",
    value: USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
  },
];
export const LIENS_SOUSCRIPEUR_ASSURE = [
  {
    key: "DEFAULT",
    text: "Choisir...",
    value: "",
  },
  {
    key: "LUI_MEME",
    text: "Lui Même",
    value: "LUI_MEME",
  },
  {
    key: "ASCENDANT",
    text: "Ascendant",
    value: "ASCENDANT",
  },
  {
    key: "BEAUX_PARENT",
    text: "Beaux-Parent",
    value: "BEAUX_PARENT",
  },
  {
    key: "DESCENDANT",
    text: "Descendant",
    value: "DESCENDANT",
  },
  {
    key: "EMPLOYE",
    text: "Employé",
    value: "EMPLOYE",
  },
  {
    key: "MEMBRE",
    text: "Membre",
    value: "MEMBRE",
  },
  {
    key: "PARENT",
    text: "Parent",
    value: "PARENT",
  },
  {
    key: "AUTRE",
    text: "Autre",
    value: "AUTRE",
  },
];
