import { api } from "../axios/api";

export const fetchOne = async (id) => {
  return api.get(`dictionnaire/categories-contrats/${id}`);
};
export const create = async (data) => {
  return api.post(`dictionnaire/categories-contrats`, data);
};
export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`dictionnaire/categories-contrats`, {
    params: { page, search },
  });
};

export const update = async (id, data) => {
  return api.put(`dictionnaire/categories-contrats/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`dictionnaire/categories-contrats/${id}`);
};
