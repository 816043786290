import { api } from "../axios/api";

export const fetchAll = async ({ page = 1, search = "" } = {}) => {
  return api.get(`compagnies-partenaires`, { params: { page, search } });
};

export const fetchOne = async (id) => {
  return api.get(`compagnies-partenaires/${id}`);
};
export const create = async (data) => {
  return api.post(`compagnies-partenaires`, data);
};
export const updateCommissions = async (data) => {
  return api.post(`compagnies-partenaires/update-commissions`, data);
};
export const addUser = async (data, agenceId) => {
  return api.post(
    `compagnies-partenaires/user/add/${agenceId ? agenceId : ""}`,
    data
  );
};

export const deleteIntermediaire = async (uuid) => {
  return api.delete(`compagnies-partenaires/${uuid}`);
};
