import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
  Pagination,
} from "semantic-ui-react";
import CountryFormModal from "../../components/modalForms/CountryFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as countriesService from "../../services/countriesService";
function CountriesPage() {
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchCountries = async () => {
    setIsLoading(true);
    try {
      const response = await countriesService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });

      if (response.status === 200) {
        const {
          countries,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;
        setCountries(countries);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des pays");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteCountry = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await countriesService.remove(id);
        if (response.status === 200) {
          toast.success("Pays supprimé avec succès");
          fetchCountries();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression du pays");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedCountryId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchCountries();
  }, [pagination.currentPage, search]);

  return (
    <Page title="Pays">
      <GridRow className="CountriesPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des pays</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              value={search}
              onChange={handleSearchChange}
            />
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>libelle</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {countries.map((country, index) => {
                  return (
                    <Table.Row key={`${country.idcountries}`}>
                      <Table.Cell>
                        {(pagination.currentPage - 1) * pagination.limit +
                          index +
                          1}
                      </Table.Cell>
                      <Table.Cell>{country.libelle}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() => {
                                const id = country.idcountries;
                                setSelectedCountryId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                const id = country.idcountries;
                                setSelectedCountryId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Supprimer"
                              disabled={isDeleting}
                              onClick={() =>
                                handleDeleteCountry(country.idcountries)
                              }
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8" textAlign="right">
                    <Pagination
                      activePage={pagination.currentPage}
                      totalPages={pagination.totalPages}
                      onPageChange={handlePageChange}
                      disabled={isLoading}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>
      <CountryFormModal
        open={open}
        setOpen={setOpen}
        selectedCountryId={selectedCountryId}
        handleClose={handleCloseModal}
        callback={() => {
          fetchCountries({ page: pagination.currentPage, search: search });
          handleCloseModal();
        }}
      />
    </Page>
  );
}

export default CountriesPage;
