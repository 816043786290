import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";

function IntermediaireAgencyMenus({
  activeIndex,
  activeSubMenu,
  handleItemClick,
  handleSubMenuClick,
}) {
  return (
    <Accordion
      as={Menu}
      fluid
      vertical
      style={{ borderRadius: "0px", border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="intermediaire-agency/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "production"}
          index={"production"}
          onClick={handleItemClick}
        >
          <Icon name="bus" /> Production
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "production"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              index={"intermediaire-agency/production/souscripteurs"}
              active={
                activeSubMenu ===
                "intermediaire-agency/production/souscripteurs"
              }
              onClick={handleSubMenuClick}
              to="intermediaire-agency/production/souscripteurs"
            >
              Liste des souscripteurs
            </Menu.Item>
            <Menu.Item
              as={Link}
              index={"intermediaire-agency/production/polices"}
              active={
                activeSubMenu === "intermediaire-agency/production/polices"
              }
              onClick={handleSubMenuClick}
              to="intermediaire-agency/production/polices"
            >
              Polices
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="intermediaire-agency/production/caisse"
              index={"intermediaire-agency/production/caisse"}
              active={
                activeSubMenu === "intermediaire-agency/production/caisse"
              }
              onClick={handleSubMenuClick}
            >
              Caisse
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="intermediaire-agency/production/souscriptions/mono/create"
              index={
                "intermediaire-agency/production/souscriptions/mono/create"
              }
              active={
                activeSubMenu ===
                "intermediaire-agency/production/souscriptions/mono/create"
              }
              onClick={handleSubMenuClick}
            >
              Production Mono
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="intermediaire-agency/souscriptions/flotte"
              index={"intermediaire-agency/souscriptions/flotte"}
              active={
                activeSubMenu === "intermediaire-agency/souscriptions/flotte"
              }
              onClick={handleSubMenuClick}
            >
              Production Flotte
            </Menu.Item>
            <Menu.Item as="a">Gestion des avenants</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "etats"}
          onClick={handleItemClick}
          index="etats"
        >
          <Icon name="file alternate" /> Etats
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "etats"}>
          <Menu.Menu>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
            <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
            <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
            <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="stocks"
          active={activeIndex === "stocks"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="intermediaire-agency/stocks/demandes"
              index={"intermediaire-agency/stocks/demandes"}
              active={activeSubMenu === "intermediaire-agency/stocks/demandes"}
              onClick={handleSubMenuClick}
            >
              Demandes d'attestions
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="gestion"
          onClick={handleItemClick}
          active={activeIndex === "gestion"}
        >
          <Icon name="car crash" /> Gestion des sinistres
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "gestion"}>
          <Menu.Menu>
            <Menu.Item as="a">Déclaration d'un sinistre</Menu.Item>
            <Menu.Item as="a">Estimation</Menu.Item>
            <Menu.Item as="a">Règlement</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="cession"
          active={activeIndex === "cession"}
          onClick={handleItemClick}
        >
          <Icon name="expand alternate" /> Cessions & Rétrocessions
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "cession"}>
          <Menu.Menu>
            <Menu.Item as="a">Cessions</Menu.Item>
            <Menu.Item as="a">Rétrocessions</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item
        as="a"
        index={"profile"}
        onClick={handleItemClick}
        active={activeIndex === "profile"}
      >
        <span>
          <Icon name="user" /> Mon profile
        </span>
      </Menu.Item>
    </Accordion>
  );
}

export default IntermediaireAgencyMenus;
