import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  Input,
  Segment,
  Table,
  Form,
  Pagination,
  Breadcrumb,
  Divider,
  Icon,
  Label,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import * as logssService from "../../services/logss/authlogs";
import AuthLogDetailsModal from "../../components/journalisation/AuthLogDetailsModal";

function ConnexionLogs() {
  const [allLogs, setAllLogs] = useState([]);
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });

  const fetchAllLogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await logssService.fetchAll({
        page: pagination?.currentPage,
        limit: 10,
        search,
        startDate: dateDebut,
        endDate: dateFin,
      });
      if (response.status === 200) {
        setAllLogs(response.data.logs);
        setPagination(response.data.pagination);
      } else {
        throw new Error("Failed to fetch logs");
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des journaux");
    } finally {
      setLoading(false);
    }
  }, [search, dateDebut, dateFin, pagination?.currentPage]);

  const handleViewDetails = useCallback((log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  }, []);

  const handleResetFilters = useCallback(() => {
    setSearch("");
    setDateDebut("");
    setDateFin("");
    setPagination({
      ...pagination,
      currentPage: 1,
    });
  }, []);

  const handlePageChange = useCallback((e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  }, []);

  const renderUserInfo = useCallback((user) => {
    if (!user) return "Utilisateur inconnu";
    return (
      `${user.first_name || ""} ${user.last_name || ""} ${
        user.email ? `(${user.email})` : ""
      }`.trim() || "Inconnu"
    );
  }, []);

  useEffect(() => {
    fetchAllLogs();
  }, [fetchAllLogs]);

  return (
    <Page title="Journaux de Connexion">
      <Grid className="!pt-0">
        <Grid.Column width={16}>
          <Breadcrumb>
            <Breadcrumb.Section link>Pool</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>journal-audit</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>
              Journal d'authentification
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="ui segment">
              <Form className="w-full">
                <Form.Group className="flex flex-row gap-2 justify-end">
                  <Form.Field width={3}>
                    <label>Date de début</label>
                    <Input
                      type="date"
                      name="dateDebut"
                      value={dateDebut}
                      onChange={(e) => {
                        setDateDebut(e.target.value);
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>Date de fin</label>
                    <Input
                      type="date"
                      name="dateFin"
                      value={dateFin}
                      onChange={(e) => {
                        setDateFin(e.target.value);
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>Recherche</label>
                    <Input
                      placeholder="Rechercher..."
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={1}>
                    <label></label>
                    <Button type="button" onClick={handleResetFilters} icon>
                      <Icon name="refresh" />
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment loading={loading}>
              <Table striped celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date et Heure</Table.HeaderCell>
                    <Table.HeaderCell>Utilisateur</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.HeaderCell>Statut</Table.HeaderCell>
                    <Table.HeaderCell>Détails</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {allLogs.length > 0 ? (
                    allLogs.map((log) => (
                      <Table.Row key={log.id}>
                        <Table.Cell>
                          {new Date(log.timestamp).toLocaleString()}
                        </Table.Cell>
                        <Table.Cell>{renderUserInfo(log.user)}</Table.Cell>
                        <Table.Cell>
                          <Label
                            size="mini"
                            color={
                              log.action === "login"
                                ? "blue"
                                : log.action === "logout"
                                ? "orange"
                                : "grey"
                            }
                          >
                            <Icon
                              name={`${
                                log.action === "login"
                                  ? "sign in"
                                  : log.action === "logout"
                                  ? "sign out"
                                  : "question"
                              }`}
                            />
                            {log.action === "login"
                              ? "Connexion"
                              : log.action === "logout"
                              ? "Déconnexion"
                              : log.action}
                          </Label>
                        </Table.Cell>
                        <Table.Cell>
                          <Label
                            size="mini"
                            color={log.success ? "green" : "red"}
                            content={log.success ? "Succès" : "Échec"}
                          />
                        </Table.Cell>
                        <Table.Cell>{`${log.browser || "N/A"} ${
                          log.version || ""
                        } - ${log.os || "N/A"} - ${
                          log.ip_address || "N/A"
                        }`}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon
                            name="eye"
                            color="green"
                            className="cursor-pointer"
                            onClick={() => handleViewDetails(log)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="6" textAlign="center">
                        Aucun journal trouvé
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                {pagination?.totalPages > 1 && (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="6" textAlign="right">
                        <Pagination
                          activePage={pagination?.currentPage}
                          totalPages={pagination?.totalPages}
                          onPageChange={handlePageChange}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AuthLogDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedLog={selectedLog}
        renderUserInfo={renderUserInfo}
      />
    </Page>
  );
}

export default ConnexionLogs;
