import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as intermediairesService from "../../services/intermediairesService";

const validationSchema = yup.object().shape({
  denomination: yup.string().required("La dénomination est requise"),
  siege_social: yup.string().required("Le siège social est requis"),
  code: yup
    .string()
    .matches(/^\d{4}$/, "Le code doit contenir 4 chiffres")
    .required(),
  active: yup.boolean().required("Le statut est requis"),
});
function IntermediairesFormModal({
  type = "courtier",
  callback = () => {},
  open = false,
  setOpen,
  handleClose,
  selectedPartnerUuid,
  setSelectedPartnerUuid,
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      siege_social: "",
      code: "",
      active: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const serviceCall = selectedPartnerUuid
        ? intermediairesService.update(selectedPartnerUuid, values)
        : intermediairesService.create(values);

      serviceCall
        .then(
          (response) => {
            const message = selectedPartnerUuid
              ? "Intermédiaire modifié avec succès."
              : "Intermédiaire ajouté avec succès.";
            toast(message, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(
              `${reason?.response?.data?.error || "Une erreur est survenue!"}`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchIntermediaire = async () => {
    try {
      const response = await intermediairesService.fetchOne(
        selectedPartnerUuid
      );
      if (response.status === 200) {
        setFieldValue("denomination", response.data.denomination);
        setFieldValue("siege_social", response.data.siege_social);
        setFieldValue("code", response.data.code);
        setFieldValue("active", response.data.active);
      }
    } catch (error) {
      toast(`${error?.response?.data || error?.message}`, {
        type: "error",
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (selectedPartnerUuid) {
      fetchIntermediaire();
    }
  }, [selectedPartnerUuid]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
      onUnmount={() => {
        if (!open) {
          handleReset();
          setSelectedPartnerUuid(null);
        }
      }}
    >
      <Modal.Header>
        {type === "agent_general"
          ? "Ajouter un agent général"
          : "Ajouter un courtier"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Field error={"siege_social" in errors} required>
            <label>Siège social</label>
            <Input
              value={values.siege_social}
              onChange={handleChange("siege_social")}
              onBlur={handleBlur("siege_social")}
            />
            <small className="field-error">{errors.siege_social}</small>
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field error={"code" in errors} required>
              <label>Code</label>
              <Input value={values.code} onChange={handleChange("code")} />
              <small className="field-error">{errors.code}</small>
            </Form.Field>
            <Form.Field error={"active" in errors}>
              <label>Entité active</label>
              <Checkbox
                toggle
                checked={values.active}
                onChange={(e, { checked }) =>
                  setFieldValue("active", checked ? 1 : 0)
                }
              />
              <small className="field-error">{errors.active}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleClose}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediairesFormModal;
