import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as sourcesEnergiesService from "../../services/sourcesEnergiesService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(2, "Le libellé doit contenir au moins 2 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
  description: yup
    .string()
    .max(500, "La description ne doit pas dépasser 500 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function SourcesEnergiesFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedEnergieUuid,
  handleClose = () => {},
}) {
  const fetchSource = useCallback(async () => {
    if (!selectedEnergieUuid) {
      return;
    }

    try {
      const response = await sourcesEnergiesService.fetchOne(
        selectedEnergieUuid
      );
      const sourceData = response.data.sources_energies || response.data;

      if (sourceData.libelle || sourceData.description) {
        formik.setValues({
          libelle: sourceData.libelle || "",
          description: sourceData.description || "",
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de la source d'énergie: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedEnergieUuid]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedEnergieUuid;

        const serviceCall = isEditMode
          ? sourcesEnergiesService.update(selectedEnergieUuid, values)
          : sourcesEnergiesService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Source d'énergie modifiée avec succès"
            : "Source d'énergie ajoutée avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedEnergieUuid) {
      fetchSource();
    } else {
      formik.resetForm();
    }
  }, [selectedEnergieUuid, fetchSource]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedEnergieUuid
          ? "Modifier une source d'énergie"
          : "Ajouter une source d'énergie"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={formik.handleChange("libelle")}
              onBlur={formik.handleBlur("libelle")}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
          <Form.Field error={!!formik.errors.description}>
            <label>Description</label>
            <Form.TextArea
              placeholder="Entrez la description"
              value={formik.values.description}
              onChange={formik.handleChange("description")}
              onBlur={formik.handleBlur("description")}
            />
            {formik.touched.description && formik.errors.description && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.description}
              </small>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          content={selectedEnergieUuid ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={formik.handleSubmit}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default SourcesEnergiesFormModal;
