import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Input, Message, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import { LIENS_SOUSCRIPEUR_ASSURE } from "../../../constants";
import * as souscriptionService from "../../../services/gestion_production/policesService";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  denomination: yup.string().min(4).required("Nom de l'assuré/Dénomination"),
  lien_assure: yup.string().min(4).required("Lien avec l'assuré"),
  date_avenant: yup.string().min(4).required("Date de l'avenant"),
});

function ChangementDeNomModal({
  callback = () => {},
  open = false,
  setOpen,
  avenants,
  uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);

  const formik = useFormik({
    initialValues: {
      denomination: "",
      lien_assure: "AUTRE",
      date_avenant: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        denomination: values.denomination,
        lien_assure: values.lien_assure,
        date_avenant: values.date_avenant,
      };
      avenantsService
        .createAvenantChangementDenomination(uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Nom modifié avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.message || "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  useEffect(() => {
    if (open) {
      souscriptionService.findOne(uuid).then((response) => {
        setSouscription(response.data);
      });
    }
  }, [uuid, open]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de changement de nom</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui form">
            <Grid divided>
              <Grid.Column width={6}>
                <Form.Field required error={"date_avenant" in errors}>
                  <label>Date de l'avenant </label>
                  <Input
                    type="date"
                    value={values.date_avenant}
                    onChange={handleChange("date_avenant")}
                    onBlur={handleBlur("date_avenant")}
                  />
                  <small className="field-error">{errors.date_avenant}</small>
                </Form.Field>
                <Message>
                  <Message.Content>
                    <p>
                      La mise en vigueur de l'avenant est fixée au lendemain de
                      la date de l'avenant.
                    </p>
                  </Message.Content>
                </Message>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field required error={"lien_assure" in errors}>
                  <label>Lien avec l'assuré </label>
                  <Form.Select
                    readOnly
                    value={values.lien_assure}
                    onChange={(e, data) => {
                      setFieldValue("lien_assure", data.value, true);
                    }}
                    options={LIENS_SOUSCRIPEUR_ASSURE}
                  />
                  <small className="field-error">{errors.lien_assure}</small>
                </Form.Field>
                <Form.Field>
                  <label>Nom de l'assuré/Dénomination</label>
                  <Input
                    value={values.denomination}
                    onChange={handleChange("denomination")}
                    onBlur={handleBlur("denomination")}
                  />
                  <small className="field-error">{errors.denomination}</small>
                </Form.Field>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              !values.date_avenant ||
              dayjs(values.date_avenant).isBefore(
                souscription?.conditions_particulieres?.date_effet
              )
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default ChangementDeNomModal;
