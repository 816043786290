import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesPartnersService from "../../services/compagniesPartnersService";
import { toast } from "react-toastify";
import { TYPES_INTERMEDIAIRE } from "../../constants";
import AgenciesListTable from "../../components/intermediaires/AgenciesListTable";
import UsersListTable from "../../components/intermediaires/UsersListTable";

function CompanyPartnersDetails() {
  const [intermediaire, setIntermediaire] = useState();
  const [commissions, setCommissions] = useState();
  const [users, setUsers] = useState([]);
  const [usersPagination, setUsersPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });

  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedUserUuid, setSelectedUserUuid] = useState();

  const params = useParams();

  const fetchIntermediaire = async () => {
    try {
      const response = await compagniesPartnersService.fetchOne(params?.uuid);

      if (response?.status === 200) {
        setIntermediaire(response?.data);
        setCommissions(response?.data.commissions);
      }
    } catch (error) {}
  };

  const updateCommissions = () => {
    compagniesPartnersService
      .updateCommissions({
        commissions: commissions,
        idintermediaires: intermediaire.idintermediaires,
      })
      .then(
        () => {
          toast(`Commissions modifiée avec succès.`, {
            type: "success",
            theme: "colored",
          });
        },
        (reason) => {
          toast(`${reason?.response?.data || reason?.message}`, {
            type: "error",
            theme: "colored",
          });
        }
      );
  };

  useEffect(() => {
    if (params?.uuid) {
      fetchIntermediaire();
    }
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Intermédiaire détails">
      <GridRow className="IntermediaireDetails">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link as={Link} to="/compagnies/partners">
                Partenaires
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link active>
                Détails
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Header>{`Détails ${
          intermediaire?.intermediaires?.type_intermediaire ===
          TYPES_INTERMEDIAIRE.AGENT_GENERAL
            ? " de l'agent général"
            : " du courtier"
        }`}</Header>
        <GridRow as={Segment}>
          <Label ribbon>Détails</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.denomination}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.phone_1 || "-"}/
                      {intermediaire?.intermediaires?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Siège Social</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.siege_social}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">RCCM/</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Web/</List.Header>
                    <List.Description>
                      {intermediaire?.intermediaires?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>

        {intermediaire?.intermediaires?.type_intermediaire ===
          TYPES_INTERMEDIAIRE.AGENT_GENERAL && (
          <>
            <UsersListTable
              intermediaireUuid={intermediaire?.intermediaires?.uuid}
            />
            <AgenciesListTable
              intermediaireUuid={intermediaire?.intermediaires?.uuid}
            />
          </>
        )}
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>
                Commissions <small>(en %)</small>
              </Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input type="number">
                <input
                  value={commissions}
                  onChange={(e) => setCommissions(e.target.value)}
                />
              </Input>

              <Button
                style={{ marginLeft: "10px" }}
                icon={<Icon name="save" />}
                onClick={() => updateCommissions()}
              />
            </Grid.Column>
          </Grid>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default CompanyPartnersDetails;
