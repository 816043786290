import React from "react";
import PropTypes from "prop-types";
import { authState } from "../atoms/authState";
import { useRecoilValue } from "recoil";

/**
 * Fonction utilitaire pour vérifier une permission dans un objet imbriqué
 * ou via une notation pointillée.
 */
const checkPermission = (permissions, permPath) => {
  const permParts = permPath.split(".");
  let currentPermissions = permissions;

  for (const part of permParts) {
    if (!currentPermissions || !currentPermissions[part]) {
      return false;
    }
    currentPermissions = currentPermissions[part];
  }

  // Vérifie si c'est une valeur booléenne ou un objet
  if (typeof currentPermissions === "object") {
    return Object.values(currentPermissions).some((value) => value === true);
  }

  return currentPermissions === true;
};

const PermissionWrapper = ({
  requiredPermissions = [],
  fallback = null,
  children,
}) => {
  const auth = useRecoilValue(authState);
  const userPermissions =
    auth?.user?.users_group_permissions?.permissions || {};

  // Vérifie si toutes les permissions nécessaires sont présentes
  const hasPermission = requiredPermissions.every((perm) =>
    checkPermission(userPermissions, perm)
  );

  return hasPermission ? <>{children}</> : <>{fallback}</>;
};

PermissionWrapper.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default PermissionWrapper;
