import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as compagniesService from "../../services/comagniesService";

const validationSchema = yup.object().shape({
  denomination: yup.string().required("La dénomination est requise"),
  siege_social: yup.string().required("Le siège social est requis"),
  phone_1: yup
    .string()
    .min(8, "Minimum 8 caractères")
    .max(16, "Maximum 16 caractères"),
  phone_2: yup
    .string()
    .min(8, "Minimum 8 caractères")
    .max(16, "Maximum 16 caractères"),
  is_active: yup.number().min(0).max(1),
  code: yup.string().min(2).max(2),
  email: yup.string().email().nullable(),
});
function CompanyFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedCompanyUuid = null,
  handleClose = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      siege_social: "",
      phone_1: "",
      phone_2: "",
      is_active: 0,
      code: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const serviceCall = selectedCompanyUuid
        ? compagniesService.update(selectedCompanyUuid, values)
        : compagniesService.create(values);

      serviceCall
        .then(
          (response) => {
            const message = selectedCompanyUuid
              ? "Compagnie mise à jour avec succès."
              : "Compagnie ajoutée avec succès.";
            toast(message, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
  } = formik;

  const fetchCompany = async () => {
    try {
      const response = await compagniesService.fetchOne(selectedCompanyUuid);
      if (response.status === 200) {
        formik.setValues({
          denomination: response.data.denomination || "",
          siege_social: response.data.siege_social || "",
          phone_1: response.data.phone_1 || "",
          phone_2: response.data.phone_2 || "",
          is_active: response.data.is_active,
          code: response.data.code || "",
          email: response.data.email || "",
        });
      }
    } catch (error) {
      toast(`${error?.response?.data || error?.message}`, {
        type: "error",
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (selectedCompanyUuid) {
      fetchCompany();
    }
  }, [selectedCompanyUuid]);

  return (
    <Modal
      onClose={() => {
        formik.resetForm();
        handleClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <Modal.Header>
        {selectedCompanyUuid ? "Modifier" : "Ajouter"} Une compagnie
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Field error={"siege_social" in errors}>
            <label>Siège social</label>
            <Input
              value={values.siege_social}
              onChange={handleChange("siege_social")}
              onBlur={handleBlur("siege_social")}
            />
            <small className="field-error">{errors.siege_social}</small>
          </Form.Field>
          <Form.Field error={"email" in errors}>
            <label>Email</label>
            <Input
              value={values.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
            />
            <small className="field-error">{errors.email}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field error={"phone_1" in errors}>
              <label>Téléphone principal</label>
              <Input
                value={values.phone_1}
                onChange={handleChange("phone_1")}
                onBlur={handleBlur("phone_1")}
              />
              <small className="field-error">{errors.phone_1}</small>
            </Form.Field>
            <Form.Field error={"phone_2" in errors}>
              <label>Téléphone secondaire</label>
              <Input
                value={values.phone_2}
                onChange={handleChange("phone_2")}
                onBlur={handleBlur("phone_2")}
              />
              <small className="field-error">{errors.phone_2}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field error={"code" in errors}>
              <label>Code</label>
              <Input value={values.code} onChange={handleChange("code")} />
              <small className="field-error">{errors.code}</small>
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                type="checkbox"
                label="Compagnie active"
                checked={values.is_active === 1}
                onChange={(e, { checked }) => {
                  formik.setFieldValue("is_active", checked ? 1 : 0);
                }}
              />
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            formik.resetForm();
            handleClose();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyFormModal;
