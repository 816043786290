import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as civilitiesService from "../../services/civilitiesService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(2, "Le libellé doit contenir au moins 2 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function CivilityFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedCivilityId,
  handleClose = () => {},
}) {
  const fetchCivility = useCallback(async () => {
    if (!selectedCivilityId) {
      return;
    }

    try {
      const response = await civilitiesService.fetchOne(selectedCivilityId);

      const civilityData = response.data.civility || response.data;

      if (civilityData.libelle) {
        formik.setValues({
          libelle: civilityData.libelle,
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de la civilité: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedCivilityId]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedCivilityId;

        const serviceCall = isEditMode
          ? civilitiesService.update(selectedCivilityId, values)
          : civilitiesService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Civilité modifiée avec succès"
            : "Civilité ajoutée avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedCivilityId) {
      fetchCivility();
    } else {
      formik.resetForm();
    }
  }, [selectedCivilityId, fetchCivility]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedCivilityId ? "Modifier une civilité" : "Ajouter une civilité"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={(e, { value }) => {
                formik.setFieldValue("libelle", value);
              }}
              onBlur={formik.handleBlur("libelle")}
              error={formik.touched.libelle && formik.errors.libelle}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          type="button"
          content={selectedCivilityId ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            formik.handleSubmit();
          }}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CivilityFormModal;
/* import React from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as civilitiesService from "../../services/civilitiesService";

const validationSchema = yup.object().shape({
  libelle: yup.string().required(),
});
function CivilityFormModal({ callback = () => {}, open = false, setOpen }) {
  const formik = useFormik({
    initialValues: {
      libelle: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      civilitiesService
        .create(values)
        .then(
          (response) => {
            toast(`Civilité ajoutée avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter une civilité</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"libelle" in errors}>
            <label>libelle</label>
            <Input
              value={values.libelle}
              onChange={handleChange("libelle")}
              onBlur={handleBlur("libelle")}
            />
            <small className="field-error">{errors.libelle}</small>
          </Form.Field>         
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CivilityFormModal;
 */
