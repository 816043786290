import React from 'react'
import { Button, Icon, Label, List, Modal } from 'semantic-ui-react'

function AuthLogDetailsModal({
    isModalOpen,
    setIsModalOpen,
    selectedLog,
    renderUserInfo
}) {
  return (
    (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} closeIcon>
        <Modal.Header>Détails du Journal</Modal.Header>
        <Modal.Content>
          {selectedLog && (
            <List divided relaxed>
              <List.Item>
                <List.Content>
                  <List.Header>ID</List.Header>
                  <List.Description>{selectedLog.id}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Utilisateur</List.Header>
                  <List.Description>
                    {renderUserInfo(selectedLog.user)}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Action</List.Header>
                  <List.Description>
                    <Label
                      size="mini"
                      color={
                        selectedLog.action === "login"
                          ? "blue"
                          : selectedLog.action === "logout"
                          ? "orange"
                          : "grey"
                      }
                    >
                      <Icon
                        name={`${
                          selectedLog.action === "login"
                            ? "sign in"
                            : selectedLog.action === "logout"
                            ? "sign out"
                            : "question"
                        }`}
                      />
                      {selectedLog.action === "login"
                        ? "Connexion"
                        : selectedLog.action === "logout"
                        ? "Déconnexion"
                        : selectedLog.action}
                    </Label>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Statut</List.Header>
                  <List.Description>
                    <Label
                      size="mini"
                      color={selectedLog.success ? "green" : "red"}
                      content={selectedLog.success ? "Succès" : "Échec"}
                    />
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Message</List.Header>
                  <List.Description>
                    {selectedLog.message || "N/A"}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Adresse IP</List.Header>
                  <List.Description>
                    {selectedLog.ip_address || "N/A"}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Navigateur</List.Header>
                  <List.Description>{`${selectedLog.browser || "N/A"} ${
                    selectedLog.version || ""
                  }`}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Système d'exploitation</List.Header>
                  <List.Description>{selectedLog.os || "N/A"}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Plateforme</List.Header>
                  <List.Description>
                    {selectedLog.platform || "N/A"}
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Date et Heure</List.Header>
                  <List.Description>
                    {new Date(selectedLog.timestamp).toLocaleString()}
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsModalOpen(false)}>Fermer</Button>
        </Modal.Actions>
      </Modal>
    )
  )
}

export default AuthLogDetailsModal