import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import UserFormModal from "../../components/modalForms/UserFormModal";
import Page from "../../components/Page";

import * as userService from "../../services/usersService";
import { getUserRoleReadableText } from "../../utils/commons";

function UsersList() {
  const [users, setUsers] = useState([]);
  const [openUserForm, setOpenUserForm] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await userService.fetchAll({
        search,
        page: pagination?.currentPage || 1,
        limit: 10,
      });

      if (response.status === 200 && Array.isArray(response.data?.users)) {
        setUsers(response.data.users);
        setPagination(response.data.pagination);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  };

  useEffect(() => {
    fetchUsers();
    return () => {};
  }, [search, pagination?.currentPage]);
  return (
    <Page title="Utilisateurs">
      <GridRow className="Users">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Utilisateurs</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpenUserForm(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment loading={loading}>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nom</Table.HeaderCell>
                  <Table.HeaderCell>Prénom</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Téléphones</Table.HeaderCell>
                  <Table.HeaderCell>Type de compte</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {users.map((user, index) => {
                  return (
                    <Table.Row key={`${user.idusers}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{user.first_name}</Table.Cell>
                      <Table.Cell>{user.last_name}</Table.Cell>
                      <Table.Cell>{user.email}</Table.Cell>
                      <Table.Cell>{user.phone}</Table.Cell>
                      <Table.Cell>
                        {getUserRoleReadableText(user.user_role)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() => {
                                navigate(`details/${user.idusers}`);
                              }}
                            />

                            {!["AGENT_GENERAL_USER"].includes(
                              user.user_role
                            ) && (
                              <>
                                <Dropdown.Item
                                  icon={<Icon name="edit" />}
                                  content="Editer"
                                  onClick={() => {
                                    setOpenUserForm(true);
                                    setSelectedId(user.idusers);
                                  }}
                                />
                                <Dropdown.Item
                                  icon={<Icon name="trash" />}
                                  content="Supprimer"
                                />
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              {pagination?.totalPages > 1 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="right">
                      <Pagination
                        activePage={pagination?.currentPage}
                        totalPages={pagination?.totalPages}
                        onPageChange={handlePageChange}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
      <UserFormModal
        open={openUserForm}
        setOpen={setOpenUserForm}
        callback={fetchUsers}
        selectedId={selectedId}
        handleClose={() => {
          setSelectedId(null);
        }}
      />
    </Page>
  );
}

export default UsersList;
