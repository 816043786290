import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as usersService from "../../services/usersService";
import * as intermediairesService from "../../services/intermediairesService";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  idusers: yup.number().required(),
  idintermediaires: yup.string().required(),
  active: yup.boolean(),
});

function IntermediaireUserFormModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
  intermediaire = null,
  type_intermediaire = null,
}) {
  const [intermediaireUsersOptions, setCompanyUsersOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      idusers: "",
      idintermediaires: "",
      is_admin: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      intermediairesService
        .addUser(values)
        .then(
          (response) => {
            toast(`Utilisateur ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchUsers = async () => {
    try {
      const response = await usersService.fetchAll({
        all: true,
        user_type: `${type_intermediaire}_USER`,
      });
      if (response.status === 200 && Array.isArray(response.data?.users)) {
        let options = [
          {
            key: "DEFAULT",
            text: "Choisir...",
            value: "",
          },
        ];
        for (const user of response.data?.users) {
          options.push({
            key: `${user.idusers}`,
            text: `${user.first_name} ${user.last_name}`,
            value: user.idusers,
          });
        }
        setCompanyUsersOptions(options);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setFieldValue("idintermediaires", intermediaire?.idintermediaires, true);
    fetchUsers();
    return () => {};
  }, [intermediaire]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
    >
      <Modal.Header>Ajouter un utilisateur</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errors.idintermediaires && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.idintermediaires}
            </div>
          )}
          <Form.Field error={"idusers" in errors}>
            <label>Utilisateur</label>
            <Form.Select
              search
              fluid
              onChange={(e, data) => setFieldValue("idusers", data.value)}
              options={intermediaireUsersOptions}
              placeholder="Choisr..."
            />
            <small className="field-error">{errors.idusers}</small>
          </Form.Field>

          <Form.Field error={"is_admin" in errors}>
            <Form.Checkbox
              onChange={(e, data) => setFieldValue("is_admin", data.checked)}
              label="Ajouter comme administrateur."
              checked={values.is_admin}
              toggle
            />
            <small className="field-error">{errors.is_admin}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediaireUserFormModal;
