import { api } from "../../axios/api";

export const fetchAll = async ({
  page,
  limit,
  search,
  startDate,
  endDate,
} = {}) => {
  return api.get(`logs/authentication/actions`, {
    params: { page, limit, search, startDate, endDate },
  });
};
