import { api } from "../../axios/api";

export const fetchAll = async ({
  page,
  limit,
  search,
  startDate,
  endDate,
} = {}) => {
  return api.get(`logs/authentication`, {
    params: { page, limit, search, startDate, endDate },
  });
};

export const deleteOne = async (id) => {
  return api.delete(`auth-logs/${id}`);
};
