import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

const validationSchema = yup.object().shape({
  date_avenant: yup.date().required("La date de l'avenant est obligatoire"),
  amount: yup
    .number()
    .min(5000, "Le montant de la ristourne doit être supérieur à 5000 FCFA")
    .required("Le montant de la ristourne est obligatoire"),
});

function AvenantResialiationModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);
  const [primeNetteGarantiesFacultatives, setPrimeNetteGarantiesFacultatives] =
    useState(0);
  const [jours_restants_a_courir, setJoursRestantsACourir] = useState(0);

  const formik = useFormik({
    initialValues: {
      date_avenant: "",
      amount: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        date_avenant: values.date_avenant,
        amount: values.amount,
      };
      avenantsService
        .createAvenantResilliation(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Résilliation effectuée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(souscription_uuid);
      setSouscription(response.data);

      let primeNetteGarantiesFacultatives = response.data.souscription_garanties
        .filter((g) => g.company_has_liste_garanties.is_required !== 1)
        .map((g) =>
          g.souscription_garanties_risques.reduce((sum, r) => sum + r.value, 0)
        )
        .reduce((sum, g) => sum + g, 0);
      setPrimeNetteGarantiesFacultatives(primeNetteGarantiesFacultatives);
    } catch (error) {}
  };

  const calculateRistourne = () => {
    try {
      const date_application = dayjs(values.date_avenant).add(1, "day");
      const duree_contrat = dayjs(
        souscription?.conditions_particulieres?.date_echeance
      )
        .add(1, "minutes")
        .diff(
          dayjs(souscription?.conditions_particulieres?.date_effet),
          "days"
        );

      const jours_restants_contrat_initial = dayjs(
        souscription?.conditions_particulieres?.date_echeance
      ).diff(
        dayjs.max(
          dayjs(),
          dayjs(souscription?.conditions_particulieres?.date_effet)
        ),
        "days"
      );

      const jours_courus_a_partir_de_la_date_d_application =
        date_application.diff(
          dayjs.max(
            dayjs(),
            dayjs(souscription?.conditions_particulieres?.date_effet)
          ),
          "days"
        );

      const jours_restants_a_courir =
        jours_restants_contrat_initial -
        jours_courus_a_partir_de_la_date_d_application;

      setJoursRestantsACourir(jours_restants_a_courir);

      const ristourne =
        ((primeNetteGarantiesFacultatives + Number(souscription?.prime_nette)) *
          jours_restants_a_courir) /
        duree_contrat;
      return Math.round(ristourne);
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    if (souscription_uuid && open === true) {
      fetchSouscription();
    }
  }, [souscription_uuid, open]);

  useEffect(() => {
    if (values.date_avenant && dayjs(values.date_avenant).isValid()) {
      setFieldValue("amount", calculateRistourne());
    }
  }, [values.date_avenant, jours_restants_a_courir]);

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean" ? setOpen(true) : setOpen("retrait-garantie");
      }}
      open={typeof open === "string" ? open === "retrait-garantie" : open}
      closeIcon
      onUnmount={() => {
        if (open !== true) {
          handleReset();
        }
      }}
      size="fullscreen"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de résilliation</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui tiny form">
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={3}>
                  {souscription && (
                    <>
                      <Segment raised>
                        <Header as="h5">Informations de la souscription</Header>
                        <List>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date de prise d'effet</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_effet
                                ).format("DD/MM/YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date d'expiration</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                )
                                  .add(1, "minutes")
                                  .subtract(1, "day")
                                  .format("DD/MM/YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Durée du contrat</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                )
                                  .add(1, "day")
                                  .diff(
                                    dayjs(
                                      souscription?.conditions_particulieres
                                        ?.date_effet
                                    ),
                                    "days"
                                  )}{" "}
                                jours
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Segment>
                      <Segment raised>
                        <Form.Field required error={!!errors.date_avenant}>
                          <label>Date de résilliation</label>
                          <Input
                            type="date"
                            value={values.date_avenant}
                            onChange={handleChange("date_avenant")}
                            onBlur={handleBlur("date_avenant")}
                            min={dayjs().format("YYYY-MM-DD")}
                          />
                          <small className="field-error">
                            {errors.date_avenant}
                          </small>
                        </Form.Field>
                        <Message>
                          <Message.Content>
                            <p>
                              La mise en vigueur de l'avenant est fixée au
                              lendemain de la date de l'avenant.
                            </p>
                          </Message.Content>
                        </Message>
                      </Segment>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={9}>
                  <Grid columns={3} stackable>
                    {souscription?.souscription_garanties
                      .filter(
                        (garantie) =>
                          garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .is_required !== 1 &&
                          garantie.souscription_garanties_risques?.length > 0 &&
                          garantie.souscription_garanties_risques?.reduce(
                            (sum, r) => sum + r.value,
                            0
                          ) > 0
                      )
                      .map((garantie) => (
                        <Grid.Column key={garantie.uuid}>
                          <Segment raised>
                            <Header as="h5">
                              {
                                garantie.company_has_liste_garanties
                                  .pool_directives_tarifaires_has_listes_garanties
                                  .liste_garanties.name
                              }
                            </Header>
                            <Divider />
                            <List relaxed>
                              {garantie.souscription_garanties_risques
                                .filter((risque) => risque.value !== 0)
                                .map((risque) => (
                                  <List.Item key={risque.uuid}>
                                    <List.Icon name="check" size="small" />
                                    <List.Content>
                                      <List.Header>
                                        {garantie.company_has_liste_garanties
                                          .pool_directives_tarifaires_has_listes_garanties
                                          .liste_garanties.name === "Franchise"
                                          ? "Prime de franchise"
                                          : risque.liste_garanties_risques.name}
                                      </List.Header>
                                      <List.Description>
                                        {numeral(risque.value).format("")} FCFA
                                      </List.Description>
                                    </List.Content>
                                  </List.Item>
                                ))}
                            </List>
                          </Segment>
                        </Grid.Column>
                      ))}
                  </Grid>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Segment raised>
                    <Header dividing as="h5">
                      Détails financiers
                    </Header>
                    <List divided>
                      <List.Item>
                        <List.Icon name="pin" />
                        <List.Content>
                          <List.Header>Prime nette RC</List.Header>
                          <List.Description className="text-right">
                            {numeral(souscription?.prime_nette).format("")} FCFA
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="money bill alternate" />
                        <List.Content>
                          <List.Header>
                            Prime nette garanties facultatives
                          </List.Header>
                          <List.Description className="text-right">
                            {numeral(primeNetteGarantiesFacultatives).format(
                              ""
                            )}{" "}
                            FCFA
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="money bill alternate" />
                        <List.Content>
                          <List.Header>Prime nette totale</List.Header>
                          <List.Description className="text-right">
                            {numeral(
                              primeNetteGarantiesFacultatives +
                                Number(souscription?.prime_nette)
                            ).format("")}{" "}
                            FCFA
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="calendar alternate" />
                        <List.Content>
                          <List.Header>Jours restants à courir</List.Header>
                          <List.Description className="text-right">
                            {jours_restants_a_courir} jours
                          </List.Description>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="percent" />
                        <List.Content>
                          <List.Header>Ristourne</List.Header>
                          <List.Description className="text-right !font-extrabold">
                            {numeral(values.amount).format("")} FCFA
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              !values.date_avenant ||
              dayjs(values.date_avenant).isBefore(
                souscription?.conditions_particulieres?.date_effet
              ) ||
              !dayjs(values.date_avenant).isValid()
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default AvenantResialiationModal;
