import React, { useEffect, useCallback, useState } from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Modal,
  Segment,
  Select,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as groupPermissionsService from "../../services/configuration_permissions/groupsPermissionsService";
import * as userPermissionsService from "../../services/configuration_permissions/userPermissionsService";
import {
  cleanPermissions,
  getTypePermissionTemplateByUserRole,
} from "../../utils/commons";
import PermissionSubmoduleContent from "../PermissionSubmoduleContent";

const validationSchema = yup.object().shape({
  idgroup_permissions: yup.number().required("Le groupe/template est requis"),
  idusers: yup.number().required("L'utilisateur est requis"),
  permissions: yup.object().shape({}),
  selectedPermissions: yup.object().shape({}),
});

function UserPermissionsModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedGroupPermissionId,
  handleClose = () => {},
  user,
}) {
  const [moduleAccordions, setModuleAccordions] = useState({});
  const [submoduleAccordions, setSubmoduleAccordions] = useState({});
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      selectedPermissions: {},
      idgroup_permissions: "",
      idusers: user?.idusers,
      permissions: {},
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const dataToSend = {
          idusers: values.idusers,
          permissions: values.selectedPermissions,
          idgroup_permissions: values.idgroup_permissions,
        };
        const response = await userPermissionsService.saveUserPermissions(
          dataToSend
        );

        if (response.status === 201 || response.status === 200) {
          toast.success("Permissions modifiées avec succès");
          callback();
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchUserPermissions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await userPermissionsService.getUserPermissions(
        user?.idusers
      );
      formik.setFieldValue(
        "selectedPermissions",
        response.data?.permissions || {}
      );
      formik.setFieldValue(
        "idgroup_permissions",
        response.data?.idgroup_permissions
      );
      formik.setFieldValue("idusers", user?.idusers);
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération des permissions: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  }, [user?.idusers]);

  const fetchGroupPermissionsByUserRole = useCallback(async () => {
    try {
      setLoading(true);
      const response = await groupPermissionsService.fetchAll(
        null,
        null,
        null,
        getTypePermissionTemplateByUserRole(user?.user_role)
      );
      setGroupPermissions(response.data?.groupPermissions || []);
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération des groupes/templates: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  }, [user?.user_role]);

  const fetchBasePermissions = useCallback(
    async (type) => {
      try {
        const response = await groupPermissionsService.fetchBasePermissions(
          type
        );
        formik.setFieldValue("permissions", response.data || {});
      } catch (error) {
        toast.error(
          `Erreur lors de la récupération des permissions de base: ${
            error.response?.data?.message || error.message
          }`
        );
      }
    },
    [user?.user_role]
  );

  useEffect(() => {
    if (open && user) {
      fetchUserPermissions();
      fetchGroupPermissionsByUserRole();
      fetchBasePermissions(
        getTypePermissionTemplateByUserRole(user?.user_role)
      );
    }
  }, [
    open,
    user,
    fetchUserPermissions,
    fetchGroupPermissionsByUserRole,
    fetchBasePermissions,
  ]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };
  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size="fullscreen"
      closeIcon
      style={{
        width: "100%",
        height: "100%",
        margin: "0",
        padding: "0",
        top: "0",
        left: "0",
        borderRadius: "0",
        overflow: "hidden",
      }}
    >
      <Modal.Header>Gérer les permissions</Modal.Header>
      <Modal.Content style={{ height: "100%" }}>
        <Modal.Description>
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Loader active inline size="large" />
            </div>
          ) : (
            <Grid>
              <Grid.Column width={4}>
                <Segment raised>
                  <Form.Field
                    required
                    error={!!formik.errors.idgroup_permissions}
                  >
                    <label>Groupe/template de permissions</label>
                    <Select
                      options={[
                        { text: "Choisir le groupe/template", value: "" },
                      ].concat(
                        groupPermissions.map((groupPermission) => ({
                          text: groupPermission.name,
                          value: groupPermission.idgroup_permissions,
                        }))
                      )}
                      value={formik.values.idgroup_permissions}
                      onChange={(e, { value }) => {
                        formik.setFieldValue("idgroup_permissions", value);
                        formik.setFieldValue("selectedPermissions", {});
                        fetchBasePermissions(
                          getTypePermissionTemplateByUserRole(user?.user_role)
                        );

                        formik.setFieldValue(
                          "selectedPermissions",
                          groupPermissions.find(
                            (groupPermission) =>
                              groupPermission.idgroup_permissions === value
                          )?.permissions || {}
                        );
                      }}
                      error={
                        formik.touched.idgroup_permissions &&
                        formik.errors.idgroup_permissions
                      }
                    />
                  </Form.Field>
                  <Button
                    type="button"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    positive
                    loading={formik.isSubmitting}
                    disabled={!formik.isValid || formik.isSubmitting}
                    fluid
                  >
                    {selectedGroupPermissionId ? "Modifier" : "Enregistrer"}
                  </Button>
                </Segment>
              </Grid.Column>
              <Grid.Column width={12}>
                <Segment
                  raised
                  className="max-h-[calc(100vh-100px)] overflow-y-auto"
                >
                  {Number(formik.values.idgroup_permissions) > 0 &&
                  Object.keys(formik.values.permissions).length > 0 ? (
                    <>
                      <Header dividing as="h3">
                        Permissions
                      </Header>
                      {Object.entries(formik.values.permissions).map(
                        ([moduleKey, moduleData]) => (
                          <Accordion
                            key={moduleKey}
                            fluid
                            styled
                            className="!mb-2"
                          >
                            <Accordion.Title
                              active={moduleAccordions[moduleKey]}
                              onClick={() => {
                                setModuleAccordions((prev) => ({
                                  ...prev,
                                  [moduleKey]: !prev[moduleKey],
                                }));
                              }}
                            >
                              <Icon name="dropdown" />
                              {moduleData.label}
                            </Accordion.Title>
                            <Accordion.Content
                              active={moduleAccordions[moduleKey]}
                            >
                              {/* Permissions du module principal */}
                              {moduleData.permissions &&
                                moduleData.permissions.length > 0 && (
                                  <div className="mb-4 ml-4">
                                    <Header as="h5">
                                      Permissions générales
                                    </Header>
                                    <div className="p-2 flex flex-row flex-wrap gap-4">
                                      {moduleData.permissions.map(
                                        (permission) => (
                                          <div
                                            key={`${moduleKey}-${permission.key}`}
                                            className="mb-2"
                                          >
                                            <Checkbox
                                              toggle
                                              label={`${permission.description}`}
                                              checked={
                                                formik.values
                                                  .selectedPermissions?.[
                                                  moduleKey
                                                ]?.[permission.key] || false
                                              }
                                              onChange={(e, { checked }) => {
                                                formik.setFieldValue(
                                                  `selectedPermissions.${moduleKey}.${permission.key}`,
                                                  checked
                                                );
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}

                              {/* Sous-modules avec récursivité */}
                              {moduleData.submodules &&
                                moduleData.submodules.map((submodule) => (
                                  <PermissionSubmoduleContent
                                    key={`${moduleKey}-${submodule.key}`}
                                    moduleKey={moduleKey}
                                    submodule={submodule}
                                    formik={formik}
                                    submoduleAccordions={submoduleAccordions}
                                    setSubmoduleAccordions={
                                      setSubmoduleAccordions
                                    }
                                  />
                                ))}
                            </Accordion.Content>
                          </Accordion>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <Header dividing as="h3">
                        Permissions
                      </Header>
                      <p>Veuillez choisir le type du groupe de permissions</p>
                    </>
                  )}
                </Segment>
              </Grid.Column>
            </Grid>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
}

export default UserPermissionsModal;
