import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { findOne } from "../../../services/gestion_production/policesService";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";

function ChangementPriseEffetPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PreviewTemplateLayout souscription={souscription} />
          <PreviewContentWrapper souscription={souscription}>
            <View style={styles.avenantHeaderSection}>
              <Text style={styles.avenantHeaderTitle}>
                Avenant de changement de prise d'effet
              </Text>
            </View>
            <View
              style={{
                textAlign: "center",
                fontSize: 12,
                fontWeight: "bold",
                marginTop: 25,
              }}
            >
              <Text>
                (Concerne la police Automobile n°{" "}
                {souscriptionWithAvenant?.souscription_polices?.numero_police})
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 10,
                marginTop: 25,
                borderColor: "#CCC",
                borderWidth: 1,
                padding: 10,
              }}
            >
              <View style={{ width: "50%" }}>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 10 }}
                >
                  <Text>Assuré</Text>
                  <Text>:</Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.nom_assure
                    }
                  </Text>
                </View>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 10 }}
                >
                  <Text>Adresse</Text>
                  <Text>:</Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.address_assure
                    }
                  </Text>
                </View>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 10 }}
                >
                  <Text>Téléphone</Text>
                  <Text>:</Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.phone_assure
                    }
                  </Text>
                </View>
              </View>
              <View style={{ width: "50%" }}>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 10 }}
                >
                  <Text>Date d'effet du contrat</Text>
                  <Text>:</Text>
                  <Text>
                    {dayjs(
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_effet
                    ).format("DD/MM/YYYY")}
                  </Text>
                </View>

                <View
                  style={{ display: "flex", flexDirection: "row", gap: 10 }}
                >
                  <Text>Date d'exp. du contrat</Text>
                  <Text>:</Text>
                  <Text>
                    {dayjs(
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_echeance
                    )
                      .add(1, "minute")
                      .subtract(1, "day")
                      .format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ marginTop: 50 }}>
              <Text>
                A la demande de l'assuré et d'un commun accord entre les
                parties, il est convenu que la date de prise d'effet de la
                police ci-dessus indiquée, précédemment fixée au{" "}
                {dayjs(avenant?.meta_data?.last_date_prise_effet).format(
                  "DD MMMM YYYY"
                )}
                {", "}
                est maintenant fixée au{" "}
                {dayjs(avenant?.meta_data?.date_prise_effet).format(
                  "DD MMMM YYYY"
                )}
                .
              </Text>
            </View>

            <View style={{ marginTop: 25 }}>
              <Text>
                L’avenant de modification étant acté, il n’est rien changé
                d’autre aux conditions tant générales que particulières de la
                police.
              </Text>
            </View>

            <View style={{ marginTop: 70 }}>
              <Text style={{ textAlign: "right" }}>
                Fait en 02 exemplaires à ___________________________, le{" "}
                {dayjs().format("DD/MM/YYYY")}
              </Text>
              <View
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontWeight: "extrabold",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  Pour l'assuré
                </Text>
                <Text
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  Pour la compagnie
                </Text>
              </View>
            </View>
          </PreviewContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ChangementPriseEffetPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 50,
    fontSize: 10,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 10,
  },
  avenantHeaderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
  columnsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },
  column: {
    width: "45%",
  },
  line: {
    marginBottom: 10,
    padding: 5,
    borderBottom: "1pt solid #000",
  },
});
