import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import {
  PDFViewer,
  Page as PageView,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { toast } from "react-toastify";
import * as paymentService from "../../../services/gestion_production/paymentService";
import PreviewContent from "./PreviewContent";

function EncaissementPreviewModal({
  open = false,
  setOpen,
  paymentUuid = null,
}) {
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(false);

  const fetchPayment = async () => {
    try {
      const response = await paymentService.getPayment(paymentUuid);
      if (response?.status === 200) {
        setPayment(response?.data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue!");
    }
  };
  useEffect(() => {
    if (open && paymentUuid) {
      fetchPayment();
    }
    return () => {};
  }, [paymentUuid, open]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      closeIcon
      size="fullscreen"
    >
      <Modal.Header style={{ fontSize: "12px" }}>Preview</Modal.Header>
      <Modal.Content style={{ padding: "0px" }}>
        <Modal.Description>
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
              <PageView size="A4" style={styles.page}>
                {payment && (
                  <>
                    <PreviewContent payment={payment} styles={styles} />
                    <View style={{ height: 2, backgroundColor: "black" }} />
                    <PreviewContent payment={payment} styles={styles} />
                  </>
                )}
              </PageView>
            </Document>
          </PDFViewer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EncaissementPreviewModal;
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});
