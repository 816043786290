import React from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
} from "semantic-ui-react";

function Breadcrumber({ breadcrumbs = [{ label: "", link: "" }], active }) {
  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index, array) => {
        if (!breadcrumb?.label) return null;

        const isLast = index === array.length - 1;

        return (
          <React.Fragment key={`${breadcrumb.label}-${index}`}>
            <BreadcrumbSection
              link={!isLast && breadcrumb.link}
              active={isLast}
            >
              {breadcrumb.label}
            </BreadcrumbSection>

            {!isLast && <BreadcrumbDivider icon="right chevron" />}
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
}

export default Breadcrumber;
