import React, { useState } from "react";
import { Dimmer, Divider, Form, Loader, Modal, Radio } from "semantic-ui-react";
import ParticulierForm from "../SouscripteurForms/ParticulierForm";
import EntrepriseForm from "../SouscripteurForms/EntrepriseForm";
import * as countriesService from "../../services/countriesService";
import * as souscripteursService from "../../services/souscripteursService";

function SouscripteursFormModal({
  uuid = null,
  callback = () => {},
  open = false,
  setOpen,
  hideModalCallback = () => {},
}) {
  const [groupSouscripteur, setGroupSouscripteur] = useState("PARTICULIER");
  const [countries, setCountries] = useState([]);
  const [souscripteur, setSouscripteur] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCountries = () => {
    countriesService
      .fetchAll()
      .then(
        (response) => {
          if (Array.isArray(response.data?.countries)) {
            setCountries(response.data.countries);
          }
        },
        (reason) => {}
      )
      .finally(() => {});
  };

  const fetchSouscripteur = () => {
    setLoading(true);
    souscripteursService
      .fetchOne(uuid)
      .then((response) => {
        setSouscripteur(response.data);
        setGroupSouscripteur(response.data.groupe_souscripteur);
      })
      .catch((reason) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setSouscripteur(null);
    if (uuid) {
      fetchSouscripteur();
    }
  }, [uuid]);

  React.useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        hideModalCallback();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeIcon
    >
      <Modal.Header>
        {loading
          ? "Chargement..."
          : uuid
          ? "Modifier un souscripteur"
          : "Ajouter un souscripteur"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {loading ? (
            <>
              <div className="text-center">
                <Loader active inline size="massive" />
              </div>
            </>
          ) : (
            <>
              <Form>
                <Form.Group inline required>
                  <label>Type de souscripteur </label>
                  <Form.Field
                    control={Radio}
                    label="Particulier"
                    value="PARTICULIER"
                    onChange={() => setGroupSouscripteur("PARTICULIER")}
                    checked={groupSouscripteur === "PARTICULIER"}
                  />
                  <Form.Field
                    control={Radio}
                    label="Entreprise"
                    value="ENTREPRISE"
                    onChange={() => setGroupSouscripteur("ENTREPRISE")}
                    checked={groupSouscripteur === "ENTREPRISE"}
                  />
                </Form.Group>
              </Form>
              <Divider />
              {groupSouscripteur === "PARTICULIER" ? (
                <ParticulierForm
                  callback={callback}
                  setOpen={setOpen}
                  open={open}
                  countries={countries}
                  souscripteur={souscripteur}
                  hideModalCallback={hideModalCallback}
                />
              ) : (
                <EntrepriseForm
                  callback={callback}
                  setOpen={setOpen}
                  open={open}
                  countries={countries}
                  souscripteur={souscripteur}
                  hideModalCallback={hideModalCallback}
                />
              )}
            </>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default SouscripteursFormModal;
