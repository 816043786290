import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";

export default function GarantiesFacultatives({
  formik,
  garantiesComplementaires,
}) {
  return (
    <Grid.Column width={16}>
      <Segment>
        <Header content="Garanties complémentaires" dividing />
        <Table basic="very" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Prime nette</Table.HeaderCell>
              <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {formik.values.garanties_complementaires?.map((garantie, index) => (
              <Table.Row key={garantie.uuid}>
                <Table.Cell width={10}>
                  <div className="ui form" size="small">
                    <Form.Dropdown
                      placeholder="Sélectionner une garantie"
                      selection
                      search
                      fluid
                      options={garantiesComplementaires.map((garantie) => ({
                        key: garantie.uuid,
                        text: garantie.name,
                        value: garantie.uuid,
                      }))}
                      value={garantie.uuid}
                      onChange={(e, { value }) => {
                        let garanties = formik.values.garanties_complementaires;
                        if (garanties.some((g) => g.uuid === value)) {
                          return toast.error("Garantie déjà sélectionnée");
                        }
                        formik.setFieldValue(
                          "garanties_complementaires",
                          garanties.map((g, i) =>
                            i === index ? { ...g, uuid: value } : g
                          )
                        );
                      }}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell width={5}>
                  <Input
                    type="number"
                    value={garantie.value}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "garanties_complementaires",
                        formik.values.garanties_complementaires.map((g, i) =>
                          i === index ? { ...g, value: e.target.value } : g
                        )
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    icon="trash"
                    size="mini"
                    color="red"
                    circular
                    onClick={() => {
                      const garanties = formik.values.garanties_complementaires;
                      let newItems = garanties.filter((g, i) => i !== index);
                      formik.setFieldValue(
                        "garanties_complementaires",
                        newItems
                      );
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="3">
                <Button
                  icon="plus"
                  size="mini"
                  circular
                  onClick={() => {
                    formik.setFieldValue("garanties_complementaires", [
                      ...formik.values.garanties_complementaires,
                      { uuid: "", value: 0 },
                    ]);
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </Grid.Column>
  );
}
