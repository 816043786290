import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/Page";

import * as intermediairesService from "../../services/intermediairesService";

function PartnersCompagniesList() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchCompagnies = async () => {
    try {
      setLoading(true);
      const response = await intermediairesService.getPartners();
      if (response.status === 200 && Array.isArray(response.data)) {
        setPartners(response.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCompagnies();
    return () => {};
  }, []);

  return (
    <Page title="Nos Partenaires">
      <GridRow className="PartnersCompagniesList">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des partenaires</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
          </Grid.Column>
        </Grid>
        <Segment loading={loading}>
          <>
            <Table striped celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Compagnie</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Téléphone</Table.HeaderCell>
                  <Table.HeaderCell>Ajouté le</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {partners.map((partner, index) => {
                  return (
                    <Table.Row key={`${partner.idcompagnies_intermediaires}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {partner?.compagnies?.denomination}
                      </Table.Cell>
                      <Table.Cell>{partner?.compagnies?.email}</Table.Cell>
                      <Table.Cell>
                        {partner?.compagnies?.phone_1}/
                        {partner?.compagnies?.phone_2}
                      </Table.Cell>

                      <Table.Cell>
                        {dayjs(partner?.created_at).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() =>
                                navigate(`details/${partner.uuid}`)
                              }
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </>
        </Segment>
      </GridRow>
    </Page>
  );
}

export default PartnersCompagniesList;
