import { api } from "../axios/api";

export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`dictionnaire/countries`, { params: { page, search } });
};
export const fetchOne = async (id) => {
  return api.get(`dictionnaire/countries/${id}`);
};
export const create = async (data) => {
  return api.post(`dictionnaire/countries`, data);
};

export const update = async (id, data) => {
  return api.put(`dictionnaire/countries/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`dictionnaire/countries/${id}`);
};
