import React, { useEffect, useState, useCallback } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Grid,
  GridRow,
  Header,
  Label,
  List,
  Segment,
  Table,
  Input,
  Form,
  Pagination,
  Icon,
} from "semantic-ui-react";

import { Link, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesService from "../../services/comagniesService";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import ActionLogDetailsModal from "../../components/journalisation/ActionLogDetailsModal";
const validationSchema = yup.object().shape({
  first_name: yup.string().required("Le prénom est requis"),
  last_name: yup.string().required("Le nom est requis"),
  email: yup.string().email("Email invalide").required("L'email est requis"),
  phone: yup.string().required("Le téléphone est requis"),
});

function CompanyUserDetails() {
  const [user, setUser] = useState();
  const authValue = useRecoilValue(authState);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });
  const { id } = useParams();

  const [alllogs, setAllLogs] = useState([]);

  const [isLoadingLogs, setIsLoadingLogs] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      active: parseInt(user?.active) || 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      compagniesService
        .update(id, values)
        .then(
          (response) => {
            toast("Utilisateur mis à jour avec succès.", {
              type: "success",
              theme: "colored",
            });
            fetchUser();
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { isSubmitting, setSubmitting } = formik;

  // In your UserDetails component
  const fetchUserLogs = async (page = 1) => {
    setIsLoadingLogs(true);
    try {
      const response = await compagniesService.fetchUserLogs(id, {
        page,
        pageSize: pagination.pageSize,
      });

      const {
        data: { data: alllogs, pagination: paginationData },
      } = response;

      setAllLogs(alllogs);
      setPagination(paginationData);
    } catch (error) {
      toast.error(error?.response?.data || "Error fetching alllogs");
    } finally {
      setIsLoadingLogs(false);
    }
  };

  // Usage in useEffect
  useEffect(() => {
    if (id) {
      fetchUser();
      fetchUserLogs(1); // Initial logs fetch
    }
  }, [id]);

  const handlePageChange = useCallback((e, { activePage }) => {
    fetchUserLogs(activePage);
  }, []);

  const fetchUser = async () => {
    setIsLoading(true);

    try {
      const response = await compagniesService.fetchOneUser(id);

      if (response?.status === 200) {
        setUser(response?.data);
      } else {
      }
    } catch (error) {
      toast(`${error?.response?.data || error?.message}`, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        phone: user.phone || "",
        active: parseInt(user.active) || 0,
      });
    }
  }, [user]);

  const formatDate = (date) => {
    return new Date(date).toLocaleString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleViewDetails = useCallback((log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  }, []);

  const renderUserInfo = useCallback((user) => {
    if (!user) return "Utilisateur inconnu";
    return (
      `${user.first_name || ""} ${user.last_name || ""} ${
        user.email ? `(${user.email})` : ""
      }`.trim() || "Inconnu"
    );
  }, []);

  const renderLogsSection = () => (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date et Heure</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Statut</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoadingLogs ? (
                <Table.Row>
                  <Table.Cell colSpan="5" textAlign="center">
                    <div className="ui active centered inline loader"></div>
                  </Table.Cell>
                </Table.Row>
              ) : alllogs.length > 0 ? (
                alllogs.map((log) => (
                  <Table.Row key={log.id}>
                    <Table.Cell>
                      {new Date(log.timestamp).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell>
                      <Label size="mini" color="blue">
                        <Icon name="tasks" />
                        {log.action}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        size="mini"
                        color={log.success ? "green" : "red"}
                        content={log.success ? "Succès" : "Échec"}
                      />
                    </Table.Cell>
                    <Table.Cell>{log.message}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Icon
                        name="eye"
                        color="green"
                        className="cursor-pointer"
                        onClick={() => handleViewDetails(log)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="5" textAlign="center">
                    Aucun journal trouvé
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="5" textAlign="right">
                  <div
                    className="pagination-info"
                    style={{ marginBottom: "10px" }}
                  >
                    Total: {pagination.totalCount} entrées
                  </div>
                  <Pagination
                    activePage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                    disabled={isLoadingLogs}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );

  return (
    <Page title="Détails utilisateur">
      <GridRow className="UserDetails">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link as={Link} to="/pool/administration/users">
                Utilisateurs
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection>Détails de l'utilisateur</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>

        <Header>Détails de l'utilisateur</Header>
        <GridRow as={Segment}>
          <Label ribbon>{`${user?.first_name} ${user?.last_name}`}</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="user" />
                  <List.Content>
                    <List.Header>Nom complet</List.Header>
                    <List.Description>{`${user?.first_name} ${user?.last_name}`}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="mail" />
                  <List.Content>
                    <List.Header>Email</List.Header>
                    <List.Description>{user?.email}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="phone" />
                  <List.Content>
                    <List.Header>Téléphone</List.Header>
                    <List.Description>{user?.phone}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="user circle" />
                  <List.Content>
                    <List.Header>Rôle</List.Header>
                    <List.Description>{user?.user_role}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="calendar" />
                  <List.Content>
                    <List.Header>Créé le</List.Header>
                    <List.Description>
                      {formatDate(user?.created_at)}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="check circle" />
                  <List.Content>
                    <List.Header>Statut</List.Header>
                    <List.Description>
                      <Label color={user?.active === 1 ? "green" : "red"}>
                        {user?.active === 1 ? "Actif" : "Inactif"}
                      </Label>
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>

        {user?.users_compagnies && (
          <GridRow as={Segment}>
            <Header>Compagnie associée</Header>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Dénomination</Table.HeaderCell>
                  <Table.HeaderCell>Siège Social</Table.HeaderCell>
                  <Table.HeaderCell>Téléphone</Table.HeaderCell>
                  <Table.HeaderCell>Admin</Table.HeaderCell>
                  <Table.HeaderCell>Statut</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>1</Table.Cell>
                  <Table.Cell>
                    {user?.users_compagnies?.compagnies?.denomination}
                  </Table.Cell>
                  <Table.Cell>
                    {user?.users_compagnies?.compagnies?.siege_social}
                  </Table.Cell>
                  <Table.Cell>
                    {user?.users_compagnies?.compagnies?.phone_1}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      color={
                        user?.users_compagnies?.is_admin === 1
                          ? "green"
                          : "grey"
                      }
                    >
                      {user?.users_compagnies?.is_admin === 1 ? "OUI" : "NON"}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      color={
                        user?.users_compagnies?.compagnies?.is_active === 1
                          ? "green"
                          : "red"
                      }
                    >
                      {user?.users_compagnies?.compagnies?.is_active === 1
                        ? "Actif"
                        : "Inactif"}
                    </Label>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </GridRow>
        )}

        {renderLogsSection()}
      </GridRow>

      <ActionLogDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedLog={selectedLog}
        renderUserInfo={renderUserInfo}
      />
    </Page>
  );
}

export default CompanyUserDetails;
