import { api } from "../../../axios/api";

export const getGaranties = async (directive_uuid) => {
  return api.get(
    `/gestion-production/companies/garanties/${directive_uuid}/directives-tarifaires`
  );
};

export const storeBatch = async (data) => {
  return api.post("/gestion-production/companies/garanties", data);
};

export const removeItem = async (uuid) => {
  return api.delete(`/gestion-production/companies/garanties/${uuid}`);
};

export const getByActiveDirectivesTarifaires = async (uuid, is_active) => {
  return api.get(
    `/gestion-production/companies/garanties/active/directives-tarifaires`,
    {
      params: { company_uuid: uuid, is_active },
    }
  );
};
