import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import IntermediairesFormModal from "../../components/modalForms/IntermediairesFormModal";
import Page from "../../components/Page";

import * as intermediairesService from "../../services/intermediairesService";
import { getTypeIntermediaireText } from "../../utils/commons";
import { toast } from "react-toastify";

function IntermediairesList() {
  const [intermediaires, setIntermediaires] = useState([]);
  const [pagination, setPagination] = useState({});
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedIntermediaireUuid, setSelectedIntermediaireUuid] =
    useState(null);

  const navigate = useNavigate();

  const fetchIntermediaires = async () => {
    try {
      setLoading(true);
      const response = await intermediairesService.fetchAll({
        search,
        page: pagination.currentPage || 1,
        limit: pagination.itemsPerPage || 10,
      });
      if (response.status === 200 && Array.isArray(response.data.data)) {
        setIntermediaires(response.data.data);
        setPagination(response.data.pagination);
      }
    } catch (error) {
      toast.error("Une erreur est survenue!");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  };

  useEffect(() => {
    fetchIntermediaires();
    return () => {};
  }, [pagination.currentPage, search]);

  return (
    <Page title="Intermediaires">
      <GridRow className="Intermediaires">
        <Grid.Column className="!mb-6">
          <Breadcrumb>
            <Breadcrumb.Section link>Pool</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>Intermediaires</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Liste</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Divider />
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Intermédiaires</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment loading={loading}>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell collapsing>#</Table.HeaderCell>
                  <Table.HeaderCell>code</Table.HeaderCell>
                  <Table.HeaderCell>Dénomination</Table.HeaderCell>
                  <Table.HeaderCell>Siège Social</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Téléphones</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right" collapsing>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {intermediaires?.map((intermediaire, index) => {
                  return (
                    <Table.Row key={`${intermediaire.idintermediaires}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{intermediaire.code}</Table.Cell>
                      <Table.Cell>{intermediaire.denomination}</Table.Cell>
                      <Table.Cell>{intermediaire.siege_social}</Table.Cell>
                      <Table.Cell>
                        {getTypeIntermediaireText(
                          intermediaire.type_intermediaire
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {intermediaire.phone_1}/{intermediaire.phone_2}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() =>
                                navigate(`details/${intermediaire.uuid}`)
                              }
                            />
                            {intermediaire.type_intermediaire ===
                              "COURTIER" && (
                              <>
                                <Dropdown.Item
                                  icon={<Icon name="edit" />}
                                  content="Editer"
                                  onClick={() => {
                                    setSelectedIntermediaireUuid(
                                      intermediaire.uuid
                                    );
                                    setOpen(true);
                                  }}
                                />
                                <Dropdown.Item
                                  icon={<Icon name="eye" />}
                                  content="Supprimer"
                                />
                              </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              {pagination.totalPages > 1 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="8" textAlign="right">
                      <Pagination
                        totalPages={pagination.totalPages}
                        activePage={pagination.currentPage}
                        onPageChange={handlePageChange}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
      <IntermediairesFormModal
        open={open}
        setOpen={setOpen}
        callback={fetchIntermediaires}
        selectedPartnerUuid={selectedIntermediaireUuid}
        setSelectedPartnerUuid={setSelectedIntermediaireUuid}
        handleClose={() => {
          setSelectedIntermediaireUuid(null);
          setOpen(false);
        }}
      />
    </Page>
  );
}

export default IntermediairesList;
