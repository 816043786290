import { Text, View } from "@react-pdf/renderer";
import numeral from "numeral";
import React from "react";

function AssurancePreviewPart({
  souscription,
  GARANTIES_ASSISTANCE_ENUMS,
  styles,
  GarantieSectionHeader,
}) {
  return (
    souscription.souscription_garanties.some((garantie) =>
      GARANTIES_ASSISTANCE_ENUMS.includes(
        garantie.company_has_liste_garanties
          .pool_directives_tarifaires_has_listes_garanties.liste_garanties.name
      )
    ) &&
    souscription.souscription_garanties
      .filter((garantie) =>
        GARANTIES_ASSISTANCE_ENUMS.includes(
          garantie.company_has_liste_garanties
            .pool_directives_tarifaires_has_listes_garanties.liste_garanties
            .name
        )
      )
      .reduce(
        (total, garantie) =>
          total +
          garantie.souscription_garanties_risques.reduce(
            (acc, risque) => acc + Number(risque.value),
            0
          ),
        0
      ) > 0 && (
      <>
        <GarantieSectionHeader title="Assistance" />

        {souscription.souscription_garanties.map((souscription_garantie) => {
          if (
            !GARANTIES_ASSISTANCE_ENUMS.includes(
              souscription_garantie.company_has_liste_garanties
                .pool_directives_tarifaires_has_listes_garanties.liste_garanties
                .name
            )
          )
            return null;

          return (
            <View style={styles.tableRow} key={souscription_garantie.uuid}>
              <Text style={[styles.tableCellGarantie, { paddingLeft: 8 }]}>
                {
                  souscription_garantie.company_has_liste_garanties
                    .pool_directives_tarifaires_has_listes_garanties
                    .liste_garanties.name
                }
              </Text>
              <Text style={styles.tableCell}>Voir annexe</Text>
              <Text style={styles.tableCell}>Voir annexe</Text>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}>
                {numeral(
                  souscription_garantie.souscription_garanties_risques.reduce(
                    (acc, risque) => acc + Number(risque.value),
                    0
                  ) || 0
                ).format()}
              </Text>
              <Text style={styles.tableCellReduction}>0</Text>
              <Text style={styles.tableCellReduction}>0</Text>
              <Text style={styles.tableCellReduction}>0</Text>
              <Text style={styles.tableCell}>
                {numeral(
                  souscription_garantie.souscription_garanties_risques.reduce(
                    (acc, risque) => acc + Number(risque.value),
                    0
                  ) || 0
                ).format()}
              </Text>
            </View>
          );
        })}

        <View style={styles.tableRow}>
          <Text
            style={[
              styles.tableCellGarantie,
              { fontWeight: "ultrabold", textAlign: "left" },
            ]}
          >
            Sous-Total Assistance
          </Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}>
            {numeral(
              souscription.souscription_garanties
                .filter((garantie) =>
                  GARANTIES_ASSISTANCE_ENUMS.includes(
                    garantie.company_has_liste_garanties
                      .pool_directives_tarifaires_has_listes_garanties
                      .liste_garanties.name
                  )
                )
                .reduce(
                  (total, garantie) =>
                    total +
                    garantie.souscription_garanties_risques.reduce(
                      (acc, risque) => acc + Number(risque.value),
                      0
                    ),
                  0
                )
            ).format()}
          </Text>
          <Text style={styles.tableCellReduction}></Text>
          <Text style={styles.tableCellReduction}></Text>
          <Text style={styles.tableCellReduction}></Text>
          <Text style={styles.tableCell}>
            {numeral(
              souscription.souscription_garanties
                .filter((garantie) =>
                  GARANTIES_ASSISTANCE_ENUMS.includes(
                    garantie.company_has_liste_garanties
                      .pool_directives_tarifaires_has_listes_garanties
                      .liste_garanties.name
                  )
                )
                .reduce(
                  (total, garantie) =>
                    total +
                    garantie.souscription_garanties_risques.reduce(
                      (acc, risque) => acc + Number(risque.value),
                      0
                    ),
                  0
                )
            ).format()}
          </Text>
        </View>
      </>
    )
  );
}

export default AssurancePreviewPart;
