import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as intermediaireAgencyRequestService from "../../../../services/gestion_attestations/intermediaire_agency/requestsService";
import IntermediaireAgencyRequestAttestationFormModal from "../../../../components/modalForms/gestion_attestations/IntermediaireAgencyRequestAttestationFormModal";

function RequestsList(s) {
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [pagination, setPagination] = useState({});

  const [selectedPage, setSelectedPage] = useState(1);

  const fetchCommandes = async () => {
    try {
      const { data } = await intermediaireAgencyRequestService.fetchAll({
        page: selectedPage,
      });
      setRequests(data.requests || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des requests"
      );
    }
  };

  const handleUpdateClick = (request) => {
    setselectedRequestId(request.uuid);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setselectedRequestId(null);
    setOpen(false);
  };

  const handleDeleteClick = (request) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette demande ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        intermediaireAgencyRequestService
          .deleteOne(request.uuid)
          .then(() => {
            toast.success("Demande supprimée avec succes");
            fetchCommandes();
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.error ||
                "Une erreur est survenue lors de la suppression de la demande d'attestation"
            );
          });
      }
    });
  };

  useEffect(() => {
    fetchCommandes();
  }, [selectedPage]);

  return (
    <Page title="Liste des demandes d'attestation">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Agence</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to="/intermediaire-agency/stocks/demandes"
              >
                Demandes d'attestions
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des demandes d'attestation [{pagination.total || 0}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            <Button
              className="!ml-4"
              onClick={() => {
                setselectedRequestId(null);
                setOpen(true);
              }}
            >
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Qté demandée</Table.HeaderCell>
                  <Table.HeaderCell>Compagnie</Table.HeaderCell>
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell>Plage attribuée</Table.HeaderCell>
                  <Table.HeaderCell>Qté reçue</Table.HeaderCell>
                  <Table.HeaderCell>Etat</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {requests.map((request, index) => (
                  <Table.Row
                    key={index}
                    className={`${
                      request.attestations_intermediaires_agencies_distributions
                        ? ""
                        : "warning"
                    }`}
                  >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {new Intl.NumberFormat("fr-FR").format(request.quantity)}
                    </Table.Cell>
                    <Table.Cell>{request.compagnies?.denomination}</Table.Cell>

                    <Table.Cell>
                      {dayjs(request.created_at).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_intermediaires_agencies_distributions
                        ? `
                      ${request?.attestations_intermediaires_agencies_distributions?.start_serial}
                      -
                      ${request?.attestations_intermediaires_agencies_distributions?.end_serial}
                      `
                        : "En attente"}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_intermediaires_agencies_distributions
                        ? `${new Intl.NumberFormat("fr-FR").format(
                            parseInt(
                              request
                                ?.attestations_intermediaires_agencies_distributions
                                ?.end_serial
                            ) -
                              parseInt(
                                request
                                  ?.attestations_intermediaires_agencies_distributions
                                  ?.start_serial
                              ) +
                              1
                          )}`
                        : "-- --"}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_intermediaires_agencies_distributions
                        ? "Livrée"
                        : "En attente"}
                    </Table.Cell>

                    <Table.Cell width={1} textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            icon={<Icon name="eye" />}
                            content="Détails"
                            to={`/intermediaire-agency/stocks/demandes/details/${request.uuid}`}
                          />
                          {request?.attestations_intermediaires_agencies_distributions ? null : (
                            <>
                              <Dropdown.Item
                                icon={<Icon name="edit" />}
                                content="Editer"
                                onClick={() => handleUpdateClick(request)}
                              />
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() => handleDeleteClick(request)}
                              />
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {pagination.totalPages > 1 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9" textAlign="right">
                      <Pagination
                        activePage={pagination.currentPage}
                        totalPages={pagination.totalPages}
                        onPageChange={(e, { activePage }) =>
                          setPagination({
                            ...pagination,
                            currentPage: activePage,
                          })
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>

      <IntermediaireAgencyRequestAttestationFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchCommandes();
          setselectedRequestId(null);
        }}
        selectedRequestId={selectedRequestId}
        handleCloseModal={handleCloseModal}
      />
    </Page>
  );
}

export default RequestsList;
