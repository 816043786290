import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .test(
      "is-required-if-ristourne",
      "Le montant de la ristourne est obligatoire et doit être supérieur à 5000 FCFA",
      function (value) {
        const { remise_en_vigueur_type } = this.parent;
        if (remise_en_vigueur_type === "ristourne") {
          return value !== undefined && value >= 5000;
        }
        return true;
      }
    ),
  report_date: yup
    .date()
    .test(
      "is-required-if-report_echeance",
      "La date de report de l'échéance est obligatoire",
      function (value) {
        const { remise_en_vigueur_type } = this.parent;
        if (remise_en_vigueur_type === "report_echeance") {
          return value !== undefined && value !== null;
        }
        return true;
      }
    ),
  remise_en_vigueur_type: yup
    .string()
    .oneOf(["ristourne", "report_echeance", "", null])
    .notRequired(),
  date_avenant: yup.date().required("La date de l'avenant est requise"),
});

function AvenantRemiseEnVigueurModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);
  const [primeNetteGarantiesFacultatives, setPrimeNetteGarantiesFacultatives] =
    useState(0);

  const lastSuspension = (souscription) => {
    const suspensions = souscription?.avenants?.filter((avenant) => {
      return avenant.type_avenant === "SUSPENSION";
    });

    if (!suspensions?.length) return null;
    const sortedSuspensions = suspensions?.sort(
      (a, b) =>
        dayjs(b.date_avenant).valueOf() - dayjs(a.date_avenant).valueOf()
    );
    const lastAvenant = souscription?.avenants[0];

    if (lastAvenant?.type_avenant !== "SUSPENSION") return null;

    return sortedSuspensions[0];
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
      report_date: "",
      remise_en_vigueur_type: "",
      date_avenant: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        date_avenant: values.date_avenant,
        remise_en_vigueur_type: values.remise_en_vigueur_type,
        ...(values.remise_en_vigueur_type === "ristourne" && {
          amount: values.amount,
        }),
        ...(values.remise_en_vigueur_type === "report_echeance" && {
          report_date: calculateNewEcheance(
            dayjs(lastSuspension(souscription)?.meta_data?.date_avenant).add(
              1,
              "day"
            ),
            dayjs(values.date_avenant).add(1, "day"),
            dayjs(souscription?.conditions_particulieres?.date_echeance)
          ),
        }),
        ...(values.remise_en_vigueur_type === "ristourne" && {
          prime_nette:
            primeNetteGarantiesFacultatives + Number(souscription?.prime_nette),
        }),
      };
      avenantsService
        .createAvenantRemiseEnVigueur(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Suspension effectuée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(
        souscription_uuid,
        null,
        true
      );
      let primeNetteGarantiesFacultatives = response.data.souscription_garanties
        .filter((g) => g.company_has_liste_garanties.is_required !== 1)
        .map((g) =>
          g.souscription_garanties_risques.reduce((sum, r) => sum + r.value, 0)
        )
        .reduce((sum, g) => sum + g, 0);
      setPrimeNetteGarantiesFacultatives(primeNetteGarantiesFacultatives);
      setSouscription(response.data);
    } catch (error) {}
  };

  const calculateRistourne = (startDate, endDate) => {
    const suspensionDays = dayjs(endDate).diff(startDate, "days");
    let primeBase =
      Number(souscription?.prime_nette) + primeNetteGarantiesFacultatives;
    const coef = suspensionDays / 365;
    return Math.round(primeBase * coef * 0.75); // 3/4 de la prime RC pour la période
  };

  const calculateNewEcheance = (startDate, endDate, currentEcheance) => {
    const suspensionDays = dayjs(endDate).diff(startDate, "days");
    const reportDays = Math.floor(suspensionDays * 0.75); // 3/4 de la durée de suspension
    return dayjs(currentEcheance).add(reportDays, "days").format("YYYY-MM-DD");
  };

  useEffect(() => {
    if (souscription && open === true) {
      setFieldValue("souscription", souscription);
    }
  }, [souscription, open]);

  useEffect(() => {
    if (souscription_uuid && open === true) {
      fetchSouscription();
    }
  }, [souscription_uuid, open]);

  useEffect(() => {
    if (open === true) {
      if (values.date_avenant) {
        if (values.remise_en_vigueur_type === "ristourne") {
          setFieldValue(
            "amount",
            calculateRistourne(
              dayjs(lastSuspension(souscription)?.meta_data?.date_avenant)
                .add(1, "day")
                .format("YYYY-MM-DD"),
              dayjs(values.date_avenant).add(1, "day").format("YYYY-MM-DD")
            )
          );
        } else if (values.remise_en_vigueur_type === "report_echeance") {
          setFieldValue(
            "report_date",
            calculateNewEcheance(
              dayjs(lastSuspension(souscription)?.date_avenant)
                .add(1, "day")
                .format("YYYY-MM-DD"),
              dayjs(values.date_avenant).add(1, "day").format("YYYY-MM-DD"),
              dayjs(souscription?.conditions_particulieres?.date_echeance)
            )
          );
        }
      } else {
        setFieldValue("remise_en_vigueur_type", "");
        setFieldValue("amount", 0);
        setFieldValue("report_date", "");
      }
    }
  }, [values.remise_en_vigueur_type, values.date_avenant, open]);

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean" ? setOpen(true) : setOpen("retrait-garantie");
      }}
      open={typeof open === "string" ? open === "retrait-garantie" : open}
      closeIcon
      onUnmount={() => {
        if (open !== true) {
          handleReset();
        }
      }}
      size="large"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de remise en vigueur</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui tiny form">
            {Number(
              souscription?.conditions_particulieres
                ?.pool_directives_tarifaires_has_insurances_durations
                ?.insurances_durations?.months_number
            ) >= 12 && lastSuspension(souscription) ? (
              <Grid divided>
                <Grid.Row>
                  <Grid.Column width={6}>
                    {souscription && (
                      <>
                        <Segment raised>
                          <Header as="h5">
                            Informations de la souscription
                          </Header>
                          <List>
                            <List.Item>
                              <List.Icon name="calendar alternate" />
                              <List.Content>
                                <List.Header>Date de prise d'effet</List.Header>
                                <List.Description>
                                  {dayjs(
                                    souscription?.conditions_particulieres
                                      ?.date_effet
                                  ).format("DD/MM/YYYY")}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                            <List.Item>
                              <List.Icon name="calendar alternate" />
                              <List.Content>
                                <List.Header>Date d'expiration</List.Header>
                                <List.Description>
                                  {dayjs(
                                    souscription?.conditions_particulieres
                                      ?.date_echeance
                                  )
                                    .add(1, "minutes")
                                    .subtract(1, "day")
                                    .format("DD/MM/YYYY")}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                            <List.Item>
                              <List.Icon name="calendar alternate" />
                              <List.Content>
                                <List.Header>Durée du contrat</List.Header>
                                <List.Description>
                                  {dayjs(
                                    souscription?.conditions_particulieres
                                      ?.date_echeance
                                  )
                                    .add(1, "day")
                                    .diff(
                                      dayjs(
                                        souscription?.conditions_particulieres
                                          ?.date_effet
                                      ),
                                      "days"
                                    )}{" "}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          </List>
                        </Segment>
                        <Segment raised>
                          <Form.Field required error={!!errors.date_avenant}>
                            <label>Date de remise en vigueur</label>
                            <Input
                              type="date"
                              value={values.date_avenant}
                              onChange={handleChange("date_avenant")}
                              onBlur={handleBlur("date_avenant")}
                              min={dayjs(
                                lastSuspension(souscription)?.meta_data
                                  ?.date_avenant
                              )
                                .add(2, "day")
                                .format("YYYY-MM-DD")}
                            />
                            <small className="field-error">
                              {errors.date_avenant}
                            </small>
                            <Message>
                              <Message.Content>
                                <p>
                                  La mise en vigueur de l'avenant est fixée au
                                  lendemain de la date de l'avenant.
                                </p>
                              </Message.Content>
                            </Message>
                          </Form.Field>
                          <Divider />
                        </Segment>
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <>
                      {values.date_avenant &&
                        dayjs(values.date_avenant).isValid() && (
                          <Segment raised>
                            <Header as="h5">
                              Informations de la suspension
                            </Header>
                            <List horizontal>
                              <List.Item>
                                <List.Icon name="calendar alternate" />
                                <List.Content>
                                  <List.Header>Date de suspension</List.Header>
                                  <List.Description>
                                    {dayjs(
                                      lastSuspension(souscription)?.meta_data
                                        ?.date_avenant
                                    )
                                      .add(1, "d")
                                      .format("DD/MM/YYYY")}
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                              <List.Item>
                                <List.Icon name="calendar alternate" />
                                <List.Content>
                                  <List.Header>
                                    Nombre de jours écoulés
                                  </List.Header>
                                  <List.Description>
                                    {dayjs(
                                      dayjs(values.date_avenant).add(1, "day")
                                    ).diff(
                                      dayjs(
                                        lastSuspension(souscription)?.meta_data
                                          ?.date_avenant
                                      ).add(1, "day"),
                                      "days"
                                    )}{" "}
                                    {" jours "}, soit{" "}
                                    {dayjs(
                                      dayjs(values.date_avenant).add(1, "day")
                                    ).diff(
                                      dayjs(
                                        lastSuspension(souscription)?.meta_data
                                          ?.date_avenant
                                      ).add(1, "day"),
                                      "weeks"
                                    )}{" "}
                                    semaines
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                              {/*  <List.Item>
                                <List.Icon name="calendar alternate" />
                                <List.Content>
                                  <List.Header>
                                    Nombre de jours restant
                                  </List.Header>
                                  <List.Description>
                                    {dayjs(
                                      dayjs(
                                        souscription?.conditions_particulieres
                                          ?.date_echeance
                                      )
                                    ).diff(
                                      dayjs(values.date_avenant).add(1, "day"),
                                      "days"
                                    )}{" "}
                                    {" jours "} soit{" "}
                                    {dayjs(
                                      dayjs(
                                        souscription?.conditions_particulieres
                                          ?.date_echeance
                                      )
                                    ).diff(
                                      dayjs(values.date_avenant).add(1, "day"),
                                      "weeks"
                                    )}{" "}
                                    semaines
                                  </List.Description>
                                </List.Content>
                              </List.Item>*/}
                            </List>
                          </Segment>
                        )}
                      {!values.date_avenant && (
                        <Segment basic>
                          <Message>
                            <Message.Header>Message</Message.Header>
                            <Message.Content>
                              Veuillez sélectionner des dates valides pour la
                              période de suspension avant de continuer.
                            </Message.Content>
                          </Message>
                        </Segment>
                      )}
                      {values.date_avenant && (
                        <>
                          {dayjs(values.date_avenant)
                            .add(1, "day")
                            .diff(
                              dayjs(
                                lastSuspension(souscription)?.meta_data
                                  ?.date_avenant
                              ).add(1, "day"),
                              "weeks"
                            ) < 4 && (
                            <Segment basic>
                              <Message>
                                <Message.Header>Infos</Message.Header>
                                <Message.Content>
                                  La période de suspension [
                                  {dayjs(values.date_avenant)
                                    .add(1, "day")
                                    .diff(
                                      dayjs(
                                        lastSuspension(souscription)?.meta_data
                                          ?.date_avenant
                                      ).add(1, "day"),
                                      "months"
                                    )}{" "}
                                  mois ] est inférieure à 4 semaines (28 jours).
                                  L'assuré ne peut donc beneficier d'une
                                  ristourne ou d'un report d'échezance.
                                </Message.Content>
                              </Message>
                            </Segment>
                          )}

                          {dayjs(values.date_avenant)
                            .add(1, "day")
                            .diff(
                              dayjs(
                                lastSuspension(souscription)?.meta_data
                                  ?.date_avenant
                              ).add(1, "day"),
                              "months"
                            ) > 9 && (
                            <Segment basic>
                              <Message>
                                <Message.Header>Infos</Message.Header>
                                <Message.Content>
                                  La période de suspension [
                                  {dayjs(values.date_avenant)
                                    .add(1, "day")
                                    .diff(
                                      dayjs(
                                        lastSuspension(souscription)?.meta_data
                                          ?.date_avenant
                                      ).add(1, "day"),
                                      "months"
                                    )}{" "}
                                  mois ] est supérieure à 9 mois. L'assuré ne
                                  peut donc bénéficier d'une ristourne ou d'un
                                  report d'échéance. De plus, le contrat sera
                                  resillié et la prime sera acquise à
                                  l'assureur.
                                </Message.Content>
                              </Message>
                            </Segment>
                          )}

                          {dayjs(values.date_avenant)
                            .add(1, "day")
                            .diff(
                              dayjs(
                                lastSuspension(souscription)?.meta_data
                                  ?.date_avenant
                              ).add(1, "day"),
                              "weeks"
                            ) >= 4 &&
                            dayjs(values.date_avenant)
                              .add(1, "day")
                              .diff(
                                dayjs(
                                  lastSuspension(souscription)?.meta_data
                                    ?.date_avenant
                                ).add(1, "day"),
                                "months"
                              ) <= 9 &&
                            dayjs(
                              souscription?.conditions_particulieres
                                ?.date_echeance
                            ).diff(
                              dayjs(values.date_avenant).add(1, "day"),
                              "months"
                            ) >= 3 && (
                              <>
                                <Segment raised>
                                  <Header dividing as="h5">
                                    Que souhaite l'assuré pour la suspension?
                                  </Header>
                                  <FormField>
                                    <Checkbox
                                      toggle
                                      label="Bénéficier d'une ristourne"
                                      checked={
                                        values.remise_en_vigueur_type ===
                                        "ristourne"
                                      }
                                      onChange={() => {
                                        setFieldValue(
                                          "remise_en_vigueur_type",
                                          "ristourne"
                                        );
                                      }}
                                    />
                                  </FormField>
                                  <FormField>
                                    <Checkbox
                                      toggle
                                      label="Report de l'échéance"
                                      checked={
                                        values.remise_en_vigueur_type ===
                                        "report_echeance"
                                      }
                                      onChange={() => {
                                        setFieldValue(
                                          "remise_en_vigueur_type",
                                          "report_echeance"
                                        );
                                      }}
                                    />
                                  </FormField>
                                </Segment>
                                {values.remise_en_vigueur_type && (
                                  <Segment raised>
                                    {values.remise_en_vigueur_type ===
                                      "ristourne" && (
                                      <>
                                        <div>
                                          <Header dividing as="h5">
                                            Prime nette RC
                                          </Header>
                                          <div className="text-right">
                                            <span className="text-muted font-extrabold ">
                                              {numeral(
                                                Number(
                                                  souscription?.prime_nette
                                                )
                                              ).format("")}{" "}
                                              FCFA
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <Header dividing as="h5">
                                            Prime Autre garanties
                                          </Header>
                                          <div className="text-right">
                                            <span className="text-muted font-extrabold">
                                              {numeral(
                                                Number(
                                                  primeNetteGarantiesFacultatives
                                                )
                                              ).format("")}{" "}
                                              FCFA
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <Header dividing as="h5">
                                            Prime RC + Prime Autre garanties
                                          </Header>
                                          <div className="text-right">
                                            <span className="text-muted font-extrabold">
                                              {numeral(
                                                Number(
                                                  primeNetteGarantiesFacultatives +
                                                    Number(
                                                      souscription?.prime_nette
                                                    )
                                                )
                                              ).format("")}{" "}
                                              FCFA
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <Header dividing as="h5">
                                            Montant de la ristourne (3/4 de la
                                            prime nette RC)
                                          </Header>
                                          <div className="text-right">
                                            <span className="text-muted font-extrabold text-xl ">
                                              {numeral(values.amount).format(
                                                ""
                                              )}{" "}
                                              FCFA
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {values.remise_en_vigueur_type ===
                                      "report_echeance" && (
                                      <>
                                        <Header dividing as="h5">
                                          Date de report de l'échéance (3/4 de
                                          la durée de suspension)
                                        </Header>
                                        <Input
                                          type="date"
                                          readOnly
                                          className="w-[200px]"
                                          value={calculateNewEcheance(
                                            dayjs(
                                              lastSuspension(souscription)
                                                ?.meta_data?.date_avenant
                                            ).add(1, "day"),
                                            dayjs(values.date_avenant).add(
                                              1,
                                              "day"
                                            ),
                                            dayjs(
                                              souscription
                                                ?.conditions_particulieres
                                                ?.date_echeance
                                            )
                                          )}
                                          onChange={handleChange("report_date")}
                                          onBlur={handleBlur("report_date")}
                                        />
                                      </>
                                    )}
                                  </Segment>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : (
              <Segment basic>
                <Message>
                  <Message.Header>Message</Message.Header>
                  <Message.Content>
                    {!lastSuspension(souscription) ? (
                      <p>
                        Aucune suspension en cours. Veuillez choisir une autre
                        souscription.
                      </p>
                    ) : (
                      <p>
                        Cette souscription n'est pas annuelle. Veuillez choisir
                        une autre souscription.
                      </p>
                    )}
                  </Message.Content>
                </Message>
              </Segment>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={isSubmitting || !values.date_avenant}
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default AvenantRemiseEnVigueurModal;
