import { Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import numeral from "numeral";
import React from "react";

function ConditionsParticulieresInfosVehicule({ souscription, styles }) {
  return (
    <View>
      <View
        style={{
          padding: 4,
          backgroundColor: "#dce1e6",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Informations Vehicule & tarification
        </Text>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Immatriculation</Text>
          </View>
          <View>
            <Text>
              {
                souscription?.souscription_identite_vehicule
                  ?.immatriculation_vehicule
              }
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Marque</Text>
          </View>
          <View>
            <Text>
              {souscription?.souscription_identite_vehicule?.marque_vehicule}
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>N° Série</Text>
          </View>
          <View>
            <Text>
              {souscription?.souscription_identite_vehicule?.numero_chassis}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Type</Text>
          </View>
          <View>
            <Text>
              {
                souscription?.souscription_identite_vehicule
                  ?.type_vehicule
              }
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Genre</Text>
          </View>
          <View>
            <Text>
              {
                souscription?.souscription_identite_vehicule?.genre_vehicule
              }
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Mise en circulation</Text>
          </View>
          <View>
            <Text>
              {dayjs(
                souscription?.souscription_identite_vehicule
                  ?.date_premiere_mise_en_circulation
              ).format("DD/MM/YYYY")}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Places assises</Text>
          </View>
          <View>
            <Text>
              {souscription?.souscription_identite_vehicule?.nombre_places}
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Zone de circ.</Text>
          </View>
          <View>
            <Text>
              {
                souscription?.souscription_identite_vehicule?.zones_circulation
                  ?.libelle
              }
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Source Energie</Text>
          </View>
          <View>
            <Text>
              {
                souscription?.souscription_identite_vehicule?.sources_energie
                  ?.libelle
              }
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Valeur à neuf</Text>
          </View>
          <View>
            <Text>
              {numeral(
                souscription?.souscription_identite_vehicule?.valeur_a_neuf
              ).format()}
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Valeur Vénale</Text>
          </View>
          <View>
            <Text>
              {numeral(
                souscription?.souscription_identite_vehicule?.valeur_venale
              ).format()}
            </Text>
          </View>
        </View>
        <View style={[styles.sectionItem, { paddingRight: 10 }]}>
          <View>
            <Text style={{ fontWeight: "bold" }}>Puissance fiscale</Text>
          </View>
          <View>
            <Text>
              {souscription?.souscription_identite_vehicule?.puissance_fiscale}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default ConditionsParticulieresInfosVehicule;
