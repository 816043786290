import React, { useCallback, useEffect, useState } from "react";
import Page from "../../components/Page";
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import Breadcrumber from "../../components/Breadcrumber";
import GroupPermissionEditModal from "../../components/modalForms/GroupPermissionEditModal";
import * as groupPermissionsService from "../../services/configuration_permissions/groupsPermissionsService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
function GroupsPermissionsList() {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });
  const [search, setSearch] = useState("");
  const [groupsPermissions, setGroupsPermissions] = useState([]);
  const [openGroupPermissionEditModal, setOpenGroupPermissionEditModal] =
    useState(false);
  const [selectedGroupPermissionId, setSelectedGroupPermissionId] =
    useState(null);

  const fetchGroupPermissions = useCallback(async (page = 1) => {
    try {
      const response = await groupPermissionsService.fetchAll(page);
      setGroupsPermissions(response.data?.groupPermissions || []);
      setPagination({
        currentPage: response.data?.currentPage,
        totalPages: response.data?.totalPages,
      });
    } catch (error) {
      toast.error(error.message);
    }
  }, []);

  const handleDeleteGroupPermission = async (id) => {
    try {
      Swal.fire({
        title: "Voulez-vous vraiment supprimer ce groupe de permissions ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await groupPermissionsService.remove(id);
            fetchGroupPermissions();
            toast.success("Groupe de permissions supprimé avec succès");
          } catch (error) {
            toast.error(error.message || "Une erreur est survenue");
          }
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchGroupPermissions();
  }, [fetchGroupPermissions]);

  return (
    <Page title="Liste des groupes de permissions">
      <GridRow className="Compagnies">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumber
              breadcrumbs={[
                {
                  label: "Configuration des permissions",
                  link: "/pool/configuration-permissions/groupes-permissions",
                },
                {
                  label: "Liste des groupes de permissions",
                  link: "/pool/configuration-permissions/groupes-permissions",
                },
              ]}
            />
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des groupes de permissions</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => {
                setOpenGroupPermissionEditModal(true);
                setSelectedGroupPermissionId(null);
              }}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell collapsing>#</Table.HeaderCell>
                  <Table.HeaderCell>Nom</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right" collapsing>
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {groupsPermissions?.map((groupPermission, index) => {
                  return (
                    <Table.Row key={`${groupPermission.idgroup_permissions}`}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{groupPermission.name}</Table.Cell>
                      <Table.Cell>{groupPermission.type}</Table.Cell>
                      <Table.Cell>{groupPermission.description}</Table.Cell>

                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                setOpenGroupPermissionEditModal(true);
                                setSelectedGroupPermissionId(
                                  groupPermission.idgroup_permissions
                                );
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Supprimer"
                              onClick={() => {
                                handleDeleteGroupPermission(
                                  groupPermission.idgroup_permissions
                                );
                              }}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                {pagination.totalPages > 1 && (
                  <Table.Row>
                    <Table.HeaderCell colSpan="8" textAlign="right">
                      <Pagination
                        activePage={pagination.currentPage}
                        totalPages={pagination.totalPages}
                        onPageChange={(e, { activePage }) => {
                          fetchGroupPermissions(activePage);
                        }}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                )}
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>

      <GroupPermissionEditModal
        open={openGroupPermissionEditModal}
        setOpen={setOpenGroupPermissionEditModal}
        selectedGroupPermissionId={selectedGroupPermissionId}
        callback={() => {
          fetchGroupPermissions();
          setOpenGroupPermissionEditModal(false);
          setSelectedGroupPermissionId(null);
        }}
      />
    </Page>
  );
}

export default GroupsPermissionsList;
