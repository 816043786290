import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "dayjs/locale/fr";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import numeral from "numeral";
import convertToWord from "../../../utils/numberToWords";
import { findOne } from "../../../services/gestion_production/policesService";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
dayjs.locale("fr");

const priceArray = [
  {
    label: "Prime nette",
    value: 0,
  },
  {
    label: "Montant Accessoires",
    value: 5000,
  },
  {
    label: "Taxe d'enregistrement",
    value: 750,
  },
  {
    label: "Frais de gestion",
    value: 0,
  },
  {
    label: "Carte rose",
    value: 1500,
  },
  {
    label: "Droit de timbre Automobile",
    value: 0,
  },
];

function ChangementNom({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PreviewTemplateLayout souscription={souscription} />
          <PreviewContentWrapper souscription={souscription}>
            <View style={styles.avenantHeaderSection}>
              <Text style={styles.avenantHeaderTitle}>
                Avenant de changement de nom
              </Text>
            </View>

            <View
              style={[
                styles.souscripteurSection,
                {
                  fontWeight: "bold",
                  borderColor: "#CCC",
                  borderWidth: 1,
                  marginTop: 20,
                  marginBottom: 10,
                  fontSize: 10,
                },
              ]}
            >
              <View style={[styles.souscripteurItem]}>
                <View style={styles.souscripteurItemValue}>
                  <Text>Assuré: </Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.nom_assure
                    }
                  </Text>
                </View>
                <View style={styles.souscripteurItemValue}>
                  <Text>Adresse :</Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.adresse_assure
                    }
                  </Text>
                </View>
                <View style={styles.souscripteurItemValue}>
                  <Text>Tél: </Text>
                  <Text>
                    {
                      souscriptionWithAvenant?.souscripteur_assure?.assures
                        ?.phone_assure
                    }
                  </Text>
                </View>
              </View>
              <View style={styles.souscripteurItem}>
                <View style={styles.souscripteurItemValue}>
                  <Text>Effet: </Text>
                  <Text>
                    {dayjs(avenant?.date_avenant)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .add(1, "day")
                      .format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={styles.souscripteurItemValue}>
                  <Text>Expiration: </Text>
                  <Text>
                    {dayjs(
                      souscriptionWithAvenant?.conditions_particulieres
                        ?.date_echeance
                    )
                      .add(1, "minute")
                      .subtract(1, "day")
                      .format("DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                padding: 10,
                textAlign: "center",
                fontSize: 12,
              }}
            >
              <Text style={{ textAlign: "center" }}>
                (Concerne la police Automobile n°{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {souscriptionWithAvenant?.souscription_polices?.numero_police}
                </Text>
                )
              </Text>
            </View>
            <View
              style={{
                padding: 10,
                textAlign: "center",
                fontSize: 10,
              }}
            >
              <Text style={{ textAlign: "justify" }}>
                <Text>
                  {"      "}A la demande de l'assuré et d'un commun accord entre
                  les parties, il est convenu qu'à compter du{" "}
                </Text>
                <Text style={{ fontWeight: "extrabold" }}>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD MMMM YYYY")}
                </Text>
                , le véhicule de marque{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.marque_vehicule
                  }
                </Text>
                , Genre,{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.genre_vehicule
                  }
                </Text>
                , immatriculé{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.immatriculation_vehicule
                  }
                </Text>
                , assuré précédemment au nom de{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {
                    souscriptionWithAvenant?.souscripteur_assure?.assures
                      ?.nom_assure
                  }
                </Text>
                , est désormais assuré sous celui-ci mentionné :{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {avenant?.meta_data?.souscription_assures?.nom_assure}
                </Text>
              </Text>
            </View>
            <View
              style={{
                marginBottom: 5,
                padding: 10,
                fontSize: 10,
                fontWeight: "extrabold",
              }}
            >
              <Text style={{ fontWeight: "extrabold" }}>
                Il n'est rien changé d'autre aux conditions tant générales que
                paticulières de la police.
              </Text>
            </View>
            <View style={{ marginTop: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                }}
              >
                Prime
              </Text>
              <View style={{ marginTop: 5 }}>
                <Text>
                  De ce qui précède, le souscripteur s'engage à payer au
                  comptant à la signature du présent avenant , la somme de{" "}
                  {numeral(
                    priceArray.reduce((acc, price) => acc + price.value, 0)
                  ).format()}{" "}
                  FCFA décomposée comme suit :
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                borderTopWidth: 1,
                borderColor: "#000",
                paddingTop: 3,
              }}
            >
              {priceArray.map((price, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: 2,
                  }}
                  key={index}
                >
                  <Text style={{ width: "70%" }}>{price.label}</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(price.value).format()} FCFA
                  </Text>
                </View>
              ))}

              <View
                style={{
                  flexDirection: "row",
                  borderTop: 1,
                  marginTop: 5,
                  paddingTop: 5,
                  fontSize: 12,
                  borderBottomWidth: 1,
                  borderBottomColor: "#000",
                  paddingBottom: 5,
                }}
              >
                <Text style={{ width: "70%", fontWeight: "bold" }}>
                  Total à payer
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {numeral(
                    priceArray.reduce((acc, price) => acc + price.value, 0)
                  ).format()}{" "}
                  FCFA
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10, textAlign: "center", width: "100%" }}>
              <Text style={{ fontWeight: "bold", textTransform: "center" }}>
                Arrêté la présente facture à la somme de{" "}
                {convertToWord(
                  priceArray.reduce((acc, price) => acc + price.value, 0)
                )}{" "}
                FCFA
              </Text>
            </View>
            <View
              style={{
                textAlign: "right",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              <Text
                style={{ fontWeight: "extrabold", textDecoration: "underline" }}
              >
                Fait à ______________________, le{" "}
                <Text style={{ fontWeight: "extrabold" }}>
                  {dayjs(avenant?.date_effet).format("DD MMMM YYYY")}
                </Text>
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 14,
                marginTop: 50,
              }}
            >
              <Text>(Pour l'assuré)</Text>
              <Text>(Pour la compagnie)</Text>
            </View>
          </PreviewContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ChangementNom;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 50,
    fontSize: 10,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 10,
  },
  avenantHeaderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
    fontSize: 10,
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 10,
  },
});
