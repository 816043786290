import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "semantic-ui-react";

import * as civilitiesService from "../../../../services/civilitiesService";
import * as piecesOfficiellesService from "../../../../services/piecesOfficiellesService";
import * as jobsService from "../../../../services/jobsService";

function AssurePane({ formik }) {
  const [civilities, setCivilities] = useState([]);
  const [piecesOfficielles, setPiecesOfficielles] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { values, errors, handleBlur, handleChange } = formik;

  const { assure } = values;

  const fetchCivilities = async () => {
    try {
      const response = await civilitiesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data?.civilities)) {
        setCivilities(response.data.civilities);
      }
    } catch (error) {}
  };
  const fetchPiecesOfficielles = async () => {
    try {
      const response = await piecesOfficiellesService.fetchAll();
      if (
        response.status === 200 &&
        Array.isArray(response.data?.pieces_officielles)
      ) {
        setPiecesOfficielles(response.data.pieces_officielles);
      }
    } catch (error) {}
  };

  const fetchJobs = async () => {
    try {
      const response = await jobsService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data?.jobs)) {
        setJobs(response.data.jobs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCivilities();
    fetchPiecesOfficielles();
    fetchJobs();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Field width={6} error={"assure.nom_assure" in errors} required>
          <label>Noms & Prénoms/Dénomination</label>
          <Input
            value={assure.nom_assure}
            onChange={handleChange("assure.nom_assure")}
            onBlur={handleBlur("assure.nom_assure")}
          />
        </Form.Field>
        <Form.Field error={"assure.phone_assure" in errors} required width={6}>
          <label>Téléphone</label>
          <Input
            value={assure.phone_assure}
            onChange={handleChange("assure.phone_assure")}
            onBlur={handleBlur("assure.phone_assure")}
          />
        </Form.Field>
        <Form.Field error={"assure.email_assure" in errors} width={6}>
          <label>Email</label>
          <Input
            value={assure.email_assure}
            onChange={handleChange("assure.email_assure")}
            onBlur={handleBlur("assure.email_assure")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Field error={"assure.idjobs" in errors} width={6}>
          <label>Profession</label>
          <Select
            search
            options={jobs?.map((job) => ({
              key: job.idjobs,
              text: job.libelle,
              value: job.idjobs,
            }))}
            value={assure.idjobs}
            onChange={handleChange("assure.idjobs")}
            onBlur={handleBlur("assure.idjobs")}
          />
        </Form.Field>
        <Form.Field error={"assure.address_assure" in errors}>
          <label>Adresse</label>
          <Input
            value={assure.address_assure}
            onChange={handleChange("assure.address_assure")}
            onBlur={handleBlur("assure.address_assure")}
          />
        </Form.Field>
        <Form.Field error={"assure.nui" in errors}>
          <label>NUI</label>
          <Input
            value={assure.nui}
            onChange={handleChange("assure.nui")}
            onBlur={handleBlur("assure.nui")}
          />
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default AssurePane;
