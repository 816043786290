import React, { useState } from "react";
import { Form, Input } from "semantic-ui-react";
import * as compagniesPartnersService from "../../../../services/compagniesPartnersService";

function CalculCommission({ formik, taux_commision }) {
  const { values, errors, handleBlur, handleChange } = formik;

  return (
    <React.Fragment>
      <Form.Group widths={"4"}>
        <Form.Field>
          <label>
            Taux de commisionnement <small>(en %)</small>
          </label>
          <Input type="number" value={taux_commision} readOnly />
          <small className="field-error"></small>
        </Form.Field>
        <Form.Field error={"montant_commission" in errors}>
          <label>Montant de la commission</label>
          <Input
            type="number"
            readOnly
            value={values.montant_commission}
            onChange={handleChange("montant_commission")}
            onBlur={handleBlur("montant_commission")}
          />
          <small className="field-error">{errors.montant_commission}</small>
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default CalculCommission;
