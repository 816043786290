import { USERS_ROLE_ENUMS, USERS_ROLE_ENUMS_OPTIONS } from "../constants";

export const getTypeIntermediaireText = (key = "") => {
  let text = "Type inconnu";

  switch (key) {
    case "AGENT_GENERAL":
      text = "Agent Général";
      break;
    case "COURTIER":
      text = "Courtier";
      break;
    case "POOL_USER":
      text = "Pool";
      break;

    default:
      break;
  }

  return text;
};

export const getUserRoleReadableText = (key = "") => {
  let role = USERS_ROLE_ENUMS_OPTIONS.find((role) => role.key === key);

  return role?.text || "Type inconnu";
};

export const generateAvenantserieNumber = (numero_avenant = "") => {
  let initialCode = "0000000";
  return initialCode
    .slice(-(initialCode.length - numero_avenant?.trim()?.length))
    .concat(numero_avenant);
};

export const getReadebleOrderStatus = (key = "") => {
  let text = "Statut inconnu";
  switch (key) {
    case "ORDERED":
      text = "Commandé";
      break;
    case "CONFIRMED":
      text = "Confirmée";
      break;
    case "IN_PROGRESS":
      text = "En cours";
      break;
    case "CANCELED":
      text = "Annulée";
      break;
    default:
      break;
  }

  return text;
};

export const readableAttestationOrderPackagingType = (
  key = "",
  short = false
) => {
  let text = "Type inconnu";
  switch (key) {
    case "CARNET_CHEQUE":
      text = short ? "Carnet(s)" : "Carnet(s) de Cheque";
      break;
    case "EMBALLAGE_CONTINU":
      text = short ? "Attestion(s)" : "Emballage Continu";
      break;

    default:
      break;
  }

  return text;
};

export const getTypePermissionTemplateByUserRole = (userRole) => {
  try {
    switch (userRole) {
      case USERS_ROLE_ENUMS.ROOT_ADMIN:
      case USERS_ROLE_ENUMS.POOL_USER:
        return "pool";
      case USERS_ROLE_ENUMS.COMPANY_USER:
        return "company";
      case USERS_ROLE_ENUMS.COURTIER_USER:
        return "courtier";
      case USERS_ROLE_ENUMS.AGENT_GENERAL_USER:
        return "agent_general";
      case USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER:
        return "intermediaire_agency";
      case USERS_ROLE_ENUMS.COMPANY_AGENCY_USER:
        return "company_agency";
      default:
        return null;
    }
  } catch (error) {
    return null;
  }
};

export const cleanPermissions = (selectedPermissions, basePermissions) => {
  const cleanedPermissions = {};

  // Fonction récursive pour parcourir la structure
  const processModule = (selected, base) => {
    if (!selected || !base) return {};
    const cleaned = {};

    // Traiter les permissions directes
    if (base.permissions) {
      base.permissions.forEach((permission) => {
        if (selected[permission.key] !== undefined) {
          cleaned[permission.key] = selected[permission.key];
        }
      });
    }

    // Traiter les sous-modules
    if (base.submodules) {
      base.submodules.forEach((submodule) => {
        const cleanedSubmodule = processModule(
          selected[submodule.key],
          submodule
        );
        if (Object.keys(cleanedSubmodule).length > 0) {
          cleaned[submodule.key] = cleanedSubmodule;
        }
      });
    }

    return cleaned;
  };

  // Parcourir chaque module principal
  Object.keys(basePermissions).forEach((moduleKey) => {
    const cleanedModule = processModule(
      selectedPermissions[moduleKey],
      basePermissions[moduleKey]
    );
    if (Object.keys(cleanedModule).length > 0) {
      cleanedPermissions[moduleKey] = cleanedModule;
    }
  });

  return cleanedPermissions;
};

export const convertMmToPx = (mm, ppi = 96) => {
  // Convertir mm en pouces

  const inches = mm / 25.4;

  // Convertir pouces en pixels

  const pixels = inches * ppi;

  return Math.round(pixels);
};

export const convertPxToMm = (px, ppi = 96) => {
  // Convertir pixels en pouces
  const inches = px / ppi;

  // Convertir pouces en mm
  const mm = inches * 25.4;

  return Math.round(mm);
};
