import { api } from "../../axios/api";

export const getAvenants = async (params = {}) => {
  return api.get(`/gestion-production/avenants`, {
    params,
  });
};

export const generatePolice = async (uuid, data) => {
  return api.post(`/gestion-production/avenants/generate-police/${uuid}`, data);
};

export const createAvenantChangementImmatriculation = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-changement-immatriculation/${uuid}`,
    data
  );
};

export const createAvenantChangementDenomination = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-changement-denomination/${uuid}`,
    data
  );
};

export const createAvenantRetraitGarantie = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-retrait-garantie/${uuid}`,
    data
  );
};

export const deleteAvenant = async (uuid) => {
  return api.delete(`/gestion-production/avenants/${uuid}`);
};

export const createAvenantExtensionGaranties = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-extension-garanties/${uuid}`,
    data
  );
};

export const createAvenantResilliation = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-resilliation/${uuid}`,
    data
  );
};

export const getOneAvenant = async (uuid) => {
  return api.get(`/gestion-production/avenants/${uuid}`);
};

export const createAvenantDuplicataAttestestation = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-duplicata-attestestation/${uuid}`,
    data
  );
};

export const createAvenantSuspension = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-suspension/${uuid}`,
    data
  );
};

export const createAvenantRemiseEnVigueur = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-remise-en-vigueur/${uuid}`,
    data
  );
};

export const createAvenantChangementPriseEffet = async (uuid, data) => {
  return api.post(
    `/gestion-production/avenants/avenant-changement-prise-effet/${uuid}`,
    data
  );
};
