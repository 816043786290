import React from "react";
import { Button, Form, Icon, Input, Segment } from "semantic-ui-react";
import { LIENS_SOUSCRIPEUR_ASSURE } from "../../../../constants";
import dayjs from "dayjs";

function SouscripteurSection({
  formik,
  selectedSouscripteur,
  setOpenSelectSouscripteurModal,
  categoriesContrats,
}) {
  const { values, errors, setFieldValue, handleChange, handleBlur } = formik;
  return (
    <Segment>
      <Form.Group widths={"equal"}>
        <Form.Field required error={"idsouscripteurs" in errors}>
          <label>
            Souscripteur
            {selectedSouscripteur ? `(${selectedSouscripteur?.num})` : ""}
          </label>
          <Form.Input
            value={
              selectedSouscripteur
                ? selectedSouscripteur?.groupe_souscripteur === "ENTREPRISE"
                  ? selectedSouscripteur?.denomination
                  : `${selectedSouscripteur?.last_name} ${selectedSouscripteur?.first_name}`
                : ""
            }
            readOnly
            onClick={() => setOpenSelectSouscripteurModal(true)}
          />
        </Form.Field>
        <Form.Field required error={"lien_assure" in errors}>
          <label>Lien avec l'assuré </label>
          <Form.Select
            readOnly
            value={values.lien_assure}
            onChange={(e, data) => {
              if (isNaN(parseInt(values.idsouscripteurs))) {
                e.preventDefault();
              }
              setFieldValue("lien_assure", data.value, true);
            }}
            options={LIENS_SOUSCRIPEUR_ASSURE}
          />
          <small className="field-error">{errors.lien_assure}</small>
        </Form.Field>

        <Form.Field required error={"idcategories_contrats" in errors}>
          <label>Catégorie </label>
          <Form.Select
            readOnly
            value={values.idcategories_contrats}
            onChange={(e, data) =>
              setFieldValue("idcategories_contrats", data.value, true)
            }
            options={categoriesContrats.map((cat) => ({
              key: `${cat.idcategories_contrats}`,
              text: cat.libelle,
              value: cat.idcategories_contrats,
            }))}
          />
          <small className="field-error">{errors.idcategories_contrats}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Field error={"numero_police" in errors}>
          <label>Police</label>
          <Input
            readOnly={true}
            placeholder="Autogénéré"
            value={values.numero_police}
            onChange={handleChange("numero_police")}
            onBlur={handleBlur("numero_police")}
          />
          <small className="field-error">{errors.numero_police}</small>
        </Form.Field>
        <Form.Field error={"numero_proposition" in errors}>
          <label>Numéro de proposition</label>
          <Input
            readOnly={true}
            placeholder="Autogénéré"
            value={values.numero_proposition}
          />
          <small className="field-error">{errors.numero_proposition}</small>
        </Form.Field>
        <Form.Field error={"date_souscription" in errors}>
          <label>Date de souscription</label>
          <Input
            type="date"
            value={dayjs(values.date_souscription).format("YYYY-MM-DD")}
            onChange={handleChange("date_souscription")}
            onBlur={handleBlur("date_souscription")}
          />
          <small className="field-error">{errors.date_souscription}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"equal"} className="!mb-0">
        <Form.Field required>
          <label>Télécharger la carte grise</label>
          <Segment
            placeholder
            style={{ minHeight: "50px", maxHeight: "50px", cursor: "pointer" }}
            onClick={() => {
              document.getElementById("fichier_carte_grise").click();
            }}
          >
            <input
              id="fichier_carte_grise"
              className="!hidden"
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                setFieldValue("fichier_carte_grise", file);
              }}
              accept=".pdf,.jpg,.jpeg,.png"
            />
            {values.fichier_carte_grise ? (
              <div className="flex items-center justify-between gap-2 flex-row p-2">
                <p>Fichier sélectionné : {values.fichier_carte_grise.name}</p>
                <div className="flex items-center gap-2">
                  <Button
                    circular
                    icon
                    size="mini"
                    onClick={(e) => {
                      window.open(
                        URL.createObjectURL(values.fichier_carte_grise),
                        "_blank"
                      );
                      e.stopPropagation();
                    }}
                  >
                    <Icon name="eye" />
                  </Button>
                  <Button
                    circular
                    icon
                    size="mini"
                    className="!m-0"
                    onClick={(e) => {
                      setFieldValue("fichier_carte_grise", null);
                      e.stopPropagation();
                    }}
                  >
                    <Icon className="red" name="trash" />
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p className="flex items-center gap-2 flex-col p-2">
                  <Icon name="upload" />
                  Aucun fichier sélectionné
                </p>
              </div>
            )}
          </Segment>
        </Form.Field>
      </Form.Group>
    </Segment>
  );
}

export default SouscripteurSection;
