import React, { useEffect, useState } from "react";
import { Form, Input } from "semantic-ui-react";
import * as sourcesEnergiesService from "../../../../services/sourcesEnergiesService";
import * as zonesCirculationService from "../../../../services/zonesCirculationService";
import { initializeGaranties } from "../../../../utils/souscription_helper";

function IndentiteVehicule({
  formik,
  tauxBrisDeGlaces,
  companyDirectiveValues,
  avariesFacultatives,
}) {
  const [sourcesEnergies, setSourcesEnergies] = useState([]);
  const [zonesCirculation, setZonesCirculation] = useState([]);

  const { values, errors, handleBlur, handleChange, setFieldValue } = formik;
  const { identite_vehicule } = values;

  const fetchSourcesEnergies = async () => {
    const response = await sourcesEnergiesService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data?.sources)) {
      const _sourcesEnergies = response?.data.sources;
      _sourcesEnergies?.push({
        idsources_energie: "",
        libelle: "Choisir...",
        description: "",
      });
      setSourcesEnergies(_sourcesEnergies);
    }
  };

  const fetchZonesCirculation = async () => {
    const response = await zonesCirculationService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data.zones)) {
      setZonesCirculation(response?.data?.zones);
    }
  };

  const handleValeurNeufChange = (value) => {
    try {
      setFieldValue("identite_vehicule.valeur_a_neuf", value, true);
      initializeGaranties(formik);
    } catch (error) {}
  };

  const handleValeurVenaleChange = async (value) => {
    try {
      setFieldValue("identite_vehicule.valeur_venale", value, true);
      initializeGaranties(formik);
    } catch (error) {}
  };

  const handleDatePremiereMiseEnCirculationChange = async (value) => {
    try {
      setFieldValue(
        "identite_vehicule.date_premiere_mise_en_circulation",
        value,
        true
      );
      initializeGaranties(formik);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSourcesEnergies();
    fetchZonesCirculation();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Form.Field required error={"identite_vehicule.usage_vehicule" in errors}>
        <label>Usage du véhicule</label>
        <Form.Select
          value={identite_vehicule.usage_vehicule}
          readOnly
          options={[
            {
              key: `TRAPUBVOY`,
              text: "Transport Public Voyageur",
              value: "TRAPUBVOY",
            },
          ]}
        />
      </Form.Field>
      <Form.Group widths={"4"}>
        <Form.Field error={"identite_vehicule.idzones_circulation" in errors}>
          <label>Zone de circulation</label>
          <Form.Select
            search
            value={identite_vehicule.idzones_circulation}
            options={zonesCirculation.map((zone) => ({
              key: `${zone.idzones_circulation}`,
              text: zone.libelle,
              value: zone.idzones_circulation,
            }))}
            onChange={(e, data) =>
              setFieldValue(
                "identite_vehicule.idzones_circulation",
                data.value,
                true
              )
            }
          />
        </Form.Field>
        <Form.Field
          required
          error={"identite_vehicule.puissance_fiscale" in errors}
        >
          <label>Puissance fiscale</label>
          <Form.Input
            min={1}
            value={identite_vehicule.puissance_fiscale}
            onChange={handleChange("identite_vehicule.puissance_fiscale")}
            onBlur={handleBlur("identite_vehicule.puissance_fiscale")}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.nombre_places" in errors}
          required
        >
          <label>Nombre de places</label>
          <Input
            type="number"
            min={3}
            max={100}
            value={identite_vehicule.nombre_places}
            onChange={handleChange("identite_vehicule.nombre_places")}
            onBlur={handleBlur("identite_vehicule.nombre_places")}
          />
        </Form.Field>
        <Form.Field
          required
          error={"identite_vehicule.idsources_energie" in errors}
        >
          <label>Source d'énergie</label>
          <Form.Select
            value={identite_vehicule.idsources_energie || ""}
            onChange={(e, data) =>
              setFieldValue("identite_vehicule.idsources_energie", data.value)
            }
            options={sourcesEnergies.map((source) => ({
              key: `${source.idsources_energie}`,
              text: source.libelle,
              value: source.idsources_energie,
            }))}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field
          error={"identite_vehicule.marque_vehicule" in errors}
          required
        >
          <label>Marque</label>
          <Input
            type="text"
            value={identite_vehicule.marque_vehicule}
            onChange={handleChange("identite_vehicule.marque_vehicule")}
            onBlur={handleBlur("identite_vehicule.marque_vehicule")}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.type_vehicule" in errors}
          required
        >
          <label>Type du véhicule</label>
          <Input
            type="text"
            value={identite_vehicule.type_vehicule}
            onChange={handleChange("identite_vehicule.type_vehicule")}
            onBlur={handleBlur("identite_vehicule.type_vehicule")}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.genre_vehicule" in errors}
          required
        >
          <label>Genre du véhicule</label>
          <Input
            type="text"
            value={identite_vehicule.genre_vehicule}
            onChange={handleChange("identite_vehicule.genre_vehicule")}
            onBlur={handleBlur("identite_vehicule.genre_vehicule")}
          />
        </Form.Field>
        <Form.Field error={"identite_vehicule.carrosserie" in errors}>
          <label>Carrosserie</label>
          <Input
            type="text"
            value={identite_vehicule.carrosserie}
            onChange={handleChange("identite_vehicule.carrosserie")}
            onBlur={handleBlur("identite_vehicule.carrosserie")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field
          error={"identite_vehicule.valeur_a_neuf" in errors}
          required
        >
          <label>Valeur à neuf</label>
          <Input
            type="number"
            min={0}
            value={identite_vehicule.valeur_a_neuf}
            onChange={async (e, data) => {
              handleValeurNeufChange(data.value);
            }}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.valeur_venale" in errors}
          required
        >
          <label>Valeur vénale</label>
          <Input
            type="number"
            min={0}
            value={identite_vehicule.valeur_venale}
            onChange={async (e, data) => {
              handleValeurVenaleChange(data.value);
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            "identite_vehicule.date_premiere_mise_en_circulation" in errors
          }
          required
        >
          <label>Date de 1ère mise en circulation</label>
          <Input
            type="date"
            value={identite_vehicule.date_premiere_mise_en_circulation}
            onChange={async (e, data) => {
              handleDatePremiereMiseEnCirculationChange(data.value);
            }}
          />
        </Form.Field>
        <Form.Field
          required
          error={"identite_vehicule.immatriculation_vehicule" in errors}
        >
          <label>Immatriculation du véhicule</label>
          <Input
            type="text"
            value={identite_vehicule.immatriculation_vehicule}
            onChange={handleChange(
              "identite_vehicule.immatriculation_vehicule"
            )}
            onBlur={handleBlur("identite_vehicule.immatriculation_vehicule")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"4"}>
        <Form.Field
          error={
            "identite_vehicule.immatriculation_vehicule_precedente" in errors
          }
        >
          <label>Immatriculation précédente</label>
          <Input
            type="text"
            value={identite_vehicule.immatriculation_vehicule_precedente}
            onChange={handleChange(
              "identite_vehicule.immatriculation_vehicule_precedente"
            )}
            onBlur={handleBlur(
              "identite_vehicule.immatriculation_vehicule_precedente"
            )}
          />
        </Form.Field>
        <Form.Field
          required
          error={"identite_vehicule.numero_chassis" in errors}
        >
          <label>Numéro du châssis</label>
          <Input
            type="text"
            value={identite_vehicule.numero_chassis}
            onChange={handleChange("identite_vehicule.numero_chassis")}
            onBlur={handleBlur("identite_vehicule.numero_chassis")}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.numero_permis_conduire" in errors}
        >
          <label>Numéro de Permis du conducteur</label>
          <Input
            type="text"
            value={identite_vehicule.numero_permis_conduire}
            onChange={handleChange("identite_vehicule.numero_permis_conduire")}
            onBlur={handleBlur("identite_vehicule.numero_permis_conduire")}
          />
        </Form.Field>
        <Form.Field
          error={"identite_vehicule.date_delivrance_permis" in errors}
        >
          <label>Date du permis du conducteur</label>
          <Input
            type="date"
            value={identite_vehicule.date_delivrance_permis}
            onChange={handleChange("identite_vehicule.date_delivrance_permis")}
            onBlur={handleBlur("identite_vehicule.date_delivrance_permis")}
          />
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default IndentiteVehicule;
