import React from "react";
import { Modal } from "semantic-ui-react";
import ChangementImmatriculation from "./previews/ChangementImmatriculation";
import ChangementNom from "./previews/ChangementNom";
import ExtensionGarntiesPreview from "./previews/ExtensionGarntiesPreview";
import DuplicaPreview from "./previews/DuplicaPreview";
import ResilliationPreview from "./previews/ResilliationPreview";
import RetraitGarantiesPreview from "./previews/RetraitGarantiesPreview";
import SuspensionPreview from "./previews/SuspensionPreview";
import RemiseEnVigueurPreview from "./previews/RemiseEnVigueurPreview";
import ChangementPriseEffetPreview from "./previews/ChangementPriseEffetPreview";

function ImpressionModal({
  open,
  setOpen,
  souscription,
  avenant_uuid,
  onCloseCallBack = () => {},
}) {
  const typeAvenant = souscription?.avenants.find(
    (avenant) => avenant.uuid === avenant_uuid
  )?.type_avenant;
  const returnPreview = (souscription, avenant_uuid) => {
    switch (typeAvenant) {
      case "CHANGEMENT_IMMATRICULATION":
        return (
          <ChangementImmatriculation
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "CHANGEMENT_NOM":
        return (
          <ChangementNom
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "EXTENSION_GARANTIES":
        return (
          <ExtensionGarntiesPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "DUPLICATA_ATTESTATION":
        return (
          <DuplicaPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "RESILLIATION":
        return (
          <ResilliationPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "RETRAIT_GARANTIE":
        return (
          <RetraitGarantiesPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "SUSPENSION":
        return (
          <SuspensionPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "REMISE_EN_VIGUEUR":
        return (
          <RemiseEnVigueurPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      case "MODIFICATION_PRISE_EFFET":
        return (
          <ChangementPriseEffetPreview
            souscription={souscription}
            avenant_uuid={avenant_uuid}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="fullscreen"
      closeIcon
      onClose={() => {
        onCloseCallBack();
        setOpen(false);
      }}
    >
      <Modal.Header>Impression</Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        {returnPreview(souscription, avenant_uuid)}
      </Modal.Content>
    </Modal>
  );
}

export default ImpressionModal;
