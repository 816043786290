import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as jobsService from "../../services/jobsService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(2, "Le libellé doit contenir au moins 2 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function JobFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedJobId,
  handleClose = () => {},
}) {
  const fetchJob = useCallback(async () => {
    if (!selectedJobId) {
      return;
    }

    try {
      const response = await jobsService.fetchOne(selectedJobId);

      const jobData = response.data.job || response.data;

      if (jobData.libelle) {
        formik.setValues({
          libelle: jobData.libelle,
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de la profession: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedJobId]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedJobId;

        const serviceCall = isEditMode
          ? jobsService.update(selectedJobId, values)
          : jobsService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Profession modifiée avec succès"
            : "Profession ajoutée avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedJobId) {
      fetchJob();
    } else {
      formik.resetForm();
    }
  }, [selectedJobId, fetchJob]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedJobId ? "Modifier une profession" : "Ajouter une profession"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={(e, { value }) => {
                formik.setFieldValue("libelle", value);
              }}
              onBlur={formik.handleBlur("libelle")}
              error={formik.touched.libelle && formik.errors.libelle}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          type="button"
          content={selectedJobId ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            formik.handleSubmit();
          }}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default JobFormModal;
