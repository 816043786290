import numeral from "numeral";
import React from "react";

function SummaryAmountDisplay({ value = 0, emphasize = false }) {
  return (
    <div
      className={`w-full ${
        emphasize ? "bg-slate-300" : "bg-slate-100"
      } rounded-md p-2 text-right font-bold cursor-pointer hover:bg-slate-300`}
    >
      {numeral(value).format("0,0")}
    </div>
  );
}

export default SummaryAmountDisplay;
