import React, { Fragment, useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Image,
  Form,
  Input,
  Header,
  Icon,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as yup from "yup";
import * as authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import "../../styles/loginPage.css";
import { useRecoilState } from "recoil";
import { authState } from "../../atoms/authState";
import { getUserBaseRoute, saveUserFromStorage } from "../../utils/auth";

const validationSchema = yup.object().shape({
  email: yup.string().email("Email invalide").required("Email requis"),
  password: yup
    .string()
    .min(8, "Mot de passe trop court")
    .max(64, "Mot de passe trop long")
    .required("Mot de passe requis"),
});

const LoginPage = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      authService
        .login(values)
        .then(
          (response) => {
            let baseRoute = getUserBaseRoute(response.data);
            if (baseRoute === false) {
              return toast(`Type d'utilisateur non supporté!`, {
                type: "error",
                theme: "colored",
              });
            }
            saveUserFromStorage(response.data);
            setAuthValue(response.data);
            navigate(`/${baseRoute}`);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;
  return (
    <Fragment>
      <Helmet>
        <title>SIGAS - TPV | Connexion</title>
      </Helmet>
      <GridRow className="LoginPage" style={{ backgroundColor: "#FFFF" }}>
        <Grid style={{ height: "100%" }}>
          <Grid.Column className="left-box">
            <Header as={"h1"} className="!mb-1">
              SIGAS - TPV
            </Header>
            <Header as={"h2"} className="!text-white !mt-0">
              Portail de coassurance
            </Header>
          </Grid.Column>
          <Grid.Column className="right-box">
            <Grid.Row>
              <div className="text center">
                <Icon name="lock" color="blue" size="huge" circular />
              </div>
              <Header as={"h6"} className="text center" color={"grey"}>
                Connectez vous à votre espace d'administration.
              </Header>
              <Form>
                <Form.Field required error={"email" in errors}>
                  <label>Email</label>
                  <Input
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    type="text"
                  />
                  <small className="field-error">{errors.email}</small>
                </Form.Field>
                <Form.Field required error={"password" in errors}>
                  <label>Mot de passe</label>
                  <Input
                    value={values.password}
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    type={secureTextEntry ? "password" : "text"}
                    icon={
                      <Icon
                        name={secureTextEntry ? "eye" : "eye slash"}
                        link
                        onClick={() => setSecureTextEntry(!secureTextEntry)}
                      />
                    }
                  />
                  <small className="field-error">{errors.password}</small>
                </Form.Field>
                <Button
                  type="submit"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  color="blue"
                  fluid
                >
                  Se connecter
                </Button>
              </Form>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </GridRow>
    </Fragment>
  );
};

export default LoginPage;
