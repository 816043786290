import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Divider,
  Grid,
  GridRow,
  Label,
  List,
  Message,
  Segment,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  Progress,
  Table,
  Pagination,
  Input,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import * as requestsService from "../../../../services/gestion_attestations/agency/requestsService";

function RequestDetails() {
  const [request, setRequest] = useState(null);
  const [consommation, setConsommation] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();

  const fetchRequest = async () => {
    try {
      const { data } = await requestsService.fetchOne(uuid);
      setRequest(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation de la demande"
      );
    }
  };

  const fetchConsommation = async () => {
    try {
      setLoading(true);
      const { data } = await requestsService.consommationAttestation(uuid, {
        page: 1,
        limit: 10,
        search: search || "",
      });
      setConsommation(data?.consommation);
      setPagination(data?.pagination);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation de la consommation"
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPagination({ ...pagination, page: activePage });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    fetchConsommation();
  }, [pagination?.page, search]);

  useEffect(() => {
    fetchRequest();
    fetchConsommation();
  }, [uuid]);

  return (
    <Page title="Détails de la demande">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Agence</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to={`/agency/stocks/demandes`}>
                Demandes
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection active>détail de la demande</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de demande</List.Header>
                      <List.Description>
                        {request?.idattestations_distributions_requests
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité commandée</List.Header>
                      <List.Description>
                        {request &&
                          new Intl.NumberFormat().format(request?.quantity)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de demande</List.Header>
                      <List.Description>
                        {dayjs(request?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Statut</List.Header>
                      <List.Description>
                        {request?.attestations_distributions
                          ? "Livrée"
                          : "En attente"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de livraison</List.Header>
                      <List.Description>
                        {request?.attestations_distributions
                          ? dayjs(
                              request?.attestations_distributions?.created_at
                            ).format("DD/MM/YYYY HH:mm")
                          : "----"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de livraison</List.Header>
                      <List.Description>
                        {request?.attestations_distributions
                          ? request?.attestations_distributions?.idattestations_distributions
                              ?.toString()
                              .padStart(6, "0")
                          : "----"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>

          {request?.attestations_distributions ? (
            <>
              <Grid as={Segment}>
                <Grid.Column width={8}>
                  <Message style={{ height: "128px" }}>
                    <Message.Header>Note</Message.Header>
                    <Message.Content>
                      <p className="!mt-1">
                        {`La livraison pour cette demande a été enregistrée le ${dayjs(
                          request?.attestations_distributions?.created_at
                        ).format("DD/MM/YYYY HH:mm")}`}
                      </p>
                      <p>{`
                    La plage de la livraison est de ${
                      request?.attestations_distributions?.start_serial
                    }
                    - ${request?.attestations_distributions?.end_serial} , soit 
                    ${new Intl.NumberFormat().format(
                      parseInt(
                        request?.attestations_distributions?.end_serial
                      ) -
                        parseInt(
                          request?.attestations_distributions?.start_serial
                        ) +
                        1
                    )} attestation(s)                   
                    `}</p>
                    </Message.Content>
                  </Message>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Message>
                    <Message.Header>
                      Consommation des attestations
                    </Message.Header>
                    <Message.Content>
                      {request?.consommation &&
                        request?.attestations_distributions && (
                          <>
                            <p className="!mt-1">
                              {`${
                                request.consommation[0].count
                              } attestation(s) consommée(s) sur ${
                                parseInt(
                                  request.attestations_distributions.end_serial
                                ) -
                                parseInt(
                                  request.attestations_distributions
                                    .start_serial
                                ) +
                                1
                              }`}
                            </p>
                            <Progress
                              total={
                                parseInt(
                                  request.attestations_distributions.end_serial
                                ) -
                                parseInt(
                                  request.attestations_distributions
                                    .start_serial
                                ) +
                                1
                              }
                              value={request.consommation[0].count || 0}
                              progress="ratio"
                              indicating
                            />
                          </>
                        )}
                    </Message.Content>
                  </Message>
                </Grid.Column>
              </Grid>

              <Grid as={Segment} loading={loading}>
                <Grid.Column width={16}>
                  <div className="flex justify-between items-center">
                    <div></div>
                    <div>
                      <Input
                        className="search"
                        icon="search"
                        placeholder="Rechercher par numéro d'attestation"
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                  <Divider />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Numéro d'attestation
                        </Table.HeaderCell>
                        <Table.HeaderCell>Numéro de police</Table.HeaderCell>
                        <Table.HeaderCell>
                          Date de consommation
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {consommation?.map((item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.numero_attestation}</Table.Cell>
                          <Table.Cell>{item.numero_police}</Table.Cell>
                          <Table.Cell>
                            {dayjs(item?.created_at).format("DD-MM-YYYY HH:mm")}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                    {pagination?.totalPages > 1 && (
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell
                            colSpan="3"
                            style={{ textAlign: "right" }}
                          >
                            <Pagination
                              activePage={pagination?.page}
                              totalPages={pagination?.totalPages}
                              onPageChange={handlePaginationChange}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    )}
                  </Table>
                </Grid.Column>
              </Grid>
            </>
          ) : (
            <>
              <Message info>
                <Message.Header>Note</Message.Header>
                <p>
                  Aucune livraison pour cette demande pour l'instant. Vous
                  pouvez réessayer plus tard.
                </p>
              </Message>
            </>
          )}
        </>
      </GridRow>
    </Page>
  );
}

export default RequestDetails;
