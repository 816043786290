import React from "react";
import {
  List,
  ListContent,
  ListDescription,
  ListHeader,
  ListIcon,
  ListItem,
} from "semantic-ui-react";
import { authState } from "../atoms/authState";
import { useRecoilValue } from "recoil";
import { USERS_ROLE_ENUMS } from "../constants";

function SideBarFooter() {
  const authValue = useRecoilValue(authState);
  return [
    USERS_ROLE_ENUMS.COMPANY_USER,
    USERS_ROLE_ENUMS.COMPANY_AGENCY_USER,
    USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER,
    USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
    USERS_ROLE_ENUMS.COURTIER_USER,
  ].includes(authValue?.user?.user_role) ? (
    <div className="sidebar-footer">
      <List divided relaxed>
        <ListItem>
          <ListIcon name="building" size="large" verticalAlign="middle" />
          <ListContent>
            <ListHeader as="a">
              {USERS_ROLE_ENUMS.COMPANY_USER === authValue?.user?.user_role ||
              USERS_ROLE_ENUMS.COMPANY_AGENCY_USER ===
                authValue?.user?.user_role
                ? "Compagnie"
                : "Intermédiaire"}
            </ListHeader>
            <ListDescription as="a">
              {USERS_ROLE_ENUMS.COMPANY_USER === authValue?.user?.user_role ||
              USERS_ROLE_ENUMS.COMPANY_AGENCY_USER ===
                authValue?.user?.user_role
                ? authValue?.user?.compagnie?.name
                : authValue?.user?.intermediaire?.name}
            </ListDescription>
          </ListContent>
        </ListItem>
        {(USERS_ROLE_ENUMS.COMPANY_AGENCY_USER === authValue?.user?.user_role ||
          USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER ===
            authValue?.user?.user_role) && (
          <ListItem>
            <ListIcon name="home" size="large" verticalAlign="middle" />
            <ListContent>
              <ListHeader as="a">Agence</ListHeader>
              <ListDescription as="a">
                {USERS_ROLE_ENUMS.COMPANY_AGENCY_USER ===
                  authValue?.user?.user_role ||
                USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER ===
                  authValue?.user?.user_role
                  ? authValue?.user?.agency?.name
                  : authValue?.user?.intermediaire?.name}
              </ListDescription>
            </ListContent>
          </ListItem>
        )}
      </List>
    </div>
  ) : null;
}

export default SideBarFooter;
