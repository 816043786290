import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import * as souscriptionService from "../../services/souscriptionsService";
import { toast } from "react-toastify";
import ConditionsParticulieresHeader from "./previewSections/ConditionsParticulieresHeader";
import ConditionsParticulieresInfos from "./previewSections/ConditionsParticulieresInfos";
import ConditionsParticulieresSouscripteur from "./previewSections/ConditionsParticulieresSouscripteur";
import ConditionsParticulieresInfosVehicule from "./previewSections/ConditionsParticulieresInfosVehicule";
import ConditionsParticulieresGaranties from "./previewSections/ConditionsParticulieresGaranties";
import ConditionsParticulieresTarification from "./previewSections/ConditionsParticulieresTarification";
import dayjs from "dayjs";
import PreviewTemplateLayout from "./previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "./previewSections/partials/PreviewContentWrapper";

function ConditionsParticulieresPreviewModal({
  open = false,
  setOpen,
  souscriptionUuid = null,
  avenant_uuid = null,
}) {
  const [souscription, setSouscription] = useState(false);

  const fetchSouscription = async () => {
    try {
      const response = await souscriptionService.fetchOne(
        souscriptionUuid,
        avenant_uuid
      );

      if (response?.status === 200) {
        setSouscription(response?.data);
      }
    } catch (error) {
      toast.error("Une erreur est survenue!");
    }
  };
  useEffect(() => {
    if (open && souscriptionUuid) {
      fetchSouscription();
    }
    return () => {};
  }, [souscriptionUuid, open]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      closeIcon
      size="fullscreen"
    >
      <Modal.Header style={{ fontSize: "12px" }}>Preview</Modal.Header>
      <Modal.Content style={{ padding: "0px" }}>
        <Modal.Description>
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
              {souscription && (
                <PageView size="A4" style={[styles.page, { padding: 0 }]}>
                  <PreviewTemplateLayout souscription={souscription} />
                  <PreviewContentWrapper souscription={souscription}>
                    <ConditionsParticulieresHeader
                      souscription={souscription}
                    />
                    <ConditionsParticulieresInfos
                      souscription={souscription}
                      styles={styles}
                    />
                    <ConditionsParticulieresSouscripteur
                      souscription={souscription}
                      styles={styles}
                    />

                    <ConditionsParticulieresInfosVehicule
                      souscription={souscription}
                      styles={styles}
                    />

                    <ConditionsParticulieresGaranties
                      souscription={souscription}
                      styles={styles}
                    />
                    <ConditionsParticulieresTarification
                      souscription={souscription}
                      styles={styles}
                    />

                    <View style={{ marginTop: 10, fontWeight: "bold" }}>
                      <Text>
                        *Le souscripteur reconnaît avoir reçu une copie des
                        conditions générales qui font partie intégrante du
                        présent contrat.
                      </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "right",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Fait à {"                                      "}, le{" "}
                        {dayjs().format("DD/MM/YYYY")}
                      </Text>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          textTransform: "uppercase",
                          textDecoration: "underline",
                        }}
                      >
                        Le Souscripteur
                      </Text>
                      <View>
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: 10,
                            textTransform: "uppercase",
                            textDecoration: "underline",
                          }}
                        >
                          Pour la compagnie
                        </Text>
                      </View>
                    </View>
                  </PreviewContentWrapper>
                </PageView>
              )}
            </Document>
          </PDFViewer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ConditionsParticulieresPreviewModal;
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
    fontSize: 10,
    width: "100%",
    height: "100%",
  },
  section: {
    padding: 20,
  },
  sectionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
    fontSize: 8,
  },
});
