import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as agenciesServices from "../../services/companyAgenciesService";

const validationSchema = yup.object().shape({
  denomination: yup.string().required("Dénomination est requis"),
  phone_1: yup.string().min(8).max(16, "Téléphone invalide"),
  phone_2: yup.string().min(8).max(16, "Téléphone invalide"),
  address: yup.string().max(255),
  code: yup
    .string()
    .matches(/^[0-9]{4}$/, "Code invalide")
    .required("Code requis"),
  is_active: yup.number().min(0).max(1).default(1),
});

function CompanyAgencyFormModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
  selectedAgencyId = null,
  handleClose = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      phone_1: "",
      phone_2: "",
      address: "",
      code: "",
      is_active: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const action = selectedAgencyId
        ? agenciesServices.update(selectedAgencyId, values)
        : agenciesServices.create(values);

      action
        .then(
          (response) => {
            const message = selectedAgencyId
              ? "Agence modifiée avec succès."
              : "Agence ajoutée avec succès.";
            toast(message, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchAggency = async () => {
    try {
      const response = await agenciesServices.fetchOne(selectedAgencyId);
      if (response.status === 200) {
        setFieldValue("denomination", response.data.denomination || "");
        setFieldValue("phone_1", response.data.phone_1 || "");
        setFieldValue("phone_2", response.data.phone_2 || "");
        setFieldValue("address", response.data.address || "");
        setFieldValue("code", response.data.code || "");
        setFieldValue("is_active", response.data.is_active);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (open && selectedAgencyId) {
      fetchAggency();
    }
  }, [open, selectedAgencyId]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        handleClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
    >
      <Modal.Header>
        {selectedAgencyId ? "Editer une agence" : "Ajouter une agence"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errors.idcompagnies && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.idcompagnies}
            </div>
          )}
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Téléphone principal</label>
              <Input
                value={values.phone_1}
                onChange={handleChange("phone_1")}
                onBlur={handleBlur("phone_1")}
              />
              <small className="field-error">{errors.phone_1}</small>
            </Form.Field>
            <Form.Field>
              <label>Téléphone secondaire</label>
              <Input
                value={values.phone_2}
                onChange={handleChange("phone_2")}
                onBlur={handleBlur("phone_2")}
              />
              <small className="field-error">{errors.phone_2}</small>
            </Form.Field>
          </Form.Group>

          <Form.Field error={"address" in errors}>
            <label>Adresse</label>
            <Input
              value={values.address}
              onChange={handleChange("address")}
              onBlur={handleBlur("address")}
            />
            <small className="field-error">{errors.adress}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"code" in errors}>
              <label>Code</label>
              <Input
                value={values.code}
                onChange={handleChange("code")}
                onBlur={handleBlur("code")}
              />
              <small className="field-error">{errors.code}</small>
            </Form.Field>
            <Form.Field>
              <label>Statut</label>
              <Checkbox
                toggle
                checked={values.is_active === 1}
                onChange={(e, { checked }) =>
                  setFieldValue("is_active", checked ? 1 : 0)
                }
              />
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
            handleClose();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyAgencyFormModal;
