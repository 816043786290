import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
  Pagination,
} from "semantic-ui-react";
import CivilityFormModal from "../../components/modalForms/CivilityFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as civilitiesService from "../../services/civilitiesService";

function CivilitiesPage() {
  const [civilities, setCivilities] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCivilityId, setSelectedCivilityId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchCivilities = async () => {
    setIsLoading(true);
    try {
      const response = await civilitiesService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });

      if (response.status === 200) {
        const {
          civilities,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;

        setCivilities(civilities);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des civilités");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteCivility = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await civilitiesService.remove(id);
        if (response.status === 200) {
          toast.success("Civilité supprimée avec succès");
          fetchCivilities();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression de la civilité");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedCivilityId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchCivilities();
    return () => {};
  }, [pagination.currentPage, search]);

  return (
    <Page title="Civilités">
      <GridRow className="CivilitiesPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des civilités</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              onChange={handleSearchChange}
              value={search}
            >
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Libelle</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {civilities.map((civility, index) => {
                  return (
                    <Table.Row key={`${civility.idcivilities}`}>
                      <Table.Cell>
                        {(pagination.currentPage - 1) * pagination.limit +
                          index +
                          1}
                      </Table.Cell>
                      <Table.Cell>{civility.libelle}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() => {
                                const id = civility.idcivilities;
                                setSelectedCivilityId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                const id = civility.idcivilities;
                                setSelectedCivilityId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Supprimer"
                              onClick={() =>
                                handleDeleteCivility(civility.idcivilities)
                              }
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8" textAlign="right">
                    <Pagination
                      activePage={pagination.currentPage}
                      totalPages={pagination.totalPages}
                      onPageChange={handlePageChange}
                      disabled={isLoading}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>
      <CivilityFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchCivilities();
          handleCloseModal();
        }}
        selectedCivilityId={selectedCivilityId}
        handleClose={handleCloseModal}
      />
    </Page>
  );
}

export default CivilitiesPage;
