import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as categoriesContratsService from "../../services/categoriesContratsService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(1, "Le libellé doit contenir au moins 1 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
  description: yup
    .string()
    .max(500, "La description ne doit pas dépasser 500 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};

function CategorieContratFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedCategoryId = null,
  handleClose = () => {},
}) {
  const fetchCategory = useCallback(async () => {
    if (!selectedCategoryId) {
      return;
    }

    try {
      const response = await categoriesContratsService.fetchOne(
        selectedCategoryId
      );
      const categoryData = response.data;

      if (categoryData) {
        formik.setValues({
          libelle: categoryData.libelle || "",
          description: categoryData.description || "",
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de la catégorie: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedCategoryId]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedCategoryId;

        const serviceCall = isEditMode
          ? categoriesContratsService.update(selectedCategoryId, values)
          : categoriesContratsService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Catégorie de contrat modifiée avec succès"
            : "Catégorie de contrat ajoutée avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedCategoryId) {
      fetchCategory();
    } else {
      formik.resetForm();
    }
  }, [selectedCategoryId, fetchCategory]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedCategoryId
          ? "Modifier une catégorie de contrat"
          : "Ajouter une catégorie de contrat"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={(e, { value }) =>
                formik.setFieldValue("libelle", value)
              }
              onBlur={formik.handleBlur("libelle")}
              error={formik.touched.libelle && formik.errors.libelle}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
          <Form.Field error={!!formik.errors.description}>
            <label>Description</label>
            <Form.TextArea
              placeholder="Votre texte ici..."
              value={formik.values.description}
              onChange={(e, { value }) =>
                formik.setFieldValue("description", value)
              }
              onBlur={formik.handleBlur("description")}
              error={formik.touched.description && formik.errors.description}
            />
            {formik.touched.description && formik.errors.description && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.description}
              </small>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          content={selectedCategoryId ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => formik.handleSubmit()}
          positive
          type="button"
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CategorieContratFormModal;
