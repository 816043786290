import { api } from "../../axios/api";

export const createPayment = async (uuid, data) => {
  return api.post(`/gestion-production/payments/${uuid}`, data);
};

export const getPayments = async (params = {}) => {
  return api.get(`/gestion-production/payments`, {
    params,
  });
};

export const getPayment = async (uuid) => {
  return api.get(`/gestion-production/payments/${uuid}`);
};

export const updatePayment = async (uuid, data) => {
  return api.put(`/gestion-production/payments/${uuid}`, data);
};

export const deletePayment = async (uuid) => {
  return api.delete(`/gestion-production/payments/${uuid}`);
};
