import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  FormGroup,
  Input,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as magasinsService from "../../../services/gestion_attestations/company/magasinsService";
import * as requestsService from "../../../services/gestion_attestations/company/distributionsRequestsService";
import * as distributionsService from "../../../services/gestion_attestations/company/distributionsService";

const validationSchema = yup.object().shape({
  start_serial: yup
    .number("Veuillez renseigner un numéro de série de début valide")
    .required("Veuillez renseigner le numéro de série de début"),
  end_serial: yup
    .number("Veuillez renseigner un numéro de série de fin valide")
    .min(
      yup.ref("start_serial"),
      "Le numéro de série de fin doit être supérieur au numéro de série de début"
    )
    .required("Veuillez renseigner le numéro de série de fin"),
  magasin_uuid: yup.string().required("Le magasin est obligatoire"),
  magasin_stock_uuid: yup.string().required("Le stock est obligatoire"),
});
function CompanyDistributionModalForm({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleCloseModal = () => {},
}) {
  const [magasins, setMagasins] = useState([]);
  const [selectedMagasinStocks, setSelectedMagasinStocks] = useState([]);
  const [request, setRequest] = useState(null);
  const quantityInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      start_serial: request?.attestations_distributions?.start_serial || 0,
      end_serial: request?.attestations_distributions?.end_serial || 0,
      magasin_uuid:
        request?.attestations_distributions?.attestations_magasins_stocks
          ?.attestations_magasins?.uuid || "",
      magasin_stock_uuid:
        request?.attestations_distributions?.attestations_magasins_stocks
          ?.uuid || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        start_serial: values.start_serial,
        end_serial: values.end_serial,
        magasin_stock_uuid: values.magasin_stock_uuid,
      };

      if (!request?.attestations_distributions) {
        data.attestation_request_uuid = request?.uuid;
      }
      const requestAction = request?.attestations_distributions
        ? distributionsService.updateDistribution(
            request?.attestations_distributions?.uuid,
            data
          )
        : distributionsService.createDistribution(data);
      requestAction
        .then((response) => {
          toast.success(
            request?.attestations_distributions
              ? "Distribution d'attestation mise à jour"
              : "Distribution d'attestation ajoutée"
          );
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              "Une erreur est survenue lors de " +
                (selectedItemId ? "la mise à jour" : "l'ajout") +
                " de la commande d'attestation"
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { values, errors, handleSubmit, isSubmitting, handleBlur } = formik;

  const fetchRequest = (selectedItemId) => {
    requestsService
      .fetchOne(selectedItemId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues({
            magasin_uuid:
              data.attestations_distributions?.attestations_magasins_stocks
                ?.attestations_magasins?.uuid || "",
            magasin_stock_uuid:
              data.attestations_distributions?.attestations_magasins_stocks
                ?.uuid || "",
            start_serial: data.attestations_distributions?.start_serial || "",
            end_serial: data.attestations_distributions?.end_serial || "",
          });

          setRequest(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const fetchMagasins = async () => {
    try {
      const { data } = await magasinsService.fetchAll();
      setMagasins(
        data?.filter(
          (m) => m.type_magasin !== "STOCKAGE" && m.is_active === 1
        ) || []
      );
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des magasins"
      );
    }
  };

  const fetchMagasinStocks = async (uuid) => {
    try {
      const { data } = await magasinsService.fetchStocks(uuid, { all: true });
      setSelectedMagasinStocks(data.stocks || []);
    } catch (error) {
      setSelectedMagasinStocks([]);
    }
  };

  useEffect(() => {
    formik.resetForm();
    if (open) {
      fetchRequest(selectedItemId);
      fetchMagasins();
    }
  }, [open]);

  useEffect(() => {
    fetchMagasinStocks(values.magasin_uuid);
  }, [values.magasin_uuid]);

  return (
    <Modal
      onClose={() => {
        formik.resetForm();
        handleCloseModal();
        setRequest(null);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
        setRequest(null);
      }}
    >
      <Modal.Header>
        {request?.attestations_distributions
          ? "Mettre à jour la distribution d'attestation"
          : "Ajouter une nouvelle distribution d'attestation"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <Form.Field width={16}>
            <label className="required">Choissisez un magasin</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner un magasin"
              name="magasin_uuid"
              options={[
                { key: "", value: "", text: "Choisir un magasin" },
              ].concat(
                magasins.map((magasin) => ({
                  key: magasin?.uuid,
                  value: magasin?.uuid,
                  text: magasin?.name,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("magasin_uuid", value);
              }}
              onBlur={handleBlur("attestations_stocks_uuid")}
              value={
                values.magasin_uuid ||
                request?.attestations_distributions
                  ?.attestations_magasins_stocks?.attestations_magasins?.uuid
              }
            />
            <small className="field-error">{errors.magasin_uuid}</small>
          </Form.Field>

          <Form.Field width={16}>
            <label className="required">Choissisez un stock</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner un stock"
              name="magasin_stock_uuid"
              options={[
                { key: "", value: "", text: "Choisir un stock" },
              ].concat(
                selectedMagasinStocks?.map((stock) => ({
                  key: stock?.uuid,
                  value: stock?.uuid,
                  text: `[${stock?.start_serial} - ${
                    stock?.end_serial
                  }] ${new Intl.NumberFormat().format(
                    stock.usage?.remainingQty || 0
                  )} attestation(s) restantes`,
                }))
              )}
              onChange={(e, { value }) => {
                formik.setFieldValue("magasin_stock_uuid", value);
                let selectedStock = selectedMagasinStocks.find(
                  (s) => s.uuid === value
                );
                if (selectedStock) {
                  formik.setFieldValue(
                    "start_serial",
                    selectedStock.usage?.available_start_serial
                  );
                } else {
                  formik.setFieldValue("start_serial", "");
                  formik.setFieldValue("end_serial", "");
                }
              }}
              onBlur={handleBlur("attestations_stocks_uuid")}
              value={values.magasin_stock_uuid || ""}
            />
            <small className="field-error">{errors.magasin_stock_uuid}</small>
          </Form.Field>
          <Form.Field width={8}>
            <label>Quantité</label>
            <Input
              type="number"
              ref={quantityInputRef}
              onChange={(e, { value }) => {
                const quantity = parseInt(e.target.value);
                if (!isNaN(quantity) && quantity > 0) {
                  const startSerial = formik.values.start_serial;
                  formik.setFieldValue(
                    "end_serial",
                    (Number(startSerial) + Number(quantity) - 1)
                      .toString()
                      .padStart(formik.values.start_serial.length, "0")
                  );
                }
              }}
            />
          </Form.Field>
          <FormGroup widths="equal">
            <Form.Field required={true}>
              <label>Numéro de série de début</label>
              <Form.Input
                name="start_serial"
                value={values.start_serial}
                onChange={(e, { value }) => {
                  formik.setFieldValue("start_serial", value);
                  quantityInputRef.current.value = "";
                }}
                error={errors.start_serial}
                min={
                  selectedMagasinStocks?.find(
                    (m) => m.uuid === values.magasin_stock_uuid
                  )?.start_serial || 0
                }
                max={
                  selectedMagasinStocks?.find(
                    (m) => m.uuid === values.magasin_stock_uuid
                  )?.end_serial || 0
                }
              />
            </Form.Field>
            <Form.Field required={true}>
              <label>Numéro de série de fin</label>
              <Form.Input
                name="end_serial"
                value={values.end_serial}
                onChange={(e, { value }) => {
                  formik.setFieldValue("end_serial", value);
                  quantityInputRef.current.value = "";
                }}
                error={errors.end_serial}
                min={values.start_serial}
                max={
                  selectedMagasinStocks?.find(
                    (m) => m.uuid === values.magasin_stock_uuid
                  )?.end_serial || 0
                }
              />
            </Form.Field>
          </FormGroup>
          {!isNaN(Number(values.start_serial)) &&
          !isNaN(Number(values.end_serial)) &&
          Number(values.start_serial) <= Number(values.end_serial) ? (
            <div className="text-right text-gray-500 font-bold">
              {`Quantité d'attestations: ${
                Number(values.end_serial) - Number(values.start_serial) + 1
              }`}
            </div>
          ) : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyDistributionModalForm;
