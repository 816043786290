import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as intermediairesAgenciesService from "../../services/intermediairesAgenciesService";

const validationSchema = yup.object().shape({
  denomination: yup.string().required("La dénomination est requise"),
  phone_1: yup.string().min(8).max(16),
  phone_2: yup.string().min(8).max(16),
  adress: yup.string().max(255),
});

function IntermediaireAgenceFormModal({
  intermediaireUuid = null,
  selectedAgencyId = null,
  callback = () => {},
  open = false,
  setOpen = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      phone_1: "",
      phone_2: "",
      adress: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const callAction = selectedAgencyId
        ? intermediairesAgenciesService.update(selectedAgencyId, {
            ...values,
            intermediaireUuid,
          })
        : intermediairesAgenciesService.create({
            ...values,
            intermediaireUuid,
          });

      callAction
        .then(
          (response) => {
            toast(
              `Agence ${
                selectedAgencyId ? "modifiée" : "ajoutée"
              } avec succès!`,
              {
                type: "success",
                theme: "colored",
              }
            );
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchAgency = () => {
    intermediairesAgenciesService
      .fetchOne(selectedAgencyId)
      .then((response) => {
        setFieldValue("denomination", response?.data?.denomination || "");
        setFieldValue("phone_1", response?.data?.phone_1 || "");
        setFieldValue("phone_2", response?.data?.phone_2 || "");
        setFieldValue("adress", response?.data?.adress || "");
      });
  };

  useEffect(() => {
    if (selectedAgencyId && open) {
      fetchAgency();
    }
  }, [selectedAgencyId, open]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
    >
      <Modal.Header>Ajouter une agence</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {errors.idcompagnies && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errors.idcompagnies}
            </div>
          )}
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Téléphone principal</label>
              <Input
                value={values.phone_1}
                onChange={handleChange("phone_1")}
                onBlur={handleBlur("phone_1")}
              />
              <small className="field-error">{errors.phone_1}</small>
            </Form.Field>
            <Form.Field>
              <label>Téléphone secondaire</label>
              <Input
                value={values.phone_2}
                onChange={handleChange("phone_2")}
                onBlur={handleBlur("phone_2")}
              />
              <small className="field-error">{errors.phone_2}</small>
            </Form.Field>
          </Form.Group>

          <Form.Field error={"adress" in errors}>
            <label>Adresse</label>
            <Input
              value={values.adress}
              onChange={handleChange("adress")}
              onBlur={handleBlur("adress")}
            />
            <small className="field-error">{errors.adress}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediaireAgenceFormModal;
