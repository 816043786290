import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
  Pagination,
} from "semantic-ui-react";
import ZonesCirculationFormModal from "../../components/modalForms/ZonesCirculationFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as zonesCirculationService from "../../services/zonesCirculationService";

function ZonesCirculation() {
  const [zones, setZones] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchZones = async () => {
    setIsLoading(true);
    try {
      const response = await zonesCirculationService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });

      if (response.status === 200) {
        const {
          zones,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;

        setZones(zones);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des zones de circulation");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteZone = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await zonesCirculationService.remove(id);
        if (response.status === 200) {
          toast.success("Zone supprimée avec succès");
          fetchZones();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression de la zone");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedZoneId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchZones();
  }, [pagination.currentPage, search]);

  return (
    <Page title="Zones de circulation">
      <GridRow className="ZonesCirculation">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Zones de circulation</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              onChange={handleSearchChange}
              value={search}
            >
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <Segment>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Libelle</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {zones.map((zone, index) => (
                <Table.Row key={`${zone.idsources}`}>
                  <Table.Cell>
                    {(pagination.currentPage - 1) * pagination.limit +
                      index +
                      1}
                  </Table.Cell>
                  <Table.Cell>{zone.libelle}</Table.Cell>
                  <Table.Cell>{zone.description}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() => {
                            const id = zone.idzones_circulation;
                            setSelectedZoneId(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                          onClick={() => {
                            const id = zone.idzones_circulation;
                            setSelectedZoneId(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                          onClick={() =>
                            handleDeleteZone(zone.idzones_circulation)
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4" textAlign="right">
                  <Pagination
                    activePage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                    disabled={isLoading}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </GridRow>
      <ZonesCirculationFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchZones({ page: pagination.currentPage, search: search });
          handleCloseModal();
        }}
        selectedZoneId={selectedZoneId}
        handleClose={handleCloseModal}
      />
    </Page>
  );
}

export default ZonesCirculation;
