import { Text, View } from "@react-pdf/renderer";
import React from "react";

function ConditionsParticulieresSouscripteur({ souscription, styles }) {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "50%", padding: 3 }}>
          <View
            style={{
              padding: 4,
              backgroundColor: "#dce1e6",
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {`Souscripteur : ${
                souscription?.souscripteurs?.groupe_souscripteur ===
                "ENTREPRISE"
                  ? `${souscription?.souscripteurs?.denomination}`
                  : `${souscription?.souscripteurs?.first_name} ${souscription?.souscripteurs?.last_name}`
              }`}
            </Text>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>NUI</Text>
            </View>
            <View>
              <Text>{souscription?.souscripteurs?.nui}</Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Telephone</Text>
            </View>
            <View>
              <Text>{souscription?.souscripteurs?.phone || "NA"}</Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>E-mail</Text>
            </View>
            <View>
              <Text>{souscription?.souscripteurs?.email}</Text>
            </View>
          </View>
        </View>
        <View style={{ width: "50%", padding: 3 }}>
          <View
            style={{
              padding: 4,
              backgroundColor: "#dce1e6",
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >{`Assuré : ${souscription?.souscripteur_assure?.assures?.nom_assure}`}</Text>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>NUI</Text>
            </View>
            <View>
              <Text>
                {souscription?.souscripteur_assure?.assures.nui}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Profession</Text>
            </View>
            <View>
              <Text>
                {souscription?.souscripteur_assure?.assures?.jobs?.libelle}
              </Text>
            </View>
          </View>
          <View style={styles.sectionItem}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Adresse</Text>
            </View>
            <View>
              <Text>
                {souscription?.souscripteur_assure?.assures?.address_assure}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default ConditionsParticulieresSouscripteur;
