import React, { useState, useEffect, useMemo } from "react";
import {
  Dropdown,
  Form,
  GridColumn,
  GridRow,
  Message,
  Tab,
} from "semantic-ui-react";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import IndentiteVehicule from "./components/IndentiteVehicule";
import CalculCommission from "./components/CalculCommission";
import ReferencesConditions from "./components/ReferencesConditions";
import * as categoriesContratsService from "../../../services/categoriesContratsService";
import SelectSouscriptorModal from "./components/SelectSouscriptorModal";
import AssurePane from "./components/AssurePane";
import * as souscriptionsService from "../../../services/souscriptionsService";
import * as directivesTarifaireValuesService from "../../../services/gestion_production/directivesTarifairesValueService";
import * as companyGarantiesService from "../../../services/gestion_production/company/garantiesService";
import * as poolInsuranceDurationService from "../../../services/gestion_production/pool/insurancesDurationsService";
import * as garantiesComplementairesService from "../../../services/gestion_production/company/garantiesComplementairesService";
import * as avariesFacultativesService from "../../../services/gestion_production/pool/avariesFacultativesService";
import * as companyDirectiveValuesService from "../../../services/gestion_production/company/directivesTarifairesValueService";
import * as intermediairesService from "../../../services/intermediairesService";

import GarantiesFacultatives from "./components/GarantiesFacultatives";
import SouscripteurSection from "./components/SouscripteurSection";
import GarantiesSection from "./components/GarantiesSection";
import SommaireSection from "./components/SommaireSection";
import useCalculateSouscription from "../../../hooks/souscription/useCalculateSouscription";
import {
  collectSouscriptionData,
  generateUniqueNumber,
} from "../../../utils/souscription_helper";
import {
  createSouscriptionInitialValues,
  createSouscriptionMonoSchema,
} from "../../../utils/souscriptionValidationSchema";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import { USERS_ROLE_ENUMS } from "../../../constants";

function SouscriptionMonoForm() {
  const userData = useRecoilValue(authState);
  const [categoriesContrats, setCategoriesContrats] = useState([]);
  const [selectedSouscripteur, setSelectedSouscripteur] = useState();
  const [openSelectSouscripteurModal, setOpenSelectSouscripteurModal] =
    useState(false);

  const [directivesTarifairesValues, setDirectivesTarifairesValues] = useState(
    []
  );

  const [companyGaranties, setCompanyGaranties] = useState([]);
  const [loadCompanyGaranties, setLoadCompanyGaranties] = useState(false);
  const [insuranceDurations, setInsuranceDurations] = useState([]);
  const [garantiesComplementaires, setGarantiesComplementaires] = useState([]);
  const [avariesFacultatives, setAvariesFacultatives] = useState([]);
  const [companyDirectiveValues, setCompanyDirectiveValues] = useState([]);
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState(null);
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: createSouscriptionInitialValues(),
    validationSchema: createSouscriptionMonoSchema,
    onSubmit: (values) => {
      const data = collectSouscriptionData(values);

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(
          key,
          typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]
        );
      });

      formData.append("fichier_carte_grise", values.fichier_carte_grise);

      souscriptionsService
        .create(
          formData,
          userData?.user?.intermediaire?.type_intermediaire === "COURTIER"
            ? selectedCompanyUuid || null
            : userData?.user?.intermediaire?.company?.uuid || null
        )
        .then(
          (response) => {
            toast(`Souscription enregistrée avec succès!.`, {
              type: "success",
              theme: "colored",
            });
            redirectToPoliceDetails(response?.data?.uuid);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { values, setSubmitting, setFieldValue } = formik;

  const calculateSouscription = useCalculateSouscription(
    values,
    setFieldValue,
    insuranceDurations,
    directivesTarifairesValues,
    companyDirectiveValues,
    userData?.user?.intermediaire?.type_intermediaire
  );

  const getDirectivesTarifairesValues = async () => {
    directivesTarifaireValuesService.getByActiveDirectivesTarifaires().then(
      (response) => {
        const directiveValue = (data, name) =>
          data.find((item) => item.name === name);
        setDirectivesTarifairesValues(response.data);
        formik.setFieldValue(
          "prime_nette_iac",
          directiveValue(response.data, "prime_nette_individuelle_conducteur")
            ?.value
        );
        formik.setFieldValue(
          "cout_police",
          directiveValue(response.data, "cout_police")?.value
        );
        formik.setFieldValue(
          "carte_rose",
          directiveValue(response.data, "carte_rose")?.value
        );

        formik.setFieldValue(
          "droit_de_timbre_automobile",
          directiveValue(response.data, "droit_de_timbre_automobile")?.value
        );
      },
      () => {}
    );
  };

  const fetchInsuranceDurations = async () => {
    poolInsuranceDurationService
      .getByActiveDirectiveTarifaires()
      .then(
        (response) => {
          setInsuranceDurations(response.data);
        },
        () => {}
      )
      .finally(() => {});
  };

  const fetchCompanyGaranties = async () => {
    setLoadCompanyGaranties(true);
    companyGarantiesService
      .getByActiveDirectivesTarifaires(
        userData?.user?.intermediaire?.type_intermediaire === "COURTIER"
          ? selectedCompanyUuid || null
          : userData?.user?.intermediaire?.company?.uuid || null,
        1
      )
      .then((res) => {
        const garanties = [];
        res?.data?.forEach((item) => {
          garanties.push({
            libelle:
              item.pool_directives_tarifaires_has_listes_garanties
                .liste_garanties.name,
            uuid: item.uuid,
            code: item.code,
            is_required:
              item.pool_directives_tarifaires_has_listes_garanties
                .is_required === 1,
            risques:
              item.pool_directives_tarifaires_has_listes_garanties.liste_garanties.liste_garanties_risques?.map(
                (risque) => {
                  return {
                    libelle: risque.name,
                    uuid: risque.uuid,
                    checked:
                      item.pool_directives_tarifaires_has_listes_garanties
                        .is_required === 1,
                    value: 0,
                  };
                }
              ),
          });
        });
        setCompanyGaranties(res.data);

        formik.setFieldValue("garanties", garanties);
      })
      .catch(() => {
        setCompanyGaranties([]);
        formik.setFieldValue("garanties", []);
      })
      .finally(() => {
        setLoadCompanyGaranties(false);
      });
  };

  const panesList = useMemo(() => {
    const basePages = [
      {
        menuItem: "Assuré",
        element: <AssurePane formik={formik} />,
      },
      {
        menuItem: "Détails contrat",
        element: (
          <ReferencesConditions
            formik={formik}
            insuranceDurations={insuranceDurations}
          />
        ),
      },
      {
        menuItem: "Identité du véhicule",
        element: (
          <IndentiteVehicule
            formik={formik}
            companyDirectiveValues={companyDirectiveValues}
            avariesFacultatives={avariesFacultatives}
            tauxBrisDeGlaces={
              directivesTarifairesValues.find(
                (item) => item.name === "tarif_bris_glaces"
              )?.value
            }
          />
        ),
      },
    ];
    if (
      ["COURTIER", "AGENT_GENERAL"].includes(
        userData?.user?.intermediaire?.type_intermediaire
      )
    ) {
      basePages.push({
        menuItem: "Calcul de la commission",
        element: (
          <CalculCommission
            formik={formik}
            taux_commision={
              companyDirectiveValues.find((item) => item.name === "commision")
                ?.value
            }
          />
        ),
      });
    }
    return basePages;
  }, [
    formik,
    companyDirectiveValues,
    avariesFacultatives,
    directivesTarifairesValues,
    insuranceDurations,
    userData?.user?.intermediaire?.type_intermediaire,
  ]);

  const fetchCategoriesContrats = async () => {
    const response = await categoriesContratsService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data?.categories)) {
      setCategoriesContrats(response?.data.categories);
    }
  };

  const fetchGarantiesComplementaires = async () => {
    try {
      const response = await garantiesComplementairesService.getAll({
        active: true,
      });

      if (response?.status === 200 && Array.isArray(response?.data)) {
        setGarantiesComplementaires(response?.data);
      }
    } catch (error) {}
  };

  const fetchAvariesFacultatives = async () => {
    try {
      const response =
        await avariesFacultativesService.getByActiveDirectiveTarifaires(
          userData?.user
        );
      setAvariesFacultatives(response?.data);
    } catch (error) {}
  };

  const fetchCompanyDirectiveValues = async () => {
    try {
      const response =
        await companyDirectiveValuesService.getByActiveDirectivesTarifaires(
          userData?.user?.intermediaire?.type_intermediaire === "COURTIER"
            ? selectedCompanyUuid || null
            : userData?.user?.intermediaire?.company?.uuid || null
        );
      setCompanyDirectiveValues(response?.data);
    } catch (error) {
      setCompanyDirectiveValues([]);
    }
  };

  const redirectToPoliceDetails = (uuid) => {
    navigate(
      `/${
        userData?.user?.user_role === USERS_ROLE_ENUMS.COMPANY_AGENCY_USER
          ? "agency"
          : "intermediaire-agency"
      }/production/polices/details/${uuid}`
    );
  };

  const fetchPartners = async () => {
    try {
      const response = await intermediairesService.getPartners();
      setCompanies(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    const initializeForm = async () => {
      await Promise.all([
        fetchCategoriesContrats(),
        getDirectivesTarifairesValues(),
        fetchCompanyGaranties(),
        fetchInsuranceDurations(),
        fetchGarantiesComplementaires(),
        fetchAvariesFacultatives(),
        fetchCompanyDirectiveValues(),
      ]);
    };
    initializeForm();
    setFieldValue("numero_proposition", generateUniqueNumber(10), false);
    return () => {};
  }, [userData?.user?.intermediaire?.company?.uuid, selectedCompanyUuid]);

  useEffect(() => {
    fetchPartners();
  }, []);

  useEffect(() => {
    setFieldValue(
      "idsouscripteurs",
      selectedSouscripteur?.idsouscripteurs,
      false
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSouscripteur]);

  useEffect(() => {
    if (!selectedSouscripteur || !values.lien_assure) return;
    const newAssureData =
      values.lien_assure === "LUI_MEME"
        ? {
            nom_assure:
              selectedSouscripteur?.groupe_souscripteur === "PARTICULIER"
                ? `${selectedSouscripteur?.first_name || ""} ${
                    selectedSouscripteur?.last_name || ""
                  }`
                : selectedSouscripteur?.denomination,
            phone_assure: selectedSouscripteur?.phone || "",
            email_assure: selectedSouscripteur?.email || "",
            address_assure: selectedSouscripteur?.address || "",
            idjobs: selectedSouscripteur?.idjobs || "",
            nui: selectedSouscripteur?.nui || "",
          }
        : {
            nom_assure: "",
            phone_assure: "",
            email_assure: "",
            address_assure: "",
            idjobs: "",
            nui: "",
          };
    Object.entries(newAssureData).forEach(([key, value]) => {
      setFieldValue(`assure.${key}`, value, false);
    });
  }, [values.lien_assure, selectedSouscripteur]);

  return (
    <React.Fragment>
      <GridRow as={Form} className="souscription-mono-form">
        {userData?.user?.intermediaire?.type_intermediaire === "COURTIER" && (
          <GridColumn width={16}>
            <Form.Field>
              <label>Compagnie</label>
              <Dropdown
                fluid
                selection
                search
                clearable
                placeholder="Sélectionner une compagnie"
                value={selectedCompanyUuid}
                onChange={(_, data) => {
                  setSelectedCompanyUuid(data.value);
                }}
                options={companies
                  .map((item) => ({
                    text: item.compagnies.denomination,
                    value: item.compagnies.uuid,
                  }))
                  .concat([
                    {
                      text: "Choisir une compagnie",
                      value: null,
                    },
                  ])}
              />
            </Form.Field>
          </GridColumn>
        )}
        <SouscripteurSection
          formik={formik}
          selectedSouscripteur={selectedSouscripteur}
          setOpenSelectSouscripteurModal={setOpenSelectSouscripteurModal}
          categoriesContrats={categoriesContrats}
        />
        <Tab
          style={{ marginTop: "40px" }}
          menu={{ fluid: true, vertical: true, tabular: true }}
          panes={panesList.map((pane, index) => ({
            menuItem: pane.menuItem,
            render: () => <Tab.Pane key={index}>{pane.element}</Tab.Pane>,
            key: index,
          }))}
        />

        <>
          {formik.values.garanties.length > 0 ? (
            <GarantiesSection
              formik={formik}
              directivesTarifairesValues={directivesTarifairesValues}
              avariesFacultatives={avariesFacultatives}
              companyDirectiveValues={companyDirectiveValues}
            />
          ) : (
            <>
              <GridColumn width={16}>
                <Message
                  header="Garanties"
                  content="Aucune garantie sélectionnée! Veuillez sélectionner une compagnie si vous êtes un courtier."
                />
              </GridColumn>
            </>
          )}
          {garantiesComplementaires.length > 0 && (
            <GarantiesFacultatives
              formik={formik}
              garantiesComplementaires={garantiesComplementaires}
            />
          )}
        </>

        <SommaireSection formik={formik} />
      </GridRow>
      <SelectSouscriptorModal
        open={openSelectSouscripteurModal}
        selectedSouscripteur={selectedSouscripteur}
        setSelectedSouscripteur={setSelectedSouscripteur}
        setOpen={setOpenSelectSouscripteurModal}
      />
    </React.Fragment>
  );
}

export default SouscriptionMonoForm;
