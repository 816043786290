import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";

function ConditionsParticulieresHeader({ souscription }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 5,
      }}
    >
      <View style={{ width: "100%" }}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {souscription?.souscription_polices?.numero_police
            ? "Conditions particulières"
            : "Proposition d'assurance"}
        </Text>
      </View>
    </View>
  );
}

export default ConditionsParticulieresHeader;
