import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as zonesCirculationService from "../../services/zonesCirculationService";

const validationSchema = yup.object().shape({
  libelle: yup
    .string()
    .required("Le libellé est requis")
    .min(2, "Le libellé doit contenir au moins 2 caractères")
    .max(100, "Le libellé ne doit pas dépasser 100 caractères"),
  description: yup
    .string()
    .max(500, "La description ne doit pas dépasser 500 caractères"),
});

const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

function ZonesCirculationFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedZoneId,
  handleClose = () => {},
}) {
  const fetchZone = useCallback(async () => {
    if (!selectedZoneId) {
      return;
    }

    try {
      const response = await zonesCirculationService.fetchOne(selectedZoneId);
      const zoneData = response.data.zone || response.data;

      if (zoneData) {
        formik.setValues({
          libelle: zoneData.libelle || "",
          description: zoneData.description || "",
        });
      }
    } catch (error) {
      toast.error(
        `Erreur lors de la récupération de la zone: ${
          error.response?.data?.message || error.message
        }`,
        TOAST_CONFIG
      );
    }
  }, [selectedZoneId]);

  const formik = useFormik({
    initialValues: {
      libelle: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const isEditMode = !!selectedZoneId;

        const serviceCall = isEditMode
          ? zonesCirculationService.update(selectedZoneId, values)
          : zonesCirculationService.create(values);

        const response = await serviceCall;

        if (response.status === 201 || response.status === 200) {
          const successMessage = isEditMode
            ? "Zone modifiée avec succès"
            : "Zone ajoutée avec succès";
          toast.success(successMessage, TOAST_CONFIG);

          await callback();

          resetForm();
          setOpen(false);
        }
      } catch (error) {
        toast.error(
          `Une erreur est survenue: ${
            error.response?.data?.message || error.message
          }`,
          TOAST_CONFIG
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (selectedZoneId) {
      fetchZone();
    } else {
      formik.resetForm();
    }
  }, [selectedZoneId, fetchZone]);

  const handleModalClose = () => {
    setOpen(false);
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>
        {selectedZoneId
          ? "Modifier une zone de circulation"
          : "Ajouter une zone de circulation"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={!!formik.errors.libelle}>
            <label>Libellé</label>
            <Input
              placeholder="Entrez le libellé"
              value={formik.values.libelle}
              onChange={(e, { value }) => {
                formik.setFieldValue("libelle", value);
              }}
              onBlur={formik.handleBlur("libelle")}
            />
            {formik.touched.libelle && formik.errors.libelle && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.libelle}
              </small>
            )}
          </Form.Field>
          <Form.Field error={!!formik.errors.description}>
            <label>Description</label>
            <Form.TextArea
              placeholder="Entrez la description"
              value={formik.values.description}
              onChange={(e, { value }) => {
                formik.setFieldValue("description", value);
              }}
              onBlur={formik.handleBlur("description")}
            />
            {formik.touched.description && formik.errors.description && (
              <small className="field-error" style={{ color: "red" }}>
                {formik.errors.description}
              </small>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={handleModalClose}>
          Annuler
        </Button>
        <Button
          type="button"
          content={selectedZoneId ? "Modifier" : "Enregistrer"}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            formik.handleSubmit();
          }}
          positive
          loading={formik.isSubmitting}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ZonesCirculationFormModal;
