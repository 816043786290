import dayjs from "dayjs";
import * as yup from "yup";

export const createSouscriptionMonoSchema = yup.object().shape({
  idsouscriptions: yup.number().notRequired(),
  idsouscripteurs: yup.number().required("valeur requise"),
  idcategories_contrats: yup.number().required("valeur requise"),
  date_souscription: yup.date().nullable().default(null),
  lien_assure: yup.string().required("valeur requise"),
  montant_commission: yup.number(),
  prime_nette: yup.number(),
  prime_nette_totale: yup.number(),
  prime_nette_iac: yup.number(),
  cout_police: yup.number(),
  taxes: yup.number(),
  montant_gestion_pool: yup.number(),
  carte_rose: yup.number(),
  droit_de_timbre_automobile: yup.number(),
  garanties: yup.array().required("valeur requise").min(1),
  fichier_carte_grise: yup.mixed().required("valeur requise"),
  numero_proposition: yup
    .string()
    .matches(/^[0-9]{10}$/)
    .required("valeur requise"),
  assure: yup
    .object({
      prime_ttc: yup.number(),
      nom_assure: yup.string().required("valeur requise").min(4).max(255),
      phone_assure: yup.string().required("valeur requise").min(7).max(12),
      email_assure: yup.string().email().max(255),
      address_assure: yup.string().max(255),
      idjobs: yup.number(),
      nui: yup.string().max(12).nullable(),
    })
    .required("valeur requise"),
  conditions_particulieres: yup
    .object({
      date_echeance: yup.date().required("valeur requise"),
      date_effet: yup
        .date()
        .required("valeur requise")
        .default(
          dayjs(yup.ref("conditions_particulieres.date_proposition"))
            .add(1, "day")
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DD HH:mm")
        ),
      date_proposition: yup.date().required("valeur requise"),
      type_renouvellement: yup.string().required("valeur requise"),
      type_avenant: yup.string().max(20).required("valeur requise"),
      pool_avaries_facultatives_uuid: yup
        .mixed()
        .test(
          "valid-uuid-or-zero-or-null-or-empty ",
          "Doit être un UUID valide, 0 ou null ou vide",
          (value) =>
            value === null ||
            value === 0 ||
            (typeof value === "string" &&
              /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                value
              ))
        ),
    })
    .required("valeur requise"),
  identite_vehicule: yup.object({
    idsources_energie: yup.number().required("valeur requise"),
    usage_vehicule: yup
      .string()
      .oneOf(["TRAPUBVOY"])
      .required("valeur requise"),
    puissance_fiscale: yup.number().min(1).required("valeur requise"),
    nombre_places: yup.number().min(3).max(100).required("valeur requise"),
    marque_vehicule: yup.string().required("valeur requise"),
    type_vehicule: yup.string().required("valeur requise"),
    genre_vehicule: yup.string().required("valeur requise"),
    date_premiere_mise_en_circulation: yup.date(),
    valeur_a_neuf: yup.number().required("valeur requise"),
    valeur_venale: yup
      .number()
      .required("valeur requise")
      .test(
        "inferieur-valeur-neuf",
        "La valeur vénale doit être inférieure à la valeur à neuf",
        function (valeur_venale) {
          return valeur_venale < this.parent.valeur_a_neuf;
        }
      ),
    immatriculation_vehicule: yup.string().required("valeur requise"),
    immatriculation_vehicule_precedente: yup.string(),
    numero_chassis: yup.string().required("valeur requise"),
    numero_permis_conduire: yup.string().optional().nullable(),
    numero_police: yup.string(),
    date_delivrance_permis: yup.date().optional().nullable(),
    idzones_circulation: yup.number().required("valeur requise"),
    carrosserie: yup.string(),
  }),
  garanties_complementaires: yup.array(
    yup.object({
      uuid: yup.string().required("valeur requise"),
      value: yup.number().min(0).required("valeur requise").nullable(),
    })
  ),
});

export const createSouscriptionInitialValues = () => {
  return {
    idsouscriptions: "",
    idsouscripteurs: "",
    numero_police: "",
    numero_proposition: "",
    idcategories_contrats: 1,
    montant_commission: 0,
    prime_nette_iac: 0,
    prime_nette: 0,
    prime_nette_totale: 0,
    cout_police: 0,
    taxes: 0,
    montant_gestion_pool: 0,
    carte_rose: 0,
    droit_de_timbre_automobile: 0,
    prime_ttc: 0,
    date_souscription: dayjs().format("YYYY-MM-DD HH:mm"),
    fichier_carte_grise: null,
    assure: {
      nom_assure: "",
      lien_assure: "",
      phone_assure: "",
      email_assure: "",
      address_assure: "",
      idjobs: "",
      nui: "",
    },
    garanties: [],
    conditions_particulieres: {
      date_proposition: dayjs().format("YYYY-MM-DD HH:mm"),
      date_echeance: dayjs()
        .add(12, "months")
        .add(-1, "day")
        .format("YYYY-MM-DD HH:mm"),
      date_effet: dayjs()
        .add(1, "day")
        .hour(0)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DD HH:mm"),
      type_avenant: "AFFAIRE_NOUVELLE",
      type_renouvellement: "",
      pool_avaries_facultatives_uuid: null,
    },
    identite_vehicule: {
      idzones_circulation: "",
      idsources_energie: "",
      usage_vehicule: "TRAPUBVOY",
      puissance_fiscale: "",
      nombre_places: "",
      marque_vehicule: "",
      type_vehicule: "",
      genre_vehicule: "",
      date_premiere_mise_en_circulation: "",
      valeur_a_neuf: "",
      valeur_venale: "",
      immatriculation_vehicule: "",
      immatriculation_vehicule_precedente: "",
      numero_chassis: "",
      numero_permis_conduire: "",
      date_delivrance_permis: "",
      carrosserie: "",
    },
    garanties_complementaires: [],
  };
};
