import { api } from "../../../axios/api";

export const fetchAll = async (filter) => {
  return api.get(`gestion-attestations/companies/magasins/stocks`);
};

export const fetchOne = async (uuid) => {
  return api.get(`gestion-attestations/companies/magasins/stocks/${uuid}`);
};
export const create = async (data) => {
  return api.post(`gestion-attestations/companies/magasins/stocks`, data);
};

export const update = async (id, data) => {
  return api.put(`gestion-attestations/companies/magasins/stocks/${id}`, data);
};

export const deleteOne = async (id) => {
  return api.delete(`gestion-attestations/companies/magasins/stocks/${id}`);
};

export const fetchByDelivery = async (uuid) => {
  return api.get(
    `gestion-attestations/companies/magasins/stocks/by-delivery/${uuid}`
  );
};

export const fetchTransfert = async (uuid) => {
  return api.get(
    `gestion-attestations/companies/magasins/stocks/transfers/${uuid}`
  );
};

export const transferCreate = async (data) => {
  return api.post(
    `gestion-attestations/companies/magasins/stocks/transfers`,
    data
  );
};

export const transferUpdate = async (uuid, data) => {
  return api.put(
    `gestion-attestations/companies/magasins/stocks/transfers/${uuid}`,
    data
  );
};

export const transferDelete = async (uuid) => {
  return api.delete(
    `gestion-attestations/companies/magasins/stocks/transfers/${uuid}`
  );
};

export const fetchTransfertsByMagasinStock = async (uuid = null) => {
  return api.get(
    `gestion-attestations/companies/magasins/stocks/transfers${
      uuid ? `/${uuid}` : ""
    }`
  );
};

export const deleteTransfertStock = async (uuid) => {
  return api.delete(
    `gestion-attestations/companies/magasins/stocks/transfers/${uuid}`
  );
};
