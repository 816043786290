import React from "react";
import {
  Button,
  Grid,
  GridRow,
  Label,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import * as policesService from "../../../services/gestion_production/policesService";
import dayjs from "dayjs";
import { toast } from "react-toastify";

function SuspensionModal({
  selectedOperation,
  setSelectedOperation,
  setOpen = () => {},
  onClose = () => {},
  idpolices = null,
  onSuccessCallBack = () => {},
  onErrCallBack = () => {},
}) {
  const [police, setPolice] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const fetchpolice = async () => {
    try {
      const response = await policesService.findOne(idpolices);
      if (response.status === 200 && response.data) {
        setPolice(response.data);
      }
    } catch (error) {
      toast("Une erreur s'est produite", {
        type: "error",
        title: "Erreur",
      });
    }
  };

  const suspendrePolice = async () => {
    try {
      setLoading(true);
      const response = await policesService.suspensionPolice(idpolices);
      if (response.status === 200 && response.data) {
        onSuccessCallBack();
        toast("La police à bien été suspendue", {
          type: "success",
          title: "Succès",
        });
      }
    } catch (error) {
      toast("Une erreur s'est produite", {
        type: "error",
        title: "Erreur",
      });
      onErrCallBack();
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (idpolices) {
      fetchpolice();
    }
  }, [idpolices]);

  return (
    <Modal
      onClose={() => {
        setSelectedOperation(null);
      }}
      onOpen={() => {
        selectedOperation("suspension");
      }}
      open={selectedOperation === "suspension"}
    >
      <Modal.Header>Suspension de la police</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <GridRow>
            <Label ribbon>{police?.num_police || "N/A"}</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Code client</List.Header>
                      <List.Description>
                        {
                          police?.souscriptions
                            ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                            ?.num
                        }
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Immatriculation</List.Header>
                      <List.Description>
                        {police?.avenants[0]?.vehicules?.immatriculation}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Statut</List.Header>
                      <List.Description>
                        {police?.avenants[0]?.types_avenants?.code}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Noms/Dénomination</List.Header>
                      <List.Description>
                        {police?.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.groupe_souscripteur === "PARTICULIER"
                          ? `${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name}
                       ${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}`
                          : police?.souscriptions
                              ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                              ?.denomination}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date d'éffet</List.Header>
                      <List.Description>
                        {dayjs(police?.avenants.date_effet).format(
                          "DD/MM/YYYY"
                        )}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Type de client/</List.Header>
                      <List.Description>
                        {
                          police?.souscriptions
                            ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                            ?.groupe_souscripteur
                        }
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Echéance</List.Header>
                      <List.Description>
                        {dayjs(police?.avenants[0]?.date_effet)
                          .add(
                            Number(
                              police?.avenants[0]?.renouvellement.slice(-2)
                            ),
                            "months"
                          )
                          .add(-1, "day")
                          .format("DD/MM/YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={16}>
                <Message warning>
                  <Message.Header>Note</Message.Header>
                  <p>
                    Seules les polices d'une durée de plus trois mois peuvent
                    être suspendues
                  </p>
                  <p>
                    {-dayjs(police?.avenants[0]?.date_effet).diff(
                      dayjs(police?.avenants[0]?.date_effet).add(
                        police?.avenants[0]?.renouvellement?.substr(-2),
                        "months"
                      ),
                      "month"
                    ) < 3 ? (
                      <span style={{ color: "red" }}>
                        La police ne peut être suspendue
                      </span>
                    ) : (
                      <span style={{ color: "green" }}>
                        La police peut être suspendue
                      </span>
                    )}
                  </p>
                </Message>
              </Grid.Column>
            </Grid>
          </GridRow>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Fermer</Button>
        <Button
          primary
          disabled={
            -dayjs(police?.avenants[0]?.date_effet).diff(
              dayjs(police?.avenants[0]?.date_effet).add(
                police?.avenants[0]?.renouvellement?.substr(-2),
                "months"
              ),
              "month"
            ) < 3
          }
          onClick={() => {
            suspendrePolice();
          }}
          loading={loading}
        >
          Suspendre
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SuspensionModal;
