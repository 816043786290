import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Grid,
  Input,
  Segment,
  Table,
  Form,
  Pagination,
  Breadcrumb,
  Divider,
  Icon,
  Label,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import * as actionsService from "../../services/logss/actionLogs";
import ActionLogDetailsModal from "../../components/journalisation/ActionLogDetailsModal";
import { debounce } from "lodash";

function JournalLogs() {
  const [search, setSearch] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [allactionsLogs, setAllactionsLogs] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    dateDebut: "",
    dateFin: "",
  });
  const [loading, setLoading] = useState(true);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });

  const fetchAllLogs = useCallback(async (currentFilters, page) => {
    setLoading(true);
    try {
      const response = await actionsService.fetchAll({
        page: page,
        limit: 10,
        search: currentFilters.search,
        startDate: currentFilters.dateDebut,
        endDate: currentFilters.dateFin,
      });

      if (response.status === 200) {
        setAllactionsLogs(response.data.logs);
        setPagination(response.data.pagination);
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Erreur lors du chargement des journaux"
      );
      setAllactionsLogs([]);
    } finally {
      setLoading(false);
    }
  }, []);
  const debouncedFetch = useMemo(
    () => debounce((filters, page) => fetchAllLogs(filters, page), 500),
    [fetchAllLogs]
  );

  useEffect(() => {
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [debouncedFetch]);

  const renderUserInfo = useCallback((user) => {
    if (!user) return "Utilisateur inconnu";
    return (
      `${user.first_name || ""} ${user.last_name || ""} ${
        user.email ? `(${user.email})` : ""
      }`.trim() || "Inconnu"
    );
  }, []);

  const handleViewDetails = useCallback((log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    debouncedFetch(filters, pagination.currentPage);
    return () => debouncedFetch.cancel();
  }, [filters, pagination.currentPage, debouncedFetch]);

  const handleResetFilters = useCallback(() => {
    setFilters({
      search: "",
      dateDebut: "",
      dateFin: "",
    });
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  }, []);

  const handlePageChange = useCallback((e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  }, []);

  const handleFilterChange = useCallback((field, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [field]: value };

      if (newFilters.dateDebut && newFilters.dateFin) {
        if (new Date(newFilters.dateDebut) > new Date(newFilters.dateFin)) {
          toast.error("La date de début doit être avant la date de fin");
          return prev;
        }
      }
      return {
        ...newFilters,
        currentPage: 1,
      };
    });
  }, []);

  return (
    <Page title="Journaux d'Actions">
      <Grid className="!pt-0">
        <Grid.Column width={16}>
          <Breadcrumb>
            <Breadcrumb.Section link>Pool</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>journal-audit</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Journal d'actions</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>
      <Divider />

      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="ui segment">
              <Form className="w-full">
                <Form.Group className="flex flex-row gap-2 justify-end">
                  <Form.Field width={3}>
                    <label>Date de début</label>

                    <Input
                      type="date"
                      name="dateDebut"
                      value={filters.dateDebut}
                      onChange={(e) => {
                        handleFilterChange("dateDebut", e.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>Date de fin</label>
                    <Input
                      type="date"
                      name="dateFin"
                      value={filters.dateFin}
                      onChange={(e) => {
                        handleFilterChange("dateFin", e.target.value);
                      }}
                    />
                  </Form.Field>
                  <Form.Field width={3}>
                    <label>Recherche</label>
                    <Input
                      placeholder="Rechercher..."
                      value={filters.search}
                      onChange={(e) =>
                        handleFilterChange("search", e.target.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field width={1}>
                    <label></label>
                    <Button
                      type="button"
                      onClick={handleResetFilters}
                      icon
                      disabled={loading}
                    >
                      <Icon name="refresh" />
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Segment loading={loading}>
              <Table striped celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date et Heure</Table.HeaderCell>
                    <Table.HeaderCell>Utilisateur</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.HeaderCell>Statut</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {allactionsLogs.length > 0 ? (
                    allactionsLogs.map((log) => (
                      <Table.Row key={log.id}>
                        <Table.Cell>
                          {new Date(log.timestamp).toLocaleString()}
                        </Table.Cell>
                        <Table.Cell>{renderUserInfo(log.user)}</Table.Cell>
                        <Table.Cell>
                          <Label size="mini" color="blue">
                            <Icon name="tasks" />
                            {log.action}
                          </Label>
                        </Table.Cell>
                        <Table.Cell>
                          <Label
                            size="mini"
                            color={log.success ? "green" : "red"}
                            content={log.success ? "Succès" : "Échec"}
                          />
                        </Table.Cell>
                        <Table.Cell>{log.message}</Table.Cell>
                        <Table.Cell textAlign="right">
                          <Icon
                            name="eye"
                            color="green"
                            className="cursor-pointer"
                            onClick={() => handleViewDetails(log)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="6" textAlign="center">
                        Aucun journal trouvé
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                {pagination?.totalPages > 1 && (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="6" textAlign="right">
                        <Pagination
                          activePage={pagination?.currentPage}
                          totalPages={pagination?.totalPages}
                          onPageChange={handlePageChange}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ActionLogDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedLog={selectedLog}
        renderUserInfo={renderUserInfo}
      />
    </Page>
  );
}

export default JournalLogs;
