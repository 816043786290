import { api } from "../axios/api";

export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`dictionnaire/Zones-circulation`, {
    params: { page, search },
  });
};

export const fetchOne = async (id) => {
  return api.get(`dictionnaire/zones-circulation/${id}`);
};
export const create = async (data) => {
  return api.post(`dictionnaire/zones-circulation`, data);
};

export const update = async (id, data) => {
  return api.put(`dictionnaire/zones-circulation/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`dictionnaire/zones-circulation/${id}`);
};
