import { api } from "../axios/api";

export const fetchAll = async ({ page, search } = {}) => {
  return api.get(`dictionnaire/sources-energies`, { params: { page, search } });
};

export const fetchOne = async (id) => {
  return api.get(`dictionnaire/sources-energies/${id}`);
};
export const create = async (data) => {
  return api.post(`dictionnaire/sources-energies`, data);
};

export const update = async (id, data) => {
  return api.put(`dictionnaire/sources-energies/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`dictionnaire/sources-energies/${id}`);
};
