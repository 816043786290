import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as usersService from "../../services/usersService";
import { USERS_ROLE_ENUMS_OPTIONS } from "../../constants";

const validationSchema = yup.object().shape({
  first_name: yup.string().max(150).required("Le prénom est requis"),
  last_name: yup.string().max(150).required("Le nom est requis"),
  email: yup.string().email().trim().required("L'email est requis"),
  password: yup.string().trim().min(8).max(60),
  phone: yup.string().min(8).max(16).required("Le téléphone est requis"),
  active: yup.number().nullable().oneOf([0, 1], "Le statut est requis"),
  user_role: yup
    .string()
    .oneOf([
      "AGENCY_USER",
      "COMPANY_USER",
      "POOL_USER",
      "ROOT_ADMIN",
      "COURTIER_USER",
    ])
    .required(),
});
validationSchema.concat(yup.object({ password: yup.string().required() }));

function UserFormModal({
  callback = () => {},
  open = false,
  setOpen = () => {},
  selectedId = null,
  handleClose = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone: "",
      active: true,
      user_role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const action = selectedId
        ? usersService.update(values, selectedId)
        : usersService.create(values);
      action
        .then(
          (response) => {
            const message = selectedId
              ? `Utilisateur mis à jour avec succès.`
              : `Utilisateur ajouté avec succès.`;
            toast(message, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  const fetchUser = async () => {
    try {
      const response = await usersService.fetchOne(selectedId);
      if (response.status === 200 && response.data) {
        setFieldValue("user_role", response.data.user_role);
        setFieldValue("first_name", response.data.first_name);
        setFieldValue("last_name", response.data.last_name);
        setFieldValue("email", response.data.email);
        setFieldValue("phone", response.data.phone);
        setFieldValue("active", response.data.active);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedId && open) {
      fetchUser();
    }
  }, [selectedId, open]);

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        handleReset();
        handleClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
      closeIcon
      onUnmount={() => {
        if (!open) {
          handleReset();
          handleClose();
        }
      }}
    >
      <Modal.Header>
        {selectedId ? "Editer" : "Ajouter"} un utilisateur
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"last_name" in errors}>
              <label>Nom</label>
              <Input
                value={values.last_name}
                onChange={handleChange("last_name")}
                onBlur={handleBlur("last_name")}
              />
              <small className="field-error">{errors.last_name}</small>
            </Form.Field>
            <Form.Field required error={"first_name" in errors}>
              <label>Prénom</label>
              <Input
                value={values.first_name}
                onChange={handleChange("first_name")}
                onBlur={handleBlur("first_name")}
              />
              <small className="field-error">{errors.first_name}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"phone" in errors}>
              <label>Téléphone</label>
              <Input
                value={values.phone}
                onChange={handleChange("phone")}
                onBlur={handleBlur("phone")}
              />
              <small className="field-error">{errors.phone}</small>
            </Form.Field>
            <Form.Field required error={"email" in errors}>
              <label>Email</label>
              <Input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
              />
              <small className="field-error">{errors.email}</small>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field required error={"user_role" in errors}>
              <label>Type d'utlisateur</label>
              <Form.Select
                fluid
                scrolling
                value={values.user_role}
                onChange={(e, data) => setFieldValue("user_role", data.value)}
                options={USERS_ROLE_ENUMS_OPTIONS?.filter(
                  (role) => role.value !== "AGENT_GENERAL_USER"
                )}
                placeholder="Choisr..."
              />
              <small className="field-error">{errors.user_role}</small>
            </Form.Field>
            <Form.Field required error={"password" in errors}>
              <label>Mot de passe</label>
              <Input
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                name="password"
                action={{
                  icon: "magic",
                  onClick: (e) => {
                    e.preventDefault();
                    const randomPassword = Math.random().toString(36).slice(-8);
                    setFieldValue("password", randomPassword, false);
                  },
                }}
              />
              <small className="field-error">{errors.password}</small>
            </Form.Field>
          </Form.Group>
          <Form.Field error={"active" in errors}>
            <Form.Checkbox
              toggle
              onChange={(e, data) =>
                setFieldValue("active", data.checked ? 1 : 0)
              }
              label="Compte actif"
              checked={values.active === 1}
            />
            <small className="field-error">{errors.active}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleClose();
            setOpen(false);
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default UserFormModal;
