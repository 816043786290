import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import numeral from "numeral";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(minMax);

const validationSchema = yup.object().shape({
  garanties: yup.array().of(yup.string().uuid()),
  removed_garanties: yup.array().of(yup.string().uuid()),
  date_avenant: yup.date().required("La date de l'avenant est obligatoire"),
  amount: yup
    .number()
    .min(5000)
    .required("Le montant de la ristourne est obligatoire"),
});

function RetraitsDeGarantiesModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);
  const [primeNetteTtc, setPrimeNetteTtc] = useState(0);

  const formik = useFormik({
    initialValues: {
      garanties: [],
      removed_garanties: [],
      date_avenant: "",
      amount: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        removed_garanties: values.removed_garanties,
        date_avenant: values.date_avenant,
        amount: calculateRistourne(values.garanties),
      };
      avenantsService
        .createAvenantRetraitGarantie(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Retrait de garantie effectué avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (open === true) {
      setFieldValue("amount", calculateRistourne(values.garanties));
    }
  }, [formik.values.garanties, open]);

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(souscription_uuid);
      setSouscription(response.data);
      setPrimeNetteTtc(response.data?.prime_ttc || 0);
      const garanties = response.data.souscription_garanties
        .filter(
          (garantie) =>
            garantie.company_has_liste_garanties
              .pool_directives_tarifaires_has_listes_garanties.is_required !==
              1 &&
            garantie.souscription_garanties_risques?.length > 0 &&
            garantie.souscription_garanties_risques?.reduce(
              (sum, r) => sum + r.value,
              0
            ) > 0
        )
        .map((garantie) => garantie.uuid);
      setFieldValue("garanties", garanties);
    } catch (error) {}
  };

  const getPrimeNetteTtc = (garanties) => {
    let primeNetteTtc =
      souscription?.prime_ttc -
      souscription?.souscription_garanties
        .filter((g) => !garanties.includes(g.uuid))
        .reduce(
          (sum, g) =>
            sum +
            g?.souscription_garanties_risques?.reduce(
              (total, r) => total + Number(r.value),
              0
            ),
          0
        );
    return primeNetteTtc;
  };

  const calculateRistourne = (garanties) => {
    try {
      const date_application = dayjs(values.date_avenant).add(1, "day");
      const retrait_sur_prime =
        souscription?.prime_ttc - getPrimeNetteTtc(garanties);

      const duree_contrat = dayjs(
        souscription?.conditions_particulieres?.date_echeance
      )
        .add(1, "minutes")
        .diff(
          dayjs(souscription?.conditions_particulieres?.date_effet),
          "days"
        );

      const jours_restants = dayjs(
        souscription?.conditions_particulieres?.date_echeance
      )
        .add(1, "minutes")
        .diff(date_application, "days");

      const ristourne = (jours_restants / duree_contrat) * retrait_sur_prime;
      return Math.round(ristourne);
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    if (souscription_uuid && open === true) {
      fetchSouscription();
    }
  }, [souscription_uuid, open]);

  const toggleGarantie = (garantie_uuid) => {
    const garanties = [...values.garanties];
    const index = garanties.indexOf(garantie_uuid);
    if (index > -1) {
      garanties.splice(index, 1);
    } else {
      garanties.push(garantie_uuid);
    }

    let primeNetteTtc = getPrimeNetteTtc(garanties);

    setPrimeNetteTtc(primeNetteTtc);
    setFieldValue("garanties", garanties);
    setFieldValue(
      "removed_garanties",
      souscription?.souscription_garanties
        .filter(
          (garantie) =>
            garantie.company_has_liste_garanties
              .pool_directives_tarifaires_has_listes_garanties.is_required !==
              1 &&
            garantie.souscription_garanties_risques?.length > 0 &&
            garantie.souscription_garanties_risques?.reduce(
              (sum, r) => sum + r.value,
              0
            ) > 0
        )
        .filter((garantie) => !garanties.includes(garantie.uuid))
        ?.map((g) => g.uuid)
    );
  };

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean" ? setOpen(true) : setOpen("retrait-garantie");
      }}
      open={typeof open === "string" ? open === "retrait-garantie" : open}
      closeIcon
      onUnmount={() => {
        if (open !== true) {
          handleReset();
        }
      }}
      size="large"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant de Retrait de garantie(s)</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui tiny form">
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={4}>
                  {souscription && (
                    <>
                      <Segment raised>
                        <Header as="h5">Informations de la souscription</Header>
                        <List>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date de prise d'effet</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_effet
                                ).format("DD/MM/YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Date d'expiration</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                )
                                  .add(1, "minutes")
                                  .subtract(1, "day")
                                  .format("DD/MM/YYYY")}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>Durée du contrat</List.Header>
                              <List.Description>
                                {dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                )
                                  .add(1, "day")
                                  .diff(
                                    dayjs(
                                      souscription?.conditions_particulieres
                                        ?.date_effet
                                    ),
                                    "days"
                                  )}{" "}
                                jours
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Segment>
                      <Segment raised>
                        <Form.Field required error={!!errors.date_avenant}>
                          <label>Date de l'avenant</label>
                          <Input
                            type="date"
                            value={values.date_avenant}
                            onChange={handleChange("date_avenant")}
                            onBlur={handleBlur("date_avenant")}
                          />
                          <small className="field-error">
                            {errors.date_avenant}
                          </small>
                        </Form.Field>
                        <Message>
                          <Message.Content>
                            <p>
                              La mise en vigueur de l'avenant est fixée au
                              lendemain de la date de l'avenant.
                            </p>
                          </Message.Content>
                        </Message>
                      </Segment>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={12}>
                  <Grid columns={3} stackable>
                    {souscription?.souscription_garanties
                      .filter(
                        (garantie) =>
                          garantie.company_has_liste_garanties
                            .pool_directives_tarifaires_has_listes_garanties
                            .is_required !== 1 &&
                          garantie.souscription_garanties_risques?.length > 0 &&
                          garantie.souscription_garanties_risques?.reduce(
                            (sum, r) => sum + r.value,
                            0
                          ) > 0
                      )
                      .map((garantie) => (
                        <Grid.Column key={garantie.uuid}>
                          <Segment raised>
                            <Header as="h5">
                              {
                                garantie.company_has_liste_garanties
                                  .pool_directives_tarifaires_has_listes_garanties
                                  .liste_garanties.name
                              }
                            </Header>
                            <Checkbox
                              toggle
                              label={
                                values.garanties?.includes(garantie.uuid)
                                  ? "Activée"
                                  : "Désactivée"
                              }
                              checked={values.garanties?.includes(
                                garantie.uuid
                              )}
                              onChange={() => {
                                toggleGarantie(garantie.uuid);
                              }}
                              disabled={
                                !values.date_avenant ||
                                dayjs(values.date_avenant).isBefore(
                                  souscription?.conditions_particulieres
                                    ?.date_effet
                                ) ||
                                !dayjs(values.date_avenant).isValid()
                              }
                            />
                            <Divider />
                            <List relaxed>
                              {garantie.souscription_garanties_risques
                                .filter((risque) => risque.value !== 0)
                                .map((risque) => (
                                  <List.Item key={risque.uuid}>
                                    <List.Icon name="check" size="small" />
                                    <List.Content>
                                      <List.Header>
                                        {risque.liste_garanties_risques.name}
                                      </List.Header>
                                      <List.Description>
                                        {numeral(risque.value).format("")} FCFA
                                      </List.Description>
                                    </List.Content>
                                  </List.Item>
                                ))}
                            </List>
                          </Segment>
                        </Grid.Column>
                      ))}
                    <Grid.Column>
                      <Segment raised>
                        <Header as="h5">Détails financiers</Header>
                        <List>
                          <List.Item>
                            <List.Icon name="calendar alternate" />
                            <List.Content>
                              <List.Header>
                                Nombre de jours restants
                              </List.Header>
                              <List.Description>
                                {dayjs(values.date_avenant).isValid() &&
                                dayjs(
                                  souscription?.conditions_particulieres
                                    ?.date_echeance
                                ).isValid()
                                  ? dayjs(
                                      souscription?.conditions_particulieres
                                        ?.date_echeance
                                    )
                                      .add(1, "minutes")
                                      .subtract(1, "day")
                                      .diff(dayjs(values.date_avenant), "days")
                                  : "Veuillez saisir une date valide"}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Icon name="money bill alternate" />
                            <List.Content>
                              <List.Header>Prime nette TTC</List.Header>
                              <List.Description>
                                {numeral(primeNetteTtc).format("")} FCFA
                              </List.Description>
                            </List.Content>
                          </List.Item>

                          <List.Item>
                            <List.Icon name="percent" />
                            <List.Content>
                              <List.Header>Ristourne</List.Header>
                              <List.Description>
                                {numeral(
                                  calculateRistourne(values.garanties)
                                ).format("")}{" "}
                                FCFA
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      </Segment>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              !values.date_avenant ||
              dayjs(values.date_avenant).isBefore(
                souscription?.conditions_particulieres?.date_effet
              ) ||
              !dayjs(values.date_avenant).isValid()
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default RetraitsDeGarantiesModal;
