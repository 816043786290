import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
  Segment,
  Pagination,
} from "semantic-ui-react";
import SourcesEnergiesFormModal from "../../components/modalForms/SourcesEnergiesFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as sourcesEnergiesService from "../../services/sourcesEnergiesService";

function SourcesEnergies() {
  const [sources, setSources] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedEnergieUuid, setSelectedEnergieUuid] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  useEffect(() => {
    fetchEnergie();
  }, [pagination.currentPage, search]);

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchEnergie = async () => {
    setIsLoading(true);
    try {
      const response = await sourcesEnergiesService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });

      if (response.status === 200) {
        const {
          sources,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;

        setSources(sources);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des sources d'énergies");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteCountry = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await sourcesEnergiesService.remove(id);
        if (response.status === 200) {
          toast.success("Source d'énergie supprimée avec succès");
          fetchEnergie();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression de la source d'énergie");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedEnergieUuid(null);
    setOpen(false);
  };

  return (
    <Page title="Sources d'énergies">
      <GridRow className="SourcesEnergies">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Sources d'énergies</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              value={search}
              onChange={handleSearchChange}
            />
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <Segment loading={isLoading}>
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Libellé</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sources.map((source, index) => (
                <Table.Row key={source.idsources_energie}>
                  <Table.Cell>
                    {(pagination.currentPage - 1) * pagination.limit +
                      index +
                      1}
                  </Table.Cell>
                  <Table.Cell>{source.libelle}</Table.Cell>
                  <Table.Cell>{source.description}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() => {
                            const id = source.idsources_energie;
                            setSelectedEnergieUuid(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                          onClick={() => {
                            const id = source.idsources_energie;
                            setSelectedEnergieUuid(id);
                            setOpen(true);
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                          onClick={() =>
                            handleDeleteCountry(source.idsources_energie)
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8" textAlign="right">
                  <Pagination
                    activePage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={handlePageChange}
                    disabled={isLoading}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </GridRow>
      <SourcesEnergiesFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchEnergie();
          handleCloseModal();
        }}
        selectedEnergieUuid={selectedEnergieUuid}
        handleClose={handleCloseModal}
      />
    </Page>
  );
}

export default SourcesEnergies;
