import { api } from "../../axios/api";

export const fetchAll = async (
  page = 1,
  limit = 10,
  search = "",
  type = null
) => {
  return api.get(`/configuration-permissions/groups`, {
    params: {
      page,
      limit,
      search,
      type,
    },
  });
};

export const create = async (data) => {
  return api.post("/configuration-permissions/groups", data);
};

export const update = async (id, data) => {
  return api.put(`/configuration-permissions/groups/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`/configuration-permissions/groups/${id}`);
};

export const fetchOne = async (id) => {
  return api.get(`/configuration-permissions/groups/${id}`);
};

export const fetchBasePermissions = async (type) => {
  return api.get(`/configuration-permissions/groups/base?type=${type}`);
};
