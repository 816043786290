import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as stockService from "../../../../services/gestion_attestations/pool/stockService";
import { Link } from "react-router-dom";
import { fetchAll as fetchCompanies } from "../../../../services/comagniesService";

function Stocks() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState("DEFAULT");
  const [pagination, setPagination] = useState({});
  const [stocks, setStocks] = useState([]);
  const [companies, setCompanies] = useState([]);

  const fetchStocks = async () => {
    setLoading(true);
    try {
      const { data } = await stockService.fetchAll({
        page: selectedPage,
        search,
        companyUuid:
          selectedCompanyUuid === "DEFAULT" ? null : selectedCompanyUuid,
      });
      setStocks(data.stocks || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast(error.message, { type: "error" });
    }
    setLoading(false);
  };

  const fetchCompaniesList = async () => {
    try {
      const { data } = await fetchCompanies();
      setCompanies(data.compagnies || []);
    } catch (error) {}
  };

  const handleRemoveStock = async (id) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer ce stock ?",
      text: "Vous ne pourrez pas le reprendre!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await stockService.remove(id);
          toast("Stock supprimé avec succès.!", { type: "success" });
          fetchStocks();
        } catch (error) {
          toast(error.message, { type: "error" });
        }
      }
    });
  };

  useEffect(() => {
    fetchStocks();
  }, [selectedPage, search, selectedCompanyUuid]);

  useEffect(() => {
    fetchCompaniesList();
  }, []);
  return (
    <Page title="Stocks d'attestations">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Commandes</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Stocks d'attestations</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Dropdown
              placeholder="Selectionner une compagnie"
              search
              selection
              options={[]
                .concat(
                  companies.map((company) => ({
                    key: company.uuid,
                    value: company.uuid,
                    text: company.denomination,
                  }))
                )
                .concat([
                  {
                    key: "DEFAULT",
                    text: "Toutes les compagnies",
                    value: "DEFAULT",
                  },
                ])}
              onChange={(e, { value }) => setSelectedCompanyUuid(value)}
            />
            <Button className="!ml-4 !hidden">
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell collapsing>#</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Qté commandée</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Compagnie</Table.HeaderCell>
                  <Table.HeaderCell collapsing>
                    Date de commande
                  </Table.HeaderCell>
                  <Table.HeaderCell>Plage attribuée</Table.HeaderCell>
                  <Table.HeaderCell>Qté réceptionnée</Table.HeaderCell>
                  {/*  <Table.HeaderCell collapsing>Statut</Table.HeaderCell> */}
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {stocks?.map((commande, index) => (
                  <Table.Row
                    key={index}
                    className={`${
                      commande.attestations_stocks ? "" : "warning"
                    }`}
                  >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {new Intl.NumberFormat("fr-FR").format(commande.quantity)}
                    </Table.Cell>

                    <Table.Cell>{commande.compagnies.denomination}</Table.Cell>
                    <Table.Cell>
                      {dayjs(commande.created_at).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {(commande.attestations_stocks?.start_serial || "---") +
                        " - " +
                        (commande.attestations_stocks?.end_serial || "---")}
                    </Table.Cell>
                    <Table.Cell>
                      {commande?.attestations_stocks
                        ? `
                        ${new Intl.NumberFormat().format(
                          commande?.usage?.totalQty
                        )}                        
                        `
                        : ""}
                    </Table.Cell>
                    <Table.Cell>
                      {commande.attestations_stocks?.created_at &&
                        dayjs(commande.attestations_stocks.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            as={Link}
                            to={`/pool/stocks/details/${commande.uuid}`}
                          />

                          {commande.attestations_stocks && (
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Retirer le stock"
                              onClick={() =>
                                handleRemoveStock(
                                  commande.attestations_stocks?.uuid
                                )
                              }
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {stocks.length > 0 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="10">
                      {pagination?.totalPages > 1 && (
                        <Pagination
                          floated="right"
                          activePage={pagination?.currentPage}
                          totalPages={pagination?.totalPages}
                          onPageChange={(e, { activePage }) =>
                            setSelectedPage(activePage)
                          }
                        />
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
    </Page>
  );
}

export default Stocks;
