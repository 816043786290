import React, {  } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Grid,
  GridRow,
} from "semantic-ui-react";
import Page from "../../../../components/Page";

import SouscriptionMonoForm from "../../../../components/production/souscriptions/SouscriptionMonoForm";
import Breadcrumber from "../../../../components/Breadcrumber";

function SouscriptionMonoPage() {
  return (
    <Page title="Ajouter une souscription">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumber
              breadcrumbs={[
                { label: "Gestion de la production", link: "/agence/production" },
                { label: "Souscription", link: "/agence/production/souscriptions/mono" },
                { label: "Mono", active: true },
              ]}
            />
            <Divider />
          </Grid.Column>
        </Grid.Row>

        <GridRow>
          <SouscriptionMonoForm />
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default SouscriptionMonoPage;
