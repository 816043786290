import { api } from "../axios/api";

export const fetchAll = async ({
  search,
  page,
  limit = 10,
  all = false,
} = {}) => {
  return api.get(`intermediaires`, {
    params: { search, page, limit, all },
  });
};

export const fetchOne = async (id) => {
  return api.get(`intermediaires/${id}`);
};

export const fetchOwner = async () => {
  return api.get(`intermediaires/details/owner`);
};

export const fetchDetails = async (id) => {
  return api.get(`intermediaires/details/${id}`);
};

export const create = async (data) => {
  return api.post(`intermediaires`, data);
};
export const addUser = async (data) => {
  return api.post(`intermediaires/user/add`, data);
};

export const fetchCompanies = async () => {
  return api.get(`intermediaires/companies`);
};

export const update = async (uuid, data) => {
  return api.put(`intermediaires/${uuid}`, data);
};

export const deleteIntermediaire = async (uuid) => {
  return api.delete(`intermediaires/${uuid}`);
};

export const getPartners = async () => {
  return api.get(`intermediaires/partners`);
};
