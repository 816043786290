import React, { useState } from "react";

function SidebarMenuLayout({ children }) {
  const [activeIndex, setActiveIndex] = useState("dashboard");
  const [activeSubMenu, setActiveSubMenu] = useState("");

  const handleItemClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? "" : index;
    setActiveIndex(newIndex);
    setActiveSubMenu("");
  };
  const handleSubMenuClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeSubMenu === index ? "" : index;
    if (index === newIndex) {
      setActiveSubMenu(newIndex);
    }
  };
  return (
    <div className="sidebar-menu-layout">
      {React.cloneElement(children, {
        activeIndex,
        activeSubMenu,
        handleItemClick,
        handleSubMenuClick,
      })}
    </div>
  );
}

export default SidebarMenuLayout;
