import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`compagnies-agences`);
};

export const fetchOne = async (id) => {
  return api.get(`compagnies-agences/${id}`);
};
export const create = async (data) => {
  return api.post(`compagnies-agences`, data);
};

export const update = async (id, data) => {
  return api.put(`compagnies-agences/${id}`, data);
};

export const addUser = async (data, agenceId) => {
  return api.post(
    `compagnies-agences/user/add/${agenceId ? agenceId : ""}`,
    data
  );
};
