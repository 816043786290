import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import * as avariesFacultativesService from "../../../../services/gestion_production/pool/avariesFacultativesService";
import Swal from "sweetalert2";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

const schema = Yup.object().shape({
  directive_uuid: Yup.string().required("Valeur obligatoire"),
  items: Yup.array(
    Yup.object().shape({
      uuid: Yup.string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      percentage: Yup.number().required("Valeur obligatoire"),
      directive_uuid: Yup.string().uuid().required("Valeur obligatoire"),
      reduction: Yup.number().required("Valeur obligatoire"),
      minimum: Yup.number().required("Valeur obligatoire"),
      pourcentage_montant_sinistre: Yup.number().required("Valeur obligatoire"),
    })
  ),
});

function AvariesFacultatives({ directiveUuid }) {
  const userData = useRecoilValue(authState);
  const [isModeEdit, setIsModeEdit] = React.useState(false);
  const [avariesFacultatives, setAvariesFacultatives] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const addNewItem = () => {
    const items = [...avariesFacultatives];
    items.push({
      uuid: null,
      reduction: "",
      minimum: "",
      pourcentage_montant_sinistre: "",
      directive_uuid: directiveUuid,
    });

    setAvariesFacultatives(items);
  };

  const deleteItem = (index) => {
    const items = [...avariesFacultatives];
    items.splice(index, 1);

    setAvariesFacultatives(items);
  };

  const fetchItems = () => {
    setIsLoading(true);
    avariesFacultativesService
      .getAvariesByDirectiveTatifaire(directiveUuid)
      .then(
        (res) => {
          const items = [];
          res.data.forEach((item) => {
            items.push({
              uuid: item.uuid,
              reduction: item.reduction,
              minimum: item.minimum,
              pourcentage_montant_sinistre: item.pourcentage_montant_sinistre,
              directive_uuid: directiveUuid,
            });
          });

          setAvariesFacultatives(items);
        },
        (error) => {
          toast.error("Opération échouée");
        }
      )
      .catch((error) => toast.error("Opération échouée"))
      .finally(() => setIsLoading(false));
  };

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        const item = avariesFacultatives[index];
        if (!item.uuid) {
          deleteItem(index);
          return true;
        }
        avariesFacultativesService.removeItem(item.uuid).then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
            deleteItem(index);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  useEffect(() => {
    if (directiveUuid) fetchItems();
  }, [directiveUuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment loading={isLoading}>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">Tableau d'avaries facultatives</Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(
              userData?.user?.user_role
            ) && (
                <Checkbox
                  toggle
                  label="Mode édition"
                  onChange={(e, { checked }) => {
                    setIsModeEdit(checked);
                    fetchItems();
                  }}
                  checked={isModeEdit}
                />
              )}
          </div>
          <Divider />
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing width={1}>
                  #
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Franchise d'avaries (% du montant du sinistre)
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>Minimum</Table.HeaderCell>
                <Table.HeaderCell width={4}>Réduction (%)</Table.HeaderCell>
                {isModeEdit ? (
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading && avariesFacultatives?.map((item, index) => (
                <Formik
                  initialValues={item}
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    const data = {
                      uuid: values.uuid,
                      reduction: values.reduction,
                      minimum: values.minimum,
                      pourcentage_montant_sinistre:
                        values.pourcentage_montant_sinistre,
                      directive_uuid: directiveUuid,
                    };

                    const uuid = values.uuid;
                    if (uuid) {
                      delete data.directive_uuid;
                    }
                    const reqOp = data.uuid
                      ? avariesFacultativesService.update(uuid, data)
                      : avariesFacultativesService.create(data);

                    reqOp.then(
                      (res) => {
                        toast.success("Opération réussie");
                        const items = [...avariesFacultatives];
                        items[index] = {
                          uuid: res.data.uuid,
                          reduction: res.data.reduction,
                          minimum: res.data.minimum,
                          pourcentage_montant_sinistre:
                            res.data.pourcentage_montant_sinistre,
                          directive_uuid: directiveUuid,
                        };
                        setAvariesFacultatives(items);
                      },
                      (err) => {
                        toast.error("Opération échouée");
                      }
                    );
                  }}
                >
                  {(formik) => (
                    <Table.Row key={index}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell textAlign="left">
                        {isModeEdit ? (
                          <Input
                            fluid
                            type="number"
                            min={1}
                            value={formik.values.pourcentage_montant_sinistre}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "pourcentage_montant_sinistre",
                                e.target.value
                              )
                            }
                            max={100}
                            step={1}
                          />
                        ) : (
                          item.pourcentage_montant_sinistre
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.minimum}
                            onChange={(e) => {
                              formik.setFieldValue("minimum", e.target.value);
                            }}
                            type="number"
                            min={1}
                            step={1}
                          />
                        ) : (
                          new Intl.NumberFormat().format(Number(item.minimum))
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.reduction}
                            onChange={(e) => {
                              formik.setFieldValue("reduction", e.target.value);
                            }}
                            type="number"
                            min={1}
                            max={100}
                            step={1}
                          />
                        ) : (
                          item.reduction
                        )}
                      </Table.Cell>

                      {isModeEdit ? (
                        <Table.Cell className="!text-right">
                          <Icon
                            name="save"
                            color="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              formik.submitForm();
                            }}
                          />
                          <Icon
                            name="trash"
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleRemoveItem(index);
                            }}
                          />
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  )}
                </Formik>
              ))}
            </Table.Body>
            {isModeEdit ? (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colspan={isModeEdit ? 5 : 4}>
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      floated="left"
                      onClick={() => {
                        addNewItem();
                      }}
                    />

                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        setIsModeEdit(false);
                        fetchItems();
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            ) : null}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default AvariesFacultatives;
