import { View } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";

function PreviewContentWrapper({ children, souscription }) {
  return (
    <View
      style={{
        paddingTop: Number(souscription?.paramsImpression?.top) + 10,
        marginBottom: Number(souscription?.paramsImpression?.bottom) + 50,
        paddingLeft: souscription?.paramsImpression?.left,
        paddingRight: souscription?.paramsImpression?.right,
      }}
    >
      {children}
    </View>
  );
}

PreviewContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  souscription: PropTypes.object.isRequired,
};

export default PreviewContentWrapper;
