import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
  Pagination,
} from "semantic-ui-react";
import JobFormModal from "../../components/modalForms/JobFormModal";
import Page from "../../components/Page";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as jobsService from "../../services/jobsService";

function JobsPage() {
  const [jobs, setJobs] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    prevPage: null,
    nextPage: null,
    limit: 10,
  });

  const handlePageChange = (e, { activePage }) => {
    setPagination((prev) => ({ ...prev, currentPage: activePage }));
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const response = await jobsService.fetchAll({
        page: pagination.currentPage,
        limit: pagination.limit,
        search: search || undefined,
      });

      if (response.status === 200) {
        const {
          jobs,
          totalPages,
          currentPage,
          totalItems,
          nextPage,
          prevPage,
        } = response.data;

        setJobs(jobs);
        setPagination((prev) => ({
          ...prev,
          currentPage: currentPage || prev.currentPage,
          totalPages: totalPages || prev.totalPages,
          totalItems: totalItems || prev.totalItems,
          nextPage,
          prevPage,
        }));
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des professions");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleDeleteJob = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      setIsDeleting(true);
      try {
        const response = await jobsService.remove(id);
        if (response.status === 200) {
          toast.success("Profession supprimée avec succès");
          fetchJobs();
        }
      } catch (error) {
        toast.error("Erreur lors de la suppression de la profession");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedJobId(null);
    setOpen(false);
  };

  useEffect(() => {
    fetchJobs();
  }, [pagination.currentPage, search]);

  return (
    <Page title="Professions">
      <GridRow className="JobsPage">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des professions</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              onChange={handleSearchChange}
              value={search}
            >
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Libelle</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {jobs.map((job, index) => {
                  return (
                    <Table.Row key={`${job.idjobs}`}>
                      <Table.Cell>
                        {(pagination.currentPage - 1) * pagination.limit +
                          index +
                          1}
                      </Table.Cell>
                      <Table.Cell>{job.libelle}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() => {
                                const id = job.idjobs;
                                setSelectedJobId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                const id = job.idjobs;
                                setSelectedJobId(id);
                                setOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Supprimer"
                              onClick={() => handleDeleteJob(job.idjobs)}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3" textAlign="right">
                    <Pagination
                      activePage={pagination.currentPage}
                      totalPages={pagination.totalPages}
                      onPageChange={handlePageChange}
                      disabled={isLoading}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>
      <JobFormModal
        open={open}
        setOpen={setOpen}
        callback={() => {
          fetchJobs();
          handleCloseModal();
        }}
        selectedJobId={selectedJobId}
        handleClose={handleCloseModal}
      />
    </Page>
  );
}

export default JobsPage;
