import React, { useEffect, useRef, useState } from "react";
import Page from "../../components/Page";
import {
  Button,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
} from "semantic-ui-react";
import Breadcrumber from "../../components/Breadcrumber";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form } from "semantic-ui-react";
import {
  save,
  fetchCompanyMargesImpression,
} from "../../services/settings/margesImpressionService";
import { toast } from "react-toastify";

const schema = yup.object({
  top: yup.number().required("La marge en haut est requise"),
  bottom: yup.number().required("La marge en bas est requise"),
  left: yup.number().required("La marge à gauche est requise"),
  right: yup.number().required("La marge à droite est requise"),
});

function MargesImpression() {
  const [printTemplate, setPrintTemplate] = useState(null);
  const [image, setImage] = useState(null);
  const imageInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("top", Number(values.top));
        formData.append("bottom", Number(values.bottom));
        formData.append("left", Number(values.left));
        formData.append("right", Number(values.right));

        if (image) {
          formData.append("file", image);
        }

        const response = await save(formData);
        toast.success("Marges d'impression enregistrées avec succès");
        fetchMargesImpression();
      } catch (error) {
        toast.error(error.response.data || "Une erreur est survenue");
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const fetchMargesImpression = async () => {
    try {
      const response = await fetchCompanyMargesImpression();
      formik.setValues({
        top: Number(response?.data?.top),
        bottom: Number(response?.data?.bottom),
        left: Number(response?.data?.left),
        right: Number(response?.data?.right),
      });
      setPrintTemplate(response?.data?.print_template);
    } catch (error) {
      toast.error(error?.response?.data || "Une erreur est survenue");
    }
  };

  useEffect(() => {
    fetchMargesImpression();
  }, []);

  return (
    <Page title="Marges et bordures d'impressions">
      <GridRow className="MargesImpressions">
        <Breadcrumber
          breadcrumbs={[
            { label: "Configuration", link: "" },
            { label: "Marges et bordures d'impressions", active: true },
          ]}
        />
        <Divider />
      </GridRow>

      <Grid>
        <Grid.Column width={4}>
          <Segment raised>
            <Header dividing as="h3">
              Marges d'impression
            </Header>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Field>
                <label>Marge en haut</label>
                <Input
                  type="number"
                  name="top"
                  value={formik.values.top}
                  onChange={(e) => formik.setFieldValue("top", e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Marge en bas</label>
                <Input
                  type="number"
                  name="bottom"
                  value={formik.values.bottom}
                  onChange={(e) =>
                    formik.setFieldValue("bottom", e.target.value)
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>Marge à gauche</label>
                <Input
                  type="number"
                  name="left"
                  value={formik.values.left}
                  onChange={(e) => formik.setFieldValue("left", e.target.value)}
                />
              </Form.Field>

              <Form.Field>
                <label>Marge à droite</label>
                <Input
                  type="number"
                  name="right"
                  value={formik.values.right}
                  onChange={(e) =>
                    formik.setFieldValue("right", e.target.value)
                  }
                />
              </Form.Field>

              <Button type="submit" primary loading={formik.isSubmitting}>
                Enregistrer
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment raised style={{ height: `${297}mm`, padding: "0" }}>
            <div
              style={{
                position: "relative",
                height: `${297}mm`,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  cursor: "pointer",
                }}
                onClick={() => imageInputRef.current.click()}
              >
                <Icon name="upload" />
                <span>Importer votre template</span>
              </div>
              <input
                type="file"
                hidden
                onChange={handleImageChange}
                accept="image/*"
                ref={imageInputRef}
              />
              <img
                src={
                  image
                    ? URL.createObjectURL(image)
                    : printTemplate
                    ? `${process.env.REACT_APP_API_URL}uploads/print_templates/${printTemplate}`
                    : ""
                }
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: `${formik.values.top * 1.6}px`,
                  left: `${formik.values.left * 1.6}px`,
                  right: `${formik.values.right * 1.6}px`,
                  bottom: `${formik.values.bottom * 1.6}px`,
                  border: "2px dashed red",
                  pointerEvents: "none",
                }}
              ></div>
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </Page>
  );
}

export default MargesImpression;
