import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Input, Message, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import { findOne } from "../../../services/gestion_production/policesService";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  date_avenant: yup.date().required("La date de l'avenant est obligatoire"),
});

function DuplicataAttestestationModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const [souscription, setSouscription] = useState(null);

  const formik = useFormik({
    initialValues: {
      date_avenant: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        date_avenant: values.date_avenant,
      };
      avenantsService
        .createAvenantDuplicataAttestestation(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Avenant d'émission d'attestation ajouté avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.message || "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  useEffect(() => {
    if (open) {
      findOne(souscription_uuid).then((response) => {
        setSouscription(response.data);
      });
    }
  }, [open, souscription_uuid]);

  return (
    <Modal
      onClose={() => {
        typeof open === "boolean" ? setOpen(false) : setOpen(null);
      }}
      onOpen={() => {
        typeof open === "boolean"
          ? setOpen(true)
          : setOpen("changement-immatriculation");
      }}
      open={
        typeof open === "string" ? open === "changement-immatriculation" : open
      }
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
      size="tiny"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant d'Emission d'attestation</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui form">
            <Grid divided>
              <Grid.Column width={16}>
                <Form.Field required>
                  <label>Date de l'avenant</label>
                  <Input
                    type="date"
                    value={values.date_avenant}
                    onChange={handleChange("date_avenant")}
                    onBlur={handleBlur("date_avenant")}
                  />
                  <small className="field-error">{errors.date_avenant}</small>
                </Form.Field>
                <Message>
                  <Message.Content>
                    <p>
                      La mise en vigueur de l'avenant est fixée au lendemain de
                      la date de l'avenant.
                    </p>
                  </Message.Content>
                </Message>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
            disabled={
              !values.date_avenant ||
              dayjs(values.date_avenant).isBefore(
                souscription?.conditions_particulieres?.date_effet
              )
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default DuplicataAttestestationModal;
