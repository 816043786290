import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { authState } from "../../atoms/authState";
import IntermediaireAgenceFormModal from "../modalForms/IntermediaireAgenceFormModal";
import * as intermediairesAgenciesService from "../../services/intermediairesAgenciesService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function AgenciesListTable({ intermediaireUuid }) {
  const userData = useRecoilValue(authState);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedAgencyId, setSelectedAgencyId] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleAgencyModalCallback = () => {
    setOpenAgencyModal(false);
    fetchAgencies();
  };

  const handleCloseAgencyModal = () => {
    setOpenAgencyModal(false);
    setSelectedAgencyId(null);
  };

  const fetchAgencies = () => {
    setLoading(true);
    intermediairesAgenciesService
      .fetchByIntermediaireUuid(intermediaireUuid)
      .then(
        (response) => {
          setAgencies(response.data?.agencies || []);
          setPagination(response.data?.pagination || {});
        },
        (reason) => {
          toast.error("Impossible de récupérer les agences");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteAgency = (id) => {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette agence? Cette action est irréversible.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        intermediairesAgenciesService.deleteAgency(id).then(
          (response) => {
            toast.success("Agence supprimée avec succès.");
            fetchAgencies();
          },
          (reason) => {
            toast.error("Impossible de supprimer l'agence");
          }
        );
      }
    });
  };

  useEffect(() => {
    if (intermediaireUuid) {
      fetchAgencies();
    }
  }, [intermediaireUuid]);

  return (
    <>
      <GridRow as={Segment} style={{ marginTop: "30px" }} loading={loading}>
        <GridRow stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Agences</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => {
                setOpenAgencyModal(true);
              }}
            />
          </Grid.Column>
        </GridRow>

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Dénomination</Table.HeaderCell>
              <Table.HeaderCell>Téléphones</Table.HeaderCell>
              <Table.HeaderCell>Adresse</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {agencies?.map((agency, index) => {
              return (
                <Table.Row key={`${agency.intermediaires_agencies}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{agency.denomination}</Table.Cell>
                  <Table.Cell>
                    {agency.phone_1}/{agency.phone_2}
                  </Table.Cell>
                  <Table.Cell>{agency.adress}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                          onClick={() =>
                            navigate(
                              `/intermediaire/agences/details/${agency.idintermediaires_agencies}`
                            )
                          }
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                          onClick={() => {
                            setOpenAgencyModal(true);
                            setSelectedAgencyId(
                              agency.idintermediaires_agencies
                            );
                          }}
                        />
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                          onClick={() =>
                            handleDeleteAgency(agency.idintermediaires_agencies)
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </GridRow>
      <IntermediaireAgenceFormModal
        open={openAgencyModal}
        setOpen={setOpenAgencyModal}
        callback={handleAgencyModalCallback}
        handleClose={handleCloseAgencyModal}
        intermediaireUuid={intermediaireUuid}
        selectedAgencyId={selectedAgencyId}
        setSelectedAgencyId={setSelectedAgencyId}
      />
    </>
  );
}

export default AgenciesListTable;
