import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import convertToWord from "../../../utils/numberToWords";
import numeral from "numeral";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import { findOne } from "../../../services/gestion_production/policesService";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";

function ChangementImmatriculation({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);
  const priceArray = [
    {
      label: "Prime nette",
      value: 0,
    },
    {
      label: "Montant Accessoires",
      value: 5000,
    },
    {
      label: "Taxe d'enregistrement",
      value: 750,
    },
    {
      label: "Frais de gestion",
      value: 0,
    },
    {
      label: "Carte rose",
      value: 1500,
    },
    {
      label: "Droit de timbre Automobile",
      value: 0,
    },
  ];
  const ReferenceItem = ({ label, value, styles }) => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 3,
          ...styles,
        }}
      >
        <Text>{label}</Text>
        <Text style={{ paddingRight: 15 }}>{value}</Text>
      </View>
    );
  };
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PreviewTemplateLayout souscription={souscription} />
          <PreviewContentWrapper souscription={souscription}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    textTransform: "uppercase",
                  }}
                >
                  Changement d'immatriculation Automobile
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                border: 1,
                borderWidth: 1,
                borderColor: "#000",
                marginTop: 25,
              }}
            >
              <View style={{ width: "50%" }}>
                <View style={{ borderBottomWidth: 1, padding: 5 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    Référence du souscripteur
                  </Text>
                </View>
                <ReferenceItem
                  label="Numéro souscripteur"
                  value={souscriptionWithAvenant?.souscripteurs?.num}
                />
                <ReferenceItem
                  label="Nom"
                  value={`${
                    souscriptionWithAvenant?.souscripteurs
                      ?.groupe_souscripteur === "ENTREPRISE"
                      ? `${souscriptionWithAvenant?.souscripteurs?.denomination}`
                      : `${souscriptionWithAvenant?.souscripteurs?.first_name} ${souscriptionWithAvenant?.souscripteurs?.last_name}`
                  }`}
                />
                <ReferenceItem
                  label="Adresse"
                  value={souscriptionWithAvenant?.souscripteurs?.address}
                />
                <ReferenceItem
                  label="Téléphone"
                  value={souscriptionWithAvenant?.souscripteurs?.phone}
                />
                <ReferenceItem
                  label="Profession"
                  value={souscriptionWithAvenant?.souscripteurs?.jobs?.name}
                />
                <ReferenceItem
                  label="Réseau"
                  value={
                    souscriptionWithAvenant?.souscription_origine
                      ?.compagnies_agencies
                      ? souscriptionWithAvenant?.souscription_origine
                          ?.compagnies_agencies?.denomination
                      : souscriptionWithAvenant?.souscription_origine
                          ?.intermediaires_agencies?.denomination
                  }
                />
                <ReferenceItem
                  label="Intermédiaire"
                  value={
                    souscriptionWithAvenant?.souscription_origine
                      ?.intermediaires?.denomination
                  }
                />
                <ReferenceItem
                  label="Email"
                  value={souscriptionWithAvenant?.souscripteurs?.email}
                />
              </View>
              <View style={{ width: "50%" }}>
                <View style={{ borderBottomWidth: 1, padding: 5 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    Références Changement d'immatriculation
                  </Text>
                </View>
                <ReferenceItem
                  label="Numéro de police"
                  value={
                    souscriptionWithAvenant?.souscription_polices?.numero_police
                  }
                />
                <ReferenceItem
                  label="N° Avenant"
                  value={(
                    souscriptionWithAvenant?.avenants?.findIndex(
                      (avenant) => avenant.uuid === avenant_uuid
                    ) + 1
                  )
                    ?.toString()
                    ?.padStart(6, "0")}
                />
                <ReferenceItem
                  label="Emission"
                  value={dayjs(
                    souscriptionWithAvenant?.avenants?.find(
                      (avenant) => avenant.uuid === avenant_uuid
                    ).created_at
                  ).format("DD/MM/YYYY")}
                />
                <ReferenceItem
                  label="Assuré"
                  value={` ${souscriptionWithAvenant?.souscripteur_assure?.assures?.nom_assure}`}
                />
                <ReferenceItem
                  label="Adresse"
                  value={
                    souscriptionWithAvenant?.souscription_asure?.assures
                      ?.address
                  }
                />
                <ReferenceItem
                  label="Effet de l'avenant"
                  value={dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                />
                <ReferenceItem
                  label="Expiration"
                  value={dayjs(
                    souscriptionWithAvenant?.conditions_particulieres
                      ?.date_effet
                  )
                    .add(
                      Number(
                        souscriptionWithAvenant?.conditions_particulieres
                          ?.pool_directives_tarifaires_has_insurances_durations
                          ?.insurances_durations?.months_number
                      ),
                      "months"
                    )
                    .add(-1, "day")
                    .format("DD/MM/YYYY")}
                />
                <ReferenceItem label="Catégorie" value="4" />
                <ReferenceItem
                  label="Mouvement"
                  value="Changement d'immatriculation"
                />
                <ReferenceItem label="Barème" value="Contrat Mono" />
              </View>
            </View>

            <View style={{ marginTop: 25 }}>
              <Text>
                A la demande de l'assuré(s) et d'accord parties, le présent
                avenant est établi pour procéder, à compter du{" "}
                {dayjs(avenant?.date_avenant)
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .add(1, "day")
                  .format("DD/MM/YYYY")}{" "}
                à Minuit, au changement d'immatriculation de(s) véhicule(s)
                assuré(s) dont la liste est en annexe. Ci-joint le tableau
                actualisé de(s) véhicule(s).
              </Text>
            </View>

            <View style={{ marginTop: 10 }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderBottomColor: "#000",
                  paddingBottom: 5,
                }}
              >
                <Text
                  style={{
                    width: "33%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  N°
                </Text>
                <Text
                  style={{
                    width: "33%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Ancienne Immatriculation
                </Text>
                <Text
                  style={{
                    width: "33%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Nouvelle Immatriculation
                </Text>
              </View>
              <View style={{ flexDirection: "row", paddingTop: 5 }}>
                <Text style={{ width: "33%", textAlign: "center" }}>1</Text>
                <Text style={{ width: "33%", textAlign: "center" }}>
                  {
                    souscriptionWithAvenant?.souscription_identite_vehicule
                      ?.immatriculation_vehicule
                  }
                </Text>
                <Text style={{ width: "33%", textAlign: "center" }}>
                  {
                    souscriptionWithAvenant?.avenants?.find(
                      (avenant) => avenant.uuid === avenant_uuid
                    )?.meta_data?.souscription_identite_vehicule
                      ?.immatriculation_vehicule
                  }
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                }}
              >
                Prime
              </Text>
              <View style={{ marginTop: 10 }}>
                <Text>
                  De ce qui précède, le souscripteur s'engage à payer au
                  comptant à la signature du présent avenant , la somme de{" "}
                  {numeral(
                    priceArray.reduce((acc, price) => acc + price.value, 0)
                  ).format()}{" "}
                  FCFA décomposée comme suit :
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                borderTopWidth: 1,
                borderColor: "#000",
                paddingTop: 3,
              }}
            >
              {priceArray.map((price, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    paddingTop: 2,
                  }}
                  key={index}
                >
                  <Text style={{ width: "70%" }}>{price.label}</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(price.value).format()} FCFA
                  </Text>
                </View>
              ))}

              <View
                style={{
                  flexDirection: "row",
                  borderTop: 1,
                  marginTop: 5,
                  paddingTop: 5,
                  fontSize: 12,
                  borderBottomWidth: 1,
                  borderBottomColor: "#000",
                  paddingBottom: 5,
                }}
              >
                <Text style={{ width: "70%", fontWeight: "bold" }}>
                  Total à payer
                </Text>
                <Text
                  style={{
                    width: "30%",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {numeral(
                    priceArray.reduce((acc, price) => acc + price.value, 0)
                  ).format()}{" "}
                  FCFA
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10, textAlign: "center", width: "100%" }}>
              <Text style={{ fontWeight: "bold", textTransform: "center" }}>
                Arrêté la présente facture à la somme de{" "}
                {convertToWord(
                  priceArray.reduce((acc, price) => acc + price.value, 0)
                )}{" "}
                FCFA
              </Text>
            </View>
            <View style={{ marginTop: 30, marginBottom: 30 }}>
              <Text style={{ fontStyle: "italic", textAlign: "right" }}>
                Fait à _________________, le ______________
              </Text>
            </View>
            <View
              style={{
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  Le Souscripteur
                </Text>
              </View>
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                    textAlign: "right",
                  }}
                >
                  Pour la Compagnie
                </Text>
              </View>
            </View>
          </PreviewContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ChangementImmatriculation;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
    fontSize: 10,
  },
  section: {
    padding: 20,
  },
  sectionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
    fontSize: 8,
  },
});
