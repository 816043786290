import React, { useEffect } from "react";
import { Button, Dropdown, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as intermediaireAgencyRequestsService from "../../../services/gestion_attestations/intermediaire_agency/requestsService";
import { fetchCompanies } from "../../../services/intermediairesAgenciesService";
import { authState } from "../../../atoms/authState";
import { useRecoilValue } from "recoil";

const validationSchema = (isAgentGeneralAgencyUser) =>
  yup.object().shape({
    quantity: yup
      .number("Veuillez renseigner une quantité valide")
      .min(1, "Veuillez renseigner une quantité valide")
      .required("Veuillez renseigner la quantité"),
    company_uuid: !isAgentGeneralAgencyUser
      ? yup.string().uuid().required()
      : yup.string().uuid().nullable().notRequired(),
  });

function IntermediaireAgencyRequestAttestationFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedRequestId,
  handleCloseModal = () => {},
}) {
  const [companies, setCompanies] = React.useState([]);
  const userData = useRecoilValue(authState);

  const formik = useFormik({
    initialValues: {
      quantity: 0,
      company_uuid: "",
    },
    validationSchema: validationSchema(
      userData?.user?.intermediaire?.type_intermediaire === "AGENT_GENERAL"
    ),
    onSubmit: (values) => {
      const request = selectedRequestId
        ? intermediaireAgencyRequestsService.update(selectedRequestId, values)
        : intermediaireAgencyRequestsService.create(values);
      request
        .then((response) => {
          toast.success(
            selectedRequestId
              ? "Demande d'attestation mise à jour"
              : "Demande d'attestation ajoutée"
          );
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              "Une erreur est survenue lors de " +
                (selectedRequestId ? "la mise à jour" : "l'ajout") +
                " de la demande d'attestation"
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  const fetchData = (selectedRequestId) => {
    intermediaireAgencyRequestsService
      .fetchOne(selectedRequestId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues(
            {
              quantity: data.quantity,
              company_uuid: data.compagnies?.uuid,
            },
            true
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération"
        );
      })
      .finally(() => {});
  };

  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        if (Array.isArray(response.data)) {
          const data = response.data;
          setCompanies(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération"
        );
      });
  };

  useEffect(() => {
    if (open) getCompanies();
  }, [open]);

  useEffect(() => {
    formik.resetForm();
    if (selectedRequestId !== null && open) {
      fetchData(selectedRequestId);
    }
  }, [selectedRequestId, open]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {selectedRequestId
          ? "Mettre à jour la demande d'attestation"
          : "Ajouter une nouvelle demande d'attestation"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          {userData?.user?.intermediaire?.type_intermediaire !==
            "AGENT_GENERAL" && (
            <Form.Field>
              <label>Stock</label>
              <Dropdown
                fluid
                search
                selection
                placeholder="Selectionner une compagnie"
                name="company_uuid"
                options={[
                  { key: "", value: "", text: "Choisir une compagnie" },
                  ...companies.map((company) => ({
                    key: company.uuid,
                    value: company.uuid,
                    text: company.denomination,
                  })),
                ]}
                onChange={(e, { value }) => {
                  formik.setFieldValue("company_uuid", value);
                }}
                onBlur={handleBlur("company_uuid")}
                value={values.company_uuid}
              />
              <small className="field-error">{errors.company_uuid}</small>
            </Form.Field>
          )}
          <Form.Field required error={"quantity" in errors}>
            <label>Quantité d'attestations</label>
            <Input
              type="number"
              name="quantity"
              value={values.quantity}
              onChange={handleChange("quantity")}
              onBlur={handleBlur("quantity")}
            />
            <small className="field-error">{errors.quantity}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediaireAgencyRequestAttestationFormModal;
