import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Image,
  Label,
  List,
  Modal,
  Segment,
  Tab,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../../components/Page";
import * as policesService from "../../../services/gestion_production/policesService";
import dayjs from "dayjs";
import ActesDeGestionPane from "../../../components/detailsPolice/ActesDeGestionPane";
import SinistresPane from "../../../components/detailsPolice/SinistresPane";
import ConditionsParticulieresPreviewModal from "../../../components/detailsPolice/ConditionsParticulieresPreviewModal";

function PoliceDetails() {
  const [souscription, setSouscription] = useState();
  const [openCarteGriseModal, setOpenCarteGriseModal] = useState(false);
  const [
    openConditionsParticulieresPreview,
    setOpenConditionsParticulieresPreview,
  ] = useState(false);

  const params = useParams();

  const fetchPolice = async () => {
    try {
      const response = await policesService.findOne(params?.uuid);

      if (response?.status === 200) {
        setSouscription(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchPolice();
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Details de la police">
      <GridRow className="CompagnyDetails">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Module production</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link active>
                Details de la police
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid>
          <Grid.Column width={8} style={{ display: "flex" }}>
            <Header style={{ alignSelf: "center" }}>
              Détails de la police
            </Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Dropdown
              pointing="right"
              icon={null}
              trigger={
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="ellipsis vertical" />}
                />
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setOpenConditionsParticulieresPreview(true)}
                  content="Conditions particulières"
                />
                <Dropdown.Item content="Carte Rose" />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid>
        <Divider />
        <GridRow as={Segment}>
          <Label ribbon>
            {souscription?.souscription_polices?.numero_police || "N/A"}
          </Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Code client</List.Header>
                    <List.Description>
                      {souscription?.souscripteurs?.num}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Immatriculation</List.Header>
                    <List.Description>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.immatriculation_vehicule
                      }
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Statut</List.Header>
                    <List.Description>
                      {souscription?.avenants[0]?.type_avenant}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Noms/Dénomination</List.Header>
                    <List.Description>
                      {souscription?.souscripteurs?.groupe_souscripteur ===
                      "PARTICULIER"
                        ? `${souscription?.souscripteurs?.last_name}
                         ${souscription?.souscripteurs?.first_name}`
                        : souscription?.souscripteurs?.denomination}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Date d'éffet</List.Header>
                    <List.Description>
                      {dayjs(
                        souscription?.conditions_particulieres?.date_effet
                      ).format("DD/MM/YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Numéro d'attestation</List.Header>
                    <List.Description>
                      {souscription?.souscription_polices?.numero_attestation ||
                        "N/A"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Type de client/</List.Header>
                    <List.Description>
                      {souscription?.souscripteurs?.groupe_souscripteur}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Echéance</List.Header>
                    <List.Description>
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD/MM/YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Numéro de police</List.Header>
                    <List.Description>
                      {souscription?.souscription_polices?.numero_police ||
                        "N/A"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={16}>
              <div
                className="!p-2 !bg-gray-100 rounded-md !cursor-pointer"
                onClick={() => setOpenCarteGriseModal(true)}
              >
                <Icon name="file pdf" />
                <span>Carte grise</span>
              </div>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment} style={{ marginBottom: "100px" }}>
          <Tab
            panes={[
              {
                menuItem: "Actes de gestion",
                render: () => (
                  <Tab.Pane>
                    <ActesDeGestionPane
                      avenants={souscription?.avenants}
                      onSuccessPaymentCallBack={fetchPolice}
                      onSuccessCallBack={fetchPolice}
                      idpolices={souscription?.idpolices}
                      souscription={souscription}
                      openConditionParticuliereModal={() =>
                        setOpenConditionsParticulieresPreview(true)
                      }
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "Sinistres",
                render: () => (
                  <Tab.Pane>
                    <SinistresPane />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </GridRow>
      </GridRow>
      {openConditionsParticulieresPreview && (
        <ConditionsParticulieresPreviewModal
          open={openConditionsParticulieresPreview}
          setOpen={setOpenConditionsParticulieresPreview}
          souscriptionUuid={souscription?.uuid}
          avenant_uuid={
            souscription?.avenants?.find(
              (av) => av.type_avenant === "AFFAIRE_NOUVELLE"
            )?.uuid
          }
        />
      )}
      <Modal
        size="fullscreen"
        closeIcon
        onClose={() => setOpenCarteGriseModal(false)}
        onOpen={() => setOpenCarteGriseModal(true)}
        open={openCarteGriseModal}
      >
        <Modal.Header>Carte grise</Modal.Header>
        <Modal.Content>
          {souscription?.fichier_carte_grise ? (
            <iframe
              src={`${
                process.env.REACT_APP_API_URL
              }${souscription.fichier_carte_grise.replace(/\\/g, "/")}`}
              width="100%"
              height="600px"
              title="Carte Grise"
            />
          ) : (
            <p>Aucune carte grise disponible</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenCarteGriseModal(false)}>
            Fermer
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
}

export default PoliceDetails;
