import React from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Segment,
} from "semantic-ui-react";
import numeral from "numeral";
import SummaryAmountDisplay from "./SummaryAmountDisplay";
function SommaireSection({ formik }) {
  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
  } = formik;
  return (
    <Segment>
      <Header content="Sommaire" dividing />
      <Grid>
        <Grid.Column width={4}></Grid.Column>
        <Grid.Column width={12}>
          <Grid>
            <Grid.Column width={16}>
              <Form.Group widths={"equal"}>
                <Form.Field error={"prime_nette" in errors}>
                  <label>Prime Nette RC</label>
                  <SummaryAmountDisplay value={values.prime_nette} />
                </Form.Field>
                <Form.Field error={"prime_nette_iac" in errors}>
                  <label>Prime Nette IAC</label>
                  <SummaryAmountDisplay value={values.prime_nette_iac} />
                </Form.Field>
                <Form.Field error={"prime_nette_totale" in errors}>
                  <label>Prime Nette Total</label>
                  <SummaryAmountDisplay value={values.prime_nette_totale} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field error={"taxes" in errors}>
                  <label>Taxe</label>
                  <SummaryAmountDisplay value={values.taxes} />
                </Form.Field>
                <Form.Field error={"montant_gestion_pool" in errors}>
                  <label>Frais de gestion du Pool</label>
                  <SummaryAmountDisplay value={values.montant_gestion_pool} />
                </Form.Field>
                <Form.Field error={"carte_rose" in errors}>
                  <label>Carte rose</label>
                  <SummaryAmountDisplay value={values.carte_rose} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field></Form.Field>
                <Form.Field error={"droit_de_timbre_automobile" in errors}>
                  <label>Droit de timbre automobile</label>
                  <SummaryAmountDisplay
                    value={values.droit_de_timbre_automobile}
                  />
                </Form.Field>
                <Form.Field error={"cout_police" in errors}>
                  <label>Coût de Police</label>
                  <SummaryAmountDisplay value={values.cout_police} />
                </Form.Field>
              </Form.Group>
              <Divider />
              <Form.Group widths={"equal"}>
                <Form.Field></Form.Field>
                <Form.Field></Form.Field>
                <Form.Field error={"prime_ttc" in errors} width={8}>
                  <label>Prime TTC</label>
                  <SummaryAmountDisplay value={values.prime_ttc} emphasize />
                </Form.Field>
              </Form.Group>
              <Divider />

              <div style={{ textAlign: "right" }}>
                <Button
                  content="Enregistrer"
                  icon="save"
                  onClick={handleSubmit}
                  positive
                  type="submit"
                  loading={isSubmitting}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

export default SommaireSection;
